import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import styles from "./Rules.module.css";

const Areas3 = () => {
    return ( <>
        <Header/>
        <main>
            <h3 className={styles["h3-main"]}>Кафедра бухгалтерского учета и аудита</h3>
            <h3 className={styles["h3-main"]}>БАКАЛАВРИАТ</h3><br/>
            <p>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Направление подготовки: </span>38.03.01 «Экономика»<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Профиль подготовки: </span>«Бухгалтерский учет, анализ и аудит»<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Форма и сроки обучения:</span> очная, 4 года<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Профильные предметы (вступительные испытания):</span>
            <ul>
                <li>математика (профильный предмет);</li>
                <li>иностранный язык;</li>
                <li>родной язык.</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Описание направления подготовки: </span>Профиль «Бухгалтерский учет, анализ и аудит» дает комплекс знаний в области:
            <ul>
                <li>анализа и прогнозировании социально-экономических процессов и явлений на микроуровне;</li>
                <li>планирования и обслуживания финансовых потоков, связанных с производственной деятельностью;</li>
                <li>внутреннего и внешнего финансового контроля и аудита.</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Чему будут учить:</span>
            <ul>
                <li>бухгалтерский, финансовый, управленческий и налоговый учёт в организациях различных отраслей, сфер и форм собственности (финансовых, кредитных и страховых структур, предприятий промышленности, торговли, строительства, туризма и прочих отраслей экономики), а также в органах государственной и местной власти;</li>
                <li>анализ финансово-хозяйственной деятельности коммерческих организаций различных отраслей экономики;</li>
                <li>судебно-бухгалтерская экспертиза;</li>
                <li>внутренний и внешний аудит;</li>
                <li>внутренний контроль;</li>
                <li>автоматизированная обработка учетных данных.</li>
            </ul><br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Эту профессию можно освоить: </span>на экономическом факультете при кафедре <span style={{color: '#43a5ee', fontWeight: 'bold'}}>бухгалтерского учета и аудита.</span>
            <ul><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Кем можно будет работать (профессии):</span>
                <li>работать в различных сферах экономики;</li>
                <li>занимать должности бухгалтеров на предприятиях, работников учетных и финансовых служб, ревизоров, налоговых инспекторов, специалистов счетной палаты, судебных экспертов, финансовых аналитиков, помощников аудиторов и аудиторов в национальных и международных компаниях, как в ПМР, так и за рубежом.</li>
            </ul>
            <ul><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Возможные места работы (организации, предприятия):</span>
                <li>министерство финансов ПМР, министерство здравоохранения ПМР, министерство экономического развития ПМР, счетная палата ПМР и другие министерства и ведомства;</li>
                <li>крупные и средние коммерческие организации различных отраслей и видов деятельности;</li>
                <li>государственные финансовые, налоговые и контрольно-ревизионные органы;</li>
                <li>аудиторские и консалтинговые фирмы.</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Количество бюджетных мест – 16.</span>
            </p>
        </main>
        <Footer/>
        </> );
}
 
export default Areas3;