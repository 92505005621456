import chelovskaya from "./../../img/finIkredit/chelovskaya.jpg";
import kornanu from "./../../img/finIkredit/kornanu.jpg";
import izmest from "./../../img/finIkredit/izmest.png";
import kirika from "./../../img/finIkredit/kirika.jpg";
import safronova from "./../../img/finIkredit/safr.jpg";
import mazur from "./../../img/finIkredit/mazur.jpg";
import safronov from "./../../img/finIkredit/safronov.jpg";
import grosul from "./../../img/finIkredit/grosul.jpg";
import gushkan from "./../../img/finIkredit/gushkan.jpg";
import undefined from "./../../img/finIkredit/undefined.png";
import vasilatii from "./../../img/finIkredit/vasilat.jpg";
import stratulat from "./../../img/finIkredit/stratulat.jpg";

const listDep3 = [
    {
        id:1,
        image:kornanu,
        fio:"Корняну Елена Владимировна без ученой степени без учёного звания",
        strucunit:"Кафедра финансов и кредита",
        jobtitle:"Старший преподаватель",
        listdiscip:"«Организация и методика проведения налоговых проверок»; «Правовое регулирование налоговых отношений»; «Налоговое производство»"
    },
    {
        id:2,
        image:izmest,
        fio:"Изместьева Светлана Юрьевна без ученой степени без учёного звания",
        strucunit:"Кафедра финансов и кредита",
        jobtitle:"Доцент",
        listdiscip:"«Налогообложение»; «Налоги и налогообложение»; «Налоговое право»; «Налогообложение организаций»"
    },
    {
        id:3,
        image:safronova,
        fio:"Сафронова Людмила Михайловна кандидат экономических наук доцент",
        strucunit:"Кафедра финансов и кредита",
        jobtitle:"Доцент",
        listdiscip:"«Банковский менеджмент»; «Зарубежные финансовые рынки»; «Инвестиционное кредитование»; «Международные производные финансовые инструменты»; «Международный рынок инвестиций»; «Международный банковский бизнес»; «Организованный рынок ценных бумаг»; «Производные финансовые инструменты»; «Рынок ценных бумаг»; «Система косвенных налогов»; «Страхование и страховые риски в международных экономических отношениях»; «Ценообразование на информационном рынке»; «Ценообразование»; «Дивидендная политика организации»; «Мировой опыт финансирования корпораций»"
    },
    {
        id:4,
        image:kirika,
        fio:"Кирика Наталья Дмитриевна без ученой степени без учёного звания",
        strucunit:"Кафедра финансов и кредита",
        jobtitle:"Старший преподаватель",
        listdiscip:"«Налоги с юридических и физических лиц»; «Налоговое администрирование и контроль»; «Современные подходы при осуществлении налогового администрирования»"
    },
    {
        id:5,
        image:chelovskaya,
        fio:"Человская Екатерина Ивановна",
        strucunit:"Кафедра финансов и кредита",
        jobtitle:"Доцент",
        listdiscip:"«Банковские платежные системы»; «Банковское дело»; «Деньги, кредит, банки»; «Развитие надзора за деятельностью финансово- кредитных институтов»; «Современная инфраструктура кредитной системы»; «Управление рисками валютных, кредитных и финансовых операций»; «Учет и операционная деятельность в банках»; «Международная практика валютных и финансовых операций в банках»; «Банковские платежные системы»; «Новации в развитии национальной платежной системы»; «Международная практика управления рисками валютных, кредитных и финансовых операций»."
    },
    {
        id:6,
        image:mazur,
        fio:"Мазур Елена Сергеевна кандидат экономических наук доцент",
        strucunit:"Кафедра финансов и кредита",
        jobtitle:"Доцент",
        listdiscip:"«Банковский бизнес»; «Финансовый анализ (продвинутый уровень)»; «Банковское законодательство»; «Валютное регулирование и валютный контроль»; «Корпоративное управление»; «Корпоративные стратегии»; «Международные валютно-кредитные отношения»; «Международный банковский бизнес»; «Международный финансовый менеджмент»; «Методика и методология научного исследования»; «Оценка стоимости бизнеса»; «Финансовые рынки и финансово-кредитные институты»; «Финансовый механизм государственных закупок»; «Документооборот в финансово-экономической деятельности»; «Управление стоимостью компании»; «Финансовые рынки»; «Реорганизация бизнеса: слияния и поглощения»; «Особенности анализа в сегментах бизнеса»; «Информационная безопасность и электронный документооборот в финансовой отрасли»; «Современные методики анализа финансовых рынков»"
    },
    {
        id:7,
        image:safronov,
        fio:"Сафронов Юрий Михайлович кандидат экономических наук доцент",
        strucunit:"Кафедра финансов и кредита",
        jobtitle:"Доцент Заведующий кафедрой",
        listdiscip:"«Деньги. Кредит. Банки»; «Организация денежно-кредитного регулирования»; «Организация деятельности центрального банка и оценка деятельности коммерческого банка»; «Современные финансовые отношения»; «Современные концепции финансов и кредита»; «Финансовые и денежно-кредитные методы регулирования экономики»; «Валютно-финансовые системы развитых и развивающихся стран»"
    },
    {
        id:8,
        image:grosul,
        fio:"Гросул Наталья Сергеевна без ученой степени без учёного звания",
        strucunit:"Кафедра финансов и кредита",
        jobtitle:"Старший преподаватель",
        listdiscip:"«Бизнес-аналитика»; «Бюджетная система ПМР»; «Введение в бизнес-анализ»; «Корпоративные финансы»; «Корпоративные финансы (продвинутый уровень)»; «Маркетинг в коммерческом банке»; «Моделирование банковской и финансовой деятельности»; «Налогообложение финансово-кредитных институтов»; «Страхование»; «Корпоративное страхование»;  «Финансы бюджетных организаций»; «Финансы организаций»; «Количественные методы корпоративных финансов»; «Бизнес-прогнозирование и финансовое моделирование»; «Управление внешним долгом»"
    },
    {
        id:9,
        image:gushkan,
        fio:"Гушкан Ирина Николаевна без ученой степени без учёного звания",
        strucunit:"Кафедра финансов и кредита",
        jobtitle:"Старший преподаватель",
        listdiscip:"«Налоги и налогообложение»"
    },
    {
        id:10,
        image:undefined,
        fio:"Есир Алла Ивановна без ученой степени без учёного звания",
        strucunit:"Кафедра финансов и кредита",
        jobtitle:"Старший преподаватель",
        listdiscip:"«Долгосрочная и краткосрочная финансовая политика»; «Инвестиции»; «Инвестиционная стратегия»; «Инвестиционный анализ»; «Краткосрочная и долгосрочная финансовая политика»; «Финансовая среда организации»; «Финансовая среда предпринимательства и предпринимательские риски»; «Международное инвестиционное кредитование»; «Финансово-инвестиционные расчеты»; «Финансовые расчеты для нефинансовых и финансовых организаций»; «Финансовый менеджмент»"
    },
    {
        id:11,
        image:undefined,
        fio:"Кискул Ольга Александровна без ученой степени без учёного звания",
        strucunit:"Кафедра финансов и кредита",
        jobtitle:"Старший преподаватель",
        listdiscip:"«Введение в профессиональную деятельность»; «Финансы, денежное обращение и кредит»; «Финансы»; «Публичные финансы: мировой опыт»; «Финансы и финансовые операции в общественном секторе»; «Финансы и кредит»"
    },
    {
        id:12,
        image:undefined,
        fio:"Зинченко Григорий Сергеевич без ученой степени без учёного звания",
        strucunit:"Кафедра финансов и кредита",
        jobtitle:"Старший преподаватель",
        listdiscip:"«Теневая экономика»"
    },
    {
        id:13,
        image:vasilatii,
        fio:"Василатий Олег Валерьевич без ученой степени без учёного звания",
        strucunit:"Кафедра финансов и кредита",
        jobtitle:"Старший преподаватель",
        listdiscip:"«Основы взаимодействия государства и бизнеса»; «Бюджетная система и бюджетный процесс в ПМР»"
    },
    {
        id:14,
        image:undefined,
        fio:"Колесник Яна Андреевна без ученой степени без учёного звания",
        strucunit:"Кафедра финансов и кредита",
        jobtitle:"Специалист",
        listdiscip:""
    },
    {
        id:15,
        image:stratulat,
        fio:"Стратулат Симона Эдуардовна без ученой степени без учёного звания",
        strucunit:"Кафедра финансов и кредита",
        jobtitle:"Специалист",
        listdiscip:""
    },
]
const Dep3 = () => {
    return listDep3;
}

export default Dep3;