import dedication from "./../img/dedication.jpg";
import gramota2 from "./../img/gramota2.jpg";
import gramota3 from "./../img/gramota3.jpg";
import gramota4 from "./../img/gramota4.jpg";
import gramota5 from "./../img/gramota5.jpg";
import gramota6 from "./../img/gramota6.jpg";
import gramota from "./../img/gramota.jpg";
import student from "../img/IMG_3111.jpg";
import student1 from "./../img/student1.jpg";
import student2 from "./../img/student2.jpg";
import student4 from "../img/student4.jpg";
import student5 from "./../img/student5.jpg";
import student7 from "./../img/student7.jpg";
import student8 from "./../img/student8.jpg";
import student9 from "./../img/student9.jpg";
import student10 from "./../img/student10.jpg";
import student11 from "./../img/student11.jpg";
import student12 from "./../img/student12.jpg";
import student13 from "./../img/student13.jpg";
import photo from "../img/photo.jpg";
import best1 from "./../img/best1.jpg";
import best2 from "./../img/best2.jpg";
import best3 from "./../img/best3.jpg";
import best4 from "./../img/best4.jpg";
import best5 from "./../img/best5.jpg";
import best6 from "./../img/best6.jpg";
import best7 from "./../img/best7.jpg";
import best8 from "./../img/best8.jpg";
import best9 from "./../img/best9.jpg";
import best10 from "./../img/best10.jpg";
import best11 from "./../img/best11.jpg";
import best12 from "./../img/best12.jpg";
import best13 from "./../img/best13.jpg";
import photo_2 from "../img/photo_2.jpg";
import photo_3 from "../img/photo_3.jpg";
import File from "../img/File.png";
import photo_4 from "../img/photo_4.jpg";
import sovet from "../img/sovet.jpg";
import victory from "../img/victory.jpg";
import victory1 from "../img/victory1.jpg";
import victory2 from "../img/victory2.jpg";
import victory3 from "../img/victory3.jpg";
import victory4 from "../img/victory4.jpg";
import victory5 from "../img/victory5.jpg";
import victory6 from "../img/victory6.jpg";
import victory7 from "../img/victory7.jpg";
import victory8 from "../img/victory8.jpg";
import victory9 from "../img/victory9.jpg";
import photo_6 from "../img/photo_6.jpg";
import photo_5 from "../img/photo_5.jpg";
import rally1 from "./../img/rally1.jpg";
import rally2 from "./../img/rally2.jpg";
import rally3 from "./../img/rally3.jpg";
import rally4 from "./../img/rally4.jpg";
import rally5 from "./../img/rally5.jpg";
import rally6 from "./../img/rally6.jpg";
import image from "../img/image.png";
import round1 from "./../img/round1.png";
import round2 from "./../img/round2.png";
import round3 from "./../img/round3.png";
import round4 from "./../img/round4.png";
import round5 from "./../img/round5.png";
import round6 from "./../img/round6.png";
import round7 from "./../img/round7.png";
import round8 from "./../img/round8.png";
import round9 from "./../img/round9.png";
import round10 from "./../img/round10.png";
import round11 from "./../img/round11.png";
import round12 from "./../img/round12.png";
import round13 from "./../img/round13.png";
import round14 from "./../img/round14.png";
import round15 from "./../img/round15.png";
import image_1 from "../img/image_1.png"
import glebov1 from "./../img/glebov1.png";
import glebov2 from "./../img/glebov2.png";
import glebov3 from "./../img/glebov3.png";
import glebov4 from "./../img/glebov4.png";
import glebov5 from "./../img/glebov5.png";
import glebov6 from "./../img/glebov6.png";
import glebov7 from "./../img/glebov7.png";
import glebov8 from "./../img/glebov8.png";
import glebov9 from "./../img/glebov9.png";
import glebov10 from "./../img/glebov10.png";
import glebov11 from "./../img/glebov11.png";
import glebov12 from "./../img/glebov12.png";
import glebov13 from "./../img/glebov13.png";
import glebov14 from "./../img/glebov14.png";
import glebov15 from "./../img/glebov15.png";
import spring from "./../img/spring.jpg";
import relay from "./../img/relay.png";
import relay1 from "./../img/relay1.png";
import relay2 from "./../img/relay2.png";
import rallyEc from "./../img/rallyEc.png";
import rallyEc1 from "./../img/rallyEc1.png";
import rallyEc2 from "./../img/rallyEc2.png";
import rallyEc3 from "./../img/rallyEc3.png";
import rallyEc4 from "./../img/rallyEc4.png";
import rallyEc5 from "./../img/rallyEc5.png";
import rallyEc6 from "./../img/rallyEc6.png";
import rallyEc7 from "./../img/rallyEc7.png";
import volonteer from "./../img/volonteer.png";
import volonteer1 from "./../img/volonteer1.png";
import volonteer2 from "./../img/volonteer2.png";
import volonteer3 from "./../img/volonteer3.png";
import volonteer4 from "./../img/volonteer4.png";
import applicant from "./../img/applicant.png";
import stipendia from "./../img/stipendia.jpg";
import audience from "./../img/audience.png";
import audience1 from "./../img/audience1.png";
import audience2 from "./../img/audience2.png";
import audience3 from "./../img/audience3.png";
import audience4 from "./../img/audience4.png";
import play from "./../img/play.png";
import play1 from "./../img/play1.png";
import play2 from "./../img/play2.png";
import play3 from "./../img/play3.png";
import play4 from "./../img/play4.png";
import play5 from "./../img/play5.png";
import play6 from "./../img/play6.png";
import play7 from "./../img/play7.png";
import bufet from "./../img/bufet.png";
import bufet1 from "./../img/bufet1.png";
import slaid1 from "./../img/slaid1.jpg";
import slaid2 from "./../img/slaid2.png";
import movie from "./../img/movie.png";
import elder from "./../img/elder.png";
import homeland from "./../img/homeland.jpg";
import homeland1 from "./../img/homeland1.jpg";
import homeland2 from "./../img/homeland2.jpg";
import council from "./../img/sovet.jpg";
import posvash from "./../img/posvash.jpg";
import kur1 from "./../img/1.jpg";
import kur2 from "./../img/2.jpg";
import kur3 from "./../img/3.jpg";
import kur4 from "./../img/4.jpg";
import kur5 from "./../img/5.jpg";
import kur6 from "./../img/6.jpg";
import kur7 from "./../img/7.jpg";
import kur8 from "./../img/8.jpg";
import start from "./../img/start.jpg";
import grant1 from "./../img/grant1.jpg";
import grant2 from "./../img/grant2.jpg";
import grant3 from "./../img/grant3.jpg";
import grant4 from "./../img/grant4.jpg";
import grant5 from "./../img/grant5.jpg";
import grant6 from "./../img/grant6.jpg";
import grant7 from "./../img/grant7.jpg";
import nabor11 from "./../img/nabor11.png";
import nabor11_1 from "./../img/nabor11(1).png";
import nabor11_2 from "./../img/nabor11(2).png";
import nabor11_3 from "./../img/nabor11(3).png";
import stud1 from "./../img/stud1.jpg";
import stud2 from "./../img/stud2.jpg";
import stud3 from "./../img/stud3.jpg";
import stud4 from "./../img/stud4.jpg";
import stud5 from "./../img/stud5.jpg";
import stud6 from "./../img/stud6.jpg";
import stud7 from "./../img/stud7.jpg";
import stud8 from "./../img/stud8.jpg";
import openday from "./../img/openday.jpg";
import sert1 from "./../img/sert1.jpg";
import sert2 from "./../img/sert2.jpg";
import sert3 from "./../img/sert3.jpg";
import sert4 from "./../img/sert4.jpg";

const newsData = [
    {
        id: 39,
        images: [sert1, sert2, sert3, sert4],
        h1: "Международная школа СНО: новые горизонты для студенческой науки",
        p: "С 17 по 19 октября состоялась Международная школа СНО, организованная МГУ им. М.В. Ломоносова.",
        data: "02.11.2024",
        type: "СТУДЕНЧЕСКАЯ НАУКА АКТУАЛЬНОЕ",
        link: "",
        about: `<p>В онлайн-формате прошли интенсивные тренинги и мастер-классы, посвященные актуальным вопросам развития студенческой науки.</p><br/>
        <p>⭐️От нашего университета в школе приняли участие руководители научных кружков: Ляшкова Е.А., Человская Е.И., Формусатий Д.В., а также председатель научного комитета ЭФ Жильцова Юлия и студентка 1 курса Репида Алина.</p><br/>
        <p>🗣️Участники школы познакомились с лучшими практиками организации СНО в различных ВУЗах, обсудили существующие проблемы и разработали эффективные решения. По итогам мероприятия все участники получили сертификаты.</p><br/>`
    },
    {
        id: 38,
        images: [openday],
        h1: "Дорогие будущие студенты!",
        p: "Приглашаем вас на день открытых дверей нашего факультета! ",
        data: "02.11.2024",
        type: "АКТУАЛЬНОЕ",
        link: "",
        about: `<p>Это уникальная возможность познакомиться с нашим факультетом, задать интересующие вопросы и узнать о студенческой жизни из первых уст.</p><br/>`
    },
    {
        id: 37,
        images: [stud1, stud2, stud3, stud4, stud5, stud6, stud7, stud8],
        h1: "Посвящение в студенты на экономическом факультете",
        p: "Торжественное посвящение первокурсников в студенты экономического факультета 29 октября проходило в КПЦ ПГУ.",
        data: "31.10.2024",
        type: "АКТУАЛЬНОЕ",
        link: "",
        about: `<p>Партнёрами мероприятия стали Министерство просвещения ПМР, ассоциация социальных предпринимателей, агентство инноваций и развития, профком студентов ПГУ.</p><br/> 
        <p>Перед началом посвящения ведущие объявили об организации благотворительного сбора в рамках волонтёрского движения экономического факультета «Добро вокруг нас». Сбор средств проводился для четырёхлетнего Саши Садковского, нуждающегся в дорогостоящем лечении после перенесенного коронавируса с тяжелыми осложнениями.</p><br/>
        <p>Концертную программу открыл образцовый танцевальный коллектив «Жемчужинка» Центра детско-юношеского творчества г. Слободзея под руководством С.Н. Сауленко.</p><br/>
        <p>Церемония посвящения в студенты проходила в формате конкурса творческих визиток. Задача студентов состояла в том, чтобы представить свою группу как можно ярче и креативнее. Всего было 9 команд.  Совместно с кураторами групп первокурсники устроили настоящее шоу, с танцами, веселыми скетчами и презентационными видеороликами. Каждая команда проявила оригинальность в создании творческих номеров, начиная с музыкальной подборки и заканчивая сценическими образами.</p><br/>
        <p>Выступления оценивались жюри, в состав которого вошли руководитель образцового танцевального коллектива «Жемчужинка» С.Н. Сауленко, начальник Управления по молодежной политике и дополнительному образованию Министерства просвещения ПМР Г.В. Ратушняк, главный специалист УМПиДО В.И. Циненко, проректор по молодежной политике ПГУ И.А. Спивак, директор агентства инноваций и развития Т.В. Жолобова.</p><br/>
        <p>Пока жюри обсуждало презентации команд, на сцену для вручения зачётных книжек был приглашён декан <a href="#" target="_blank" style="text-decoration:none;">экономического факультета</a> И.Н. Узун. Иван Николаевич тепло поздравил первокурсников с началом их студенческой жизни. «Сегодняшний день показал, что вы, действительно, молодцы! Вы учитесь, стараетесь, и у вас это получается», – отметил декан. После вручения зачётных книжек первокурсники по традиции принесли «Клятву студента».</p><br/>
        <p>Затем были оглашены команды-победители творческого конкурса. Первое место заняла группа специальности «Финансово-экономическая безопасность», второе – группа профиля «Бухгалтерский учет, анализ и аудит», третье – группа профиля «Финансы и кредит». Призёры и участники конкурса получили грамоты и сладкие призы от спонсоров.  Руководителю танцевального коллектива «Жемчужинка» было вручено благодарственное письмо от Министерства просвещения. За активное участие в церемонии посвящения в студенты и артистизм грамотами Министерства просвещения были награждены студентки первого курса Алина Ефодьева и Дарья Землянцева, за активное и креативное сопровождение в социальных сетях мероприятий слёта <a href="#" target="_blank" style="text-decoration:none;">экономического факультета</a> – студентка второго курса Анастасия Шокот.</p><br/>
        <p>Для комфортного спортивного досуга студентов   Министерство просвещения пополнило спортинвентарь <a href="#" target="_blank" style="text-decoration:none;">экономического факультета</a> новыми мячами и дартсом. Ассоциация социальных предпринимателей и агентство инноваций и развития каждой кафедре подарили набор канцелярских товаров.</p><br/>        
        <p>… Студенты и преподаватели спускались со сцены под звуки гимна «Эконома» (так любовно называют экономический факультет),  «Эконом – теперь и ваш дом!» могли бы они сказать первокурсникам, получившим свой первый студенческий документ.</p><br/>
        <p style="text-align: right;">Николай Зинченко.</p><br/>`
    },
    {
        id: 36,
        images: [nabor11, nabor11_1, nabor11_2, nabor11_3],
        h1: "Объявление о наборе на программу «Основы экономики и финансовой грамотности»",
        p: "Экономический факультет ГОУ «ПГУ им. Т.Г. Шевченко» с 2010 года проводит обучение по дополнительной программе «Основы экономики и финансовой грамотности». Приглашаются учащиеся 11 классов из всех городов Приднестровья.",
        data: "21.10.2024",
        type: "",
        link: "",
        about: `<p>Занятия стартуют в ноябре и будут проводиться два раза в месяц по субботам в комбинированном формате. Для участия в собеседовании необходимо пройти электронную регистрацию до 31 октября 2024 года. Успешно завершившие программу получат сертификаты, которые дают льготы при поступлении на экономический факультет.</p><br/>        
        <p>Дополнительную информацию можно получить по телефону (0533) 79483 или по электронному адресу<br/> <a href="#" target="_blank" style="text-decoration:none;">kafedra-etime@mail.ru.</a></p><br/>
        <p>Регистрация - <a href="https://forms.gle/E4SrbS6MQxnvpTr47" target="_blank" style="text-decoration:none;">https://forms.gle/E4SrbS6MQxnvpTr47</a>
        </p><br/>`
    },
    {
        id: 35,
        images: [grant1, grant2, grant3, grant4, grant5, grant6, grant7],
        h1: "Гранты: от идеи проектов – до их реализации",
        p: "Образовательный интерактив «Социальные гранты: от идеи проектов – до их реализации» для студентов и преподавателей Приднестровского государственного университета стартовал 17 октября.",
        data: "19.10.2024",
        type: "СТУДЕНЧЕСКАЯ НАУКА АКТУАЛЬНОЕ",
        link: "",
        about: `<p>Мероприятие прошло под руководством начальника отдела по работе с общественными объединениями Управления Президента ПМР по внутренней и внешней политике А.В. Березовского.</p><br/>
        <p>Образовательная программа представляет собой отличную платформу для студентов и преподавателей, которые хотят реализовать свои идеи на практике и внести вклад в развитие Приднестровья.</p><br/>        
        <p>На первом занятии А.В. Березовский познакомил присутствующих с Положением о грантах Президента Приднестровской Молдавской Республики, объяснил, кто имеет право подавать заявки на участие и какие существуют требования к проектам. Александр Владимирович подробно рассказал, как правильно оформлять заявление и отметил, что средства, выделяемые на реализацию проекта, могут использоваться исключительно по целевому назначению. Грантополучатели будут обязаны отчитываться о расходах перед государством, а сумма, выделяемая на один проект, составляет от 150 до 900 тысяч рублей ПМР.</p><br/>
        <p>После теоретической части в рамках интерактива участникам предложили сформировать инициативные группы и определиться с номинацией. Конкурс охватывает четырнадцать номинаций, среди которых семейная, демографическая и миграционная политика, содействие занятости населения, развитие социальной среды, общественное здоровье, физическая культура и спорт, сохранение окружающей среды, развитие культуры и искусства, туризм и т.д. Каждое из направлений дает возможность участникам проявить креативность и предложить действенные решения для решения актуальных социальных проблем.</p><br/>
        <p>Образовательный интерактив разделен на несколько этапов: знакомство и формирование команд, обзор заявок, представление и защита проектов. Курс продлится четыре недели, участники будут встречаться раз в неделю для обсуждения и отчета о своих достижениях.</p><br/>
        <p>Инициатива представляет собой значимый шаг на пути к активному вовлечению молодежи в социальные преобразования и реализацию идей, способствующих улучшению общественной жизни в республике.</p><br/>`
    },
    {
        id: 34,
        images: [start],
        h1: "Приглашаем всех студентов и преподавателей принять участие в образовательном интерактиве «Социальные гранты: от идеи проектов - до их реализации»!",
        p: "Александр Владимирович Березовский совместно с ЭФ запускает уникальную программу для развития ваших социальных проектов.",
        data: "18.10.2024",
        type: "СТУДЕНТАМ АКТУАЛЬНОЕ",
        link: "",
        about: `<p>Когда: Начало с 17 октября (четверг) в 15:00.<br/>
        Где: ЭФ 309 аудитория (для очной формы)<br/>
        Для кого: Студенты (с 18 лет) и преподаватели<br/>
        Что: 4-недельная интенсивная программа с практическими занятиями и поддержкой эксперта.</p>
        <br/>        
        <p>Регистрируйся и реализуй свой проект прямо сейчас!⬇️
        <a href="https://docs.google.com/forms/d/1SS9Wrhgqm0YXIT17enfaFQzTxKo4xvhlw4xnH2iaTWw/edit" target="_blank" style="text-decoration:none;">https://docs.google.com/forms/d/1SS9Wrhgqm0YXIT17enfaFQzTxKo4xvhlw4xnH2iaTWw/edit</a></p>`
    },
    {
        id: 33,
        images: [kur1, kur2, kur3, kur4, kur5, kur6, kur7, kur8],
        h1: "14 октября в актовом зале главного корпуса университета состоялся единый кураторский час",
        p: "",
        data: "18.10.2024",
        type: "СТУДЕНТАМ",
        link: "",
        about: `<p>14 октября в актовом зале главного корпуса университета состоялся единый кураторский час – первый из цикла, в рамках которого студенты и преподаватели  всех факультетов, институтов и филиалов будут просматривать видеозапись открытого урока на тему  «Великая Отечественная война 1941-1945 годов», который в свое время провёл для молодёжи Президент ПМР В.Н. Красносельский.  Тема кураторского часа – «Запрет на использование термина «Транснистрия» в качестве названия Республики». В ходе видеоурока  глава нашего государства подробно объясняет, почему это недопустимо по отношению к Приднестровью.</p><br/>
        <p>Заместитель декана по воспитательной работе <a href="http://spsu.ru/university/struct/econom" target="_blank" style="text-decoration:none;">экономического факультета</a> Д.В. Формусатий ознакомила присутствующих в зале студентов экономического и <a href="http://spsu.ru/university/struct/egf" target="_blank" style="text-decoration:none;">естественно-географического факультетов</a> с информацией о том, что Верховный Совет ПМР одобрил законодательную инициативу Президента ПМР о недопустимости использования термина «Транснистрия» в отношении Приднестровья и сразу в двух чтениях принял законопроекты о внесении изменений в Закон о противодействии экстремистской деятельности, Закон об увековечивании победы советского народа в Великой Отечественной войне и в Кодекс об административных правонарушениях.</p><br/>
        <p>В ходе открытого урока В.Н. Красносельский рассказал об основных вехах Великой Отечественной войны, её идеологических предпосылках,  ключевых сражениях и военачальниках, повлиявших на благополучный исход боевых действий. Президент остановился на подвиге блокадного  Ленинграда, битве под Москвой, Сталинградской и Курской битвах, о  наступательных операциях советских войск в 1944-1945 годах, приведших к победе над фашизмом. Затрагивались самые тяжёлые, связанные с войной темы: голод, концентрационные лагеря, бесчеловечные опыты нацистов над людьми, вклад молодых людей – вчерашних школьников и детей - в Победу. «Они были такими же, как вы: любили, мечтали, хотели жить, творить и созидать», – подчеркнул Вадим Николаевич, приведя примеры  подвигов героев Великой Отечественной войны, отмеченных высокими наградами и безымянных.</p><br/>
        <p>Один из таких героев – 20-летний старший сержант артиллерийских войск Николай Сиротинин. Во время Смоленского сражения, прикрывая отступление своего полка, в одиночку в одном бою уничтожил 11 танков, 7 бронемашин, 57 солдат и офицеров противника. За высочайшую степень отваги был похоронен с почестями немецкими солдатами. О его подвиге стало известно из дневника погибшего офицера вермахта. Несмотря на то, что подвиг Николая Сиротинина благодаря стараниям работников архива Советской Армии был признан еще в 1960 году, звание Героя Советского Союза ему присвоено не было. Помешало до боли нелепое обстоятельство: у семьи солдата не оказалось его фотографии. А она необходима для подачи документов на высокое звание. В год 20-летия Победы старший сержант Сиротинин был награжден Орденом Отечественной войны первой степени посмертно. «А сколько таких подвигов, о которых мы не узнаем», – заключил Президент.</p><br/>
        <p>Вадим Николаевич в ходе урока разъяснил, что «Губернаторство Транснистрия» было следствием румынской оккупации Одесской, Винницкой, Николаевской областей Украинской ССР и левобережной части Молдавской ССР во время Второй мировой войны. Оно было ликвидировано <a href="https://ru.wikipedia.org/wiki/%D0%A0%D0%B0%D0%B1%D0%BE%D1%87%D0%B5-%D0%BA%D1%80%D0%B5%D1%81%D1%82%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B0%D1%8F_%D0%9A%D1%80%D0%B0%D1%81%D0%BD%D0%B0%D1%8F_%D0%B0%D1%80%D0%BC%D0%B8%D1%8F" target="_blank" style="text-decoration:none;">советскими</a>  войсками в марте 1944 года <a href="https://ru.wikipedia.org/wiki/20_%D0%BC%D0%B0%D1%80%D1%82%D0%B0" target="_blank" style="text-decoration:none;">в ходе Уманско-Ботошанской  и Днепровско-Карпатской наступательных  операций</a>. Поэтому связанный с  массовым террором и геноцидом  мирного населения термин «Транснистрия» недопустим в отношении Приднестровской Молдавской Республики.</p><br/>
        <p>Николай Зинченко.</p>`
    },
    {
        id: 32,
        images: [posvash],
        h1: "Стоимость билета составит 5 рублей. Все вырученные средства пойдут на благотворительность",
        p: "",
        data: "18.10.2024",
        type: "СТУДЕНТАМ",
        link: ""
    },
    {
        id: 31,
        images: [dedication],
        h1: "1 октября в Культурно-просветительском центре им. святых равноапостольных Кирилла и Мефодия состоялась церемония посвящения первокурсников в студенты, приуроченная ко «Дню университета»",
        p: "",
        data: "01.10.2024",
        type: "СТУДЕНТАМ",
        link: "",
        about: `<p>1 октября в Культурно-просветительском центре им. святых равноапостольных Кирилла и Мефодия состоялась церемония посвящения первокурсников в студенты, приуроченная ко Дню университета. </p><br/>
        <p>Также на мероприятии наградили грамотами студенток нашего факультета, которые приняли участие в патриотическом Конкурсе медиа-материалов «За это я люблю Республику мою!»</p><br/>
        <p>Студентка 106 группы Лыкова Полина  заняла 3 место в номинации «Родины просторы».</p><br/>
        <p>Студентка 405 группы Добровольская Екатерина была награждена за участие  в номинации «Приднестровье: история которую я хочу рассказать».</p><br/>
        <p>Также была награждена студентка 108 группы Руссу Ксения  в  номинации  «Мы частичка России»</p><br/>
        <p>Поздравляем девушек !</p>`
    },
    {
        id: 30,
        images: [gramota2, gramota3, gramota4, gramota5, gramota6],
        h1: "Ученый совет  экономического факультета",
        p: "26 сентября 2024 года состоялся Ученый совет  экономического факультета.",
        data: "26.09.2024",
        type: "УЧЕНЫЙ СОВЕТ",
        link: "",
        about: `<p>Заседание началось с вручения Благодарственных писем Ректора ПГУ 4 студенткам ЭФ (Барба Анна, Григораш Алена, Кандит Валерия и Стерликова Ирина), которые  помогали в профориентационной работе в 2023-2024 г. </p><br/>
        <p>Агитационная работа с привлечением студентов - это отличный опыт прошлого года!</p><br/>`
    },
    {
        id: 29,
        images: [gramota],
        h1: "Легкоатлетический забег",
        p: "20 сентября состоялся легкоатлетический забег, приуроченный ко «Дню физической культуры и спорта Приднестровской Молдавской Республики»",
        data: "20.09.2024",
        type: "",
        link: ""
    },
    {
        id: 28,
        images: [student, student1, student2, student4, student5, student7, student8, student9, student10, student11, student12, student13],
        h1: "От студенчества к профессиональной деятельности",
        p: "Вручение дипломов выпускникам экономического факультета прошло 20 июля в Культурно-просветительском центре ПГУ.",
        data: "21.07.2024",
        type: "СТУДЕНТАМ",
        link: ""
    },
    {
        id: 27,
        images: [photo, best1, best2, best3, best4, best5, best6, best7, best8, best9, best10, best11, best12, best13],
        h1: "Названы лучшие студенты экономического факультета",
        p: "18 июня в актовом зале главного корпуса состоялось подведение итогов научно-исследовательской работы студентов экономического факультета за 2023-2024 учебный год.",
        data: "01.07.2024",
        type: "НАУКА СТУДЕНЧЕСКАЯ БИЗНЕС-ШКОЛА",
        link: ""
    },
    {
        id: 26,
        images: [photo_2],
        h1: "Порядок отбора кандидатов на получение государственной стипендии Президента Приднестровской Молдавской Республики",
        p: "",
        data: "19.06.2024",
        type: "",
        link: ""
    },
    {
        id: 25,
        images: [photo_3],
        h1: "Ежегодная церемония подведения итогов научной работы студентов экономического факультета ГОУ «ПГУ им. Т.Г. Шевченко» за 2023-2024 уч. год",
        p: "18 июня в 13:00 состоится ежегодная церемония  подведения итогов научной работы студентов экономического факультета ГОУ «ПГУ им. Т.Г. Шевченко» за 2023-2024 уч. год",
        data: "14.06.2024",
        type: "СТУДЕНТАМ",
        link: ""
    },
    {
        id: 24,
        images: [File],
        h1: "Бесплатные курсы по Технологическому предпринимательству и E-commerce",
        p: "Уважаемые друзья! Мы рады пригласить вас на бесплатные курсы по Технологическому предпринимательству и E-commerce!",
        data: "12.06.2024",
        type: "",
        link: ""
    },
    {
        id: 23,
        images: [photo_4],
        h1: "Экономический факультет Приднестровского государственного университета объявляет набор абитуриентов в 2024 году",
        p: "Прием документов на обучение по программам высшего и дополнительного профессионального образования проводится с 17 июня по 20 июля 2024 года.",
        data: "08.06.2024",
        type: "ПОСТУПАЮЩИМ",
        link: ""
    },
    {
        id: 22,
        images: [image, round1, round2, round3, round4, round5, round6, round7, round8, round9, round10, round11, round12, round13, round14, round15],
        h1: "Круглый стол на экономическом факультете",
        p: "На кафедре экономики и менеджмента экономического факультета 30 мая состоялся круглый стол «Основные макроэкономические показатели в Приднестровской Молдавской Республике и формирование мер по их позитивному изменению».",
        data: "31.05.2024",
        type: "НАУКА",
        link: ""
    },
    {
        id: 21,
        images: [sovet],
        h1: "Информация о решениях Ученого совета экономического факультета – 30 мая 2024г",
        p: "",
        data: "30.05.2024",
        type: "УЧЕНЫЙ СОВЕТ",
        link: ""
    },
    {
        id: 20,
        images: [photo_5, rally1, rally2, rally3, rally4, rally5, rally6],
        h1: "Наши студенты приняли участие на слёте Весна Победы-2024",
        p: "В лагере «Сэнэтате» в Кицканском лесу 24 мая  прошёл гражданско-патриотический спортивный слёт «Весна Победы - 2024». Его участниками стали 11 студенческих команд Приднестровского госуниверситета им. Т. Г. Шевченко.",
        data: "28.05.2024",
        type: "",
        link: ""
    },
    {
        id: 19,
        images: [image_1, glebov1, glebov2, glebov3, glebov4, glebov5, glebov6, glebov7, glebov8, glebov9, glebov10, glebov11, glebov12, glebov13, glebov14, glebov15],
        h1: "Виталий Иванович Глебов: тридцать лет служения Приднестровью",
        p: "16 мая в конференц-зале главного корпуса ПГУ состоялась презентация книги воспоминаний о Виталии Ивановиче Глебове – выдающемся общественном деятеле Приднестровья, стоявшем у истоков создания республики.",
        data: "19.05.2024",
        type: "",
        link: ""
    },
    {
        id: 18,
        images: [photo_6, victory1, victory2, victory3, victory4, victory5, victory6, victory7, victory8, victory9],
        h1: "С Днём Победы!",
        p: "9 мая на площадке перед главным корпусом университета состоялся митинг-концерт в честь Дня Победы.",
        data: "09.05.2024",
        type: "",
        link: ""
    },
    {
        id: 17,
        images: [victory],
        h1: "Наши студенты приняли участие в проекте «Я ПОМНЮ, Я ГОРЖУСЬ!»",
        p: "7 мая наши студенты приняли участие в уборке могил участников ВОВ в рамках проекта «Я ПОМНЮ, Я ГОРЖУСЬ!».",
        data: "07.05.2024",
        type: "",
        link: ""
    },
    {
        id: 16,
        images: [spring],
        h1: "С праздником весны и труда!",
        p: "Уважаемые преподаватели, сотрудники, студенты и аспиранты Приднестровского государственного университета им. Т.Г. Шевченко! Сердечно поздравляю вас с прекрасным праздником весны, труда и созидания!",
        data: "01.05.2024",
        type: "",
        link: ""
    },
    {
        id: 15,
        images: [rallyEc, rallyEc1, rallyEc2, rallyEc3, rallyEc4, rallyEc5, rallyEc6, rallyEc7],
        h1: "24 апреля состоялся IV Республиканский слет «Наш дом – Эконом».",
        p: "В слете приняли участие студенты и преподаватели экономического факультета, а также выпускники факультета, члены Молодежного парламента.",
        data: "29.04.2024",
        type: "СТУДЕНТАМ НАШ ДОМ - ЭКОНОМ",
        link: ""
    },
    {
        id: 14,
        images: [relay, relay1, relay2],
        h1: "Студенты нашего факультета приняли участие в эстафете",
        p: "12 апреля состоялась 75-я городская традиционная легкоатлетическая эстафета, посвященная 80-летию со дня освобождения Тирасполя от фашистской оккупации.",
        data: "15.04.2024",
        type: "",
        link: ""
    },
    {
        id: 13,
        images: [volonteer, volonteer1, volonteer2, volonteer3, volonteer4],
        h1: "Студенты участвовали в субботнике",
        p: "В воскресенье экономический факультет пригласили в Республиканский специализированный дом ребенка, на проведение субботника, целью которого было обновить краску на детской площадке и перилах здания.",
        data: "15.04.2024",
        type: "ВОЛОНТЕРСТВО",
        link: ""
    },
    {
        id: 12,
        images: [applicant],
        h1: "Приднестровский государственный университет им. Т.Г. Шевченко проводит предварительное тестирование для желающих поступить в университет.",
        p: "",
        data: "08.04.2024",
        type: "ПОСТУПАЮЩИМ",
        link: ""
    },
    {
        id: 11,
        images: [homeland, homeland1, homeland2],
        h1: "Прошёл кураторский час на тему: «Малая Родина»",
        p: "Сегодня , 25 марта , в Актовом зале прошёл кураторский час на тему: «Малая Родина», приуроченный к трагическим событиям, произошедшим 2 марта 1992 года - дню начала отражения широкомасштабной агрессии РМ против ПМР.",
        data: "25.03.2024",
        type: "СТУДЕНТАМ",
        link: ""
    },
    {
        id: 10,
        images: [elder],
        h1: "Старостат за 21.03.24",
        p: "",
        data: "22.03.2024",
        type: "СТАРОСТАТ",
        link: ""
    },
    {
        id: 9,
        images: [stipendia],
        h1: "Прошла церемония награждения победителей проекта «Именные стипендии компании «Шериф»",
        p: "В Тирасполе 20 марта прошла церемония награждения победителей проекта «Именные стипендии компании «Шериф»» в рамках социальной программы «Ты нам нужен».",
        data: "21.03.2024",
        type: "",
        link: ""
    },
    {
        id: 8,
        images: [audience, audience1, audience2, audience3, audience4],
        h1: "Состоялось открытие обновленной аудитории",
        p: "20 марта на экономическом факультете состоялось открытие обновленной аудитории № 309.",
        data: "21.03.2024",
        type: "",
        link: ""
    },
    {
        id: 7,
        images: [play, play1, play2, play3, play4, play5, play6, play7],
        h1: "Состоялась игра «Будь в теме»",
        p: "15 марта 2024 года впервые была проведена Интеллектуальная игра для школьников по финансовой грамотности «Будь в теме» в Культурно-просветительском центре ПГУ.",
        data: "18.03.2024",
        type: "",
        link: ""
    },
    {
        id: 6,
        images: [bufet, bufet1],
        h1: "Открылся буфет",
        p: "Уважаемые студенты и преподаватели Экономического факультета, рады Вам сообщить об открытии буфета на первом этаже на нашем факультете !",
        data: "15.03.2024",
        type: "СТУДЕНТАМ",
        link: ""
    },
    {
        id: 5,
        images: [slaid1],
        h1: "Состоялась встреча с заведующей кафедрой БУиА",
        p: "Состоялась встреча заведующей кафедрой бухгалтерского учета и аудита Стасюк Т.П. со слушателями ЮЗШ.",
        data: "05.03.2024",
        type: "ЮЗШ",
        link: ""
    },
    {
        id: 4,
        images: [slaid2],
        h1: "Состоялась встреча с заведующим кафедрой БИиИТ",
        p: "Состоялась встреча заведующего кафедрой бизнес-информатики и информационных технологий со слушателями ЮЗШ.",
        data: "05.03.2024",
        type: "ЮЗШ",
        link: ""
    },
    {
        id: 3,
        images: [movie],
        h1: "Показ фильма «28 панфиловцев»",
        p: "Приглашаем студентов и преподавателей на кинопоказ фильма «28 панфиловцев». Дата и место проведения: 26 февраля в 15:00 в КПЦ.",
        data: "25.02.2024",
        type: "СТУДЕНТАМ",
        link: ""
    },
    {
        id: 2,
        images: [elder],
        h1: "Старостат за 15.02.24",
        p: "",
        data: "25.02.2024",
        type: "СТАРОСТАТ",
        link: ""
    },
    {
        id: 1,
        images: [council],
        h1: "Приглашаем членов Ученого совета экономического факультета на очередное заседание",
        p: "",
        data: "18.02.2024",
        type: "УЧЕНЫЙ СОВЕТ",
        link: ""
    }
];

const News = () => {
    return newsData;
}

export default News;