import React from "react";

class Students extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hoveredIndex: null
        };
    }
    handleMouseEnter = (index) => {
        this.setState({hoveredIndex: index});
    };

    handleMouseLeave = () => {
        this.setState({hoveredIndex: null})
    }

    render() {
        const { className } = this.props;
        const { hoveredIndex } = this.state;
        const menuItems = [
            "Студенческая жизнь",
            "Студенческое управление",
            "Старостат",
            "Студенческие проекты",
            "Профком"
        ]
        return(
            <nav className={`menu-students ${className}`}>
                <ul className="list-menu__students">
                {menuItems.map((item, index) => (
                        <li key={index} onMouseEnter = {() => this.handleMouseEnter(index)}
                        onMouseLeave={this.handleMouseLeave}
                        style={{color: hoveredIndex === index ? '#43a5ee': 'black'}}>
                            {item}
                        </li>
                    ))}
                </ul>
            </nav>
        )
    }
}


export default Students;