import React from "react";
import Dep3 from "./Dep3";

class Dep3Table extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allDep3: Dep3()
        };
    }
    render() {
        const {allDep3} = this.state;

        return ( <>
            <table style={{border:"1px"}}>
                <thead> 
                    <tr> 
                        <th>Фото</th> 
                        <th>Ф.И.О</th> 
                        <th>Структурное подразделение</th> 
                        <th>Должность</th> 
                        <th>Перечень преподаваемых дисциплин</th> 
                    </tr> 
                </thead>
                <tbody>
                    {allDep3.map((person) => (
                        <tr key={person.id}>
                        <td>
                            <img 
                            src={person.image} 
                            alt={person.fio} 
                            style={{
                                width: "200px", 
                                height: "auto", 
                                objectFit: "cover"
                            }} />
                        </td>
                        <td>{person.fio}</td>
                        <td>{person.strucunit}</td>
                        <td>{person.jobtitle}</td>
                        <td>{person.listdiscip}</td>
                    </tr>
                    ))}
                    
                </tbody>
            </table>
            </> );
    }
}
 
export default Dep3Table;