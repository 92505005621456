import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import receipt from "./../../img/receipt.png";

const Price = () => {
    return ( <>
    <Header/>
    <main>
        <table>
            <tr className="row3">
                <td className="td" style={{verticalAlign: 'top'}}>Год</td>
                <td className="td" colSpan={5} style={{textAlign: 'center'}}>Дневная форма обучения</td>                                        
                <td className="td" colSpan={5} style={{textAlign: 'center'}}>Заочная форма обучения</td>                
            </tr>
            <tr>  
                <td rowSpan={3}>2024</td>             
                <td>Всего за год</td>
                <td colSpan={2}>Январь-август</td>               
                <td colSpan={2}>Сентябрь-декабрь</td>                
                <td>Всего за год</td>
                <td colSpan={2}>Январь-август</td>               
                <td colSpan={2}>Сентябрь-декабрь</td>             
            </tr>
            <tr>
                <td>Сумма, руб.ПМР</td>
                <td>Сумма, руб.ПМР</td>
                <td>Срок оплаты</td>
                <td>Сумма, руб.ПМР</td>
                <td>Срок оплаты</td>
                <td>Сумма, руб.ПМР</td>
                <td>Сумма, руб.ПМР</td>
                <td>Срок оплаты</td>
                <td>Сумма, руб.ПМ</td>
                <td>Срок оплаты</td>
            </tr>
            <tr>
                <td>12 756</td>
                <td>8 504</td>
                <td>До 31 августа</td>
                <td>4 252</td>
                <td>До 31 января</td>
                <td>6 378</td>
                <td>4 252</td>
                <td>До 31 августа</td>
                <td>2 126</td>
                <td>До 31 января</td>
            </tr>
        </table>     
        <p className="price-text price-text-2">Всем студентам!!!</p> 
        <p className="price-text-2">(договорной формы обучения)</p> 
        <p className="price-text-2"><span className="price-text">Как</span> оплачивать обучение</p> 
        <p className="price-text-2 price-text__italic">Бухгалтерия ПГУ квитанции на оплату не выдает!!!</p> 

        <ul className="ul-price">
            <li>Оплата за обучение производится <span className="price-text">только </span>в отделениях (интернет-банках, терминалах, мобильных приложениях) ЗАО "Агропромбанк", ОАО "Эксимбанк", ЗАО "Приднестровский Сбербанк". <span style={{fontWeight:'bold'}}>Для оплаты в отделениях банка </span> предоставляется договор или произвольная записка с ФИО <span style={{fontWeight:'bold', textTransform:'uppercase'}}>студента </span> (без сокращения) + факультет + период обучения + указываете сумму, которую вносите (банк сумму не контролирует), например:<br/>
            </li>
            <li>
            ГОУ ПГУ им. Т.Г.Шевченко, р/с 218000005023312<br/>
            Назначение платежа: За обучение сч.400.32.0<br/>
            Факультет<br/>
            Период с 01.09.202__<sub>текущего года </sub>по 31.12.202__<sub>текущего года, </sub>или с 01.09.202__<sub>текущего года </sub>по 31.12.202__<sub>текущего года </sub>(указать нужный)<br/>
            Полное ФИО студента, если требуется - Оплата ч/з ФИО родителя<br/>
            Сумма<br/>
            </li>
            <li>При оплате внимательно проверять ФИО плательщика. Если родители предоставляют себе на работу документы на предоставление налогового вычета (оплата за обучение ребенка) обязательно предупреждать об этом в банке оператора, что оплата через родителей.</li>
            <li>Приложение к договору при последующих оплатах заполняет сам студент. Бухгалтер своей подписью подтверждает принятую сумму (через 3-4 рабочих дня после оплаты или в конце учебного года).</li>
            <li><span  className="price-text-2 price-text__italic">Квитанцию об оплате в бухгалтерию ПГУ - не предоставлять!!!</span></li>
        </ul><br/>
        <p className="price-text-2 price-text__italic">Вариант квитанции, который можно написать от руки</p>
        <div style={{display:'flex', justifyContent:'center',alignItems:'center'}}><img src={receipt} style={{width:'500px', margin:'10px'}}/></div>

        <h2 style={{textAlign:'center', lineHeight:'1.5', color:'#43a5ee', marginTop:'20px'}}>Как оплачивать обучение</h2><br/>
        <p>На усмотрение Университета в исключительных случаях студенту может быть предоставлена рассрочка оплаты образовательных услуг.<br/>
        Студенту может быть предоставлена возможность перевода на обучение за счет средств бюджета при наличии вакантных бюджетных мест в рамках одной специальности и курса, при успеваемости только «на отлично».</p>
        <h2 style={{textAlign:'center', lineHeight:'1.5', color:'#43a5ee', marginTop:'20px'}}>Скидка за обучение</h2>
        <p>Согласно Закону Приднестровской Молдавской Республики от 11.05.2006 г. № 26-З-IV «О государственной поддержке многодетных семей» многодетным семьям (их членам)<span style={{color:'#43a5ee', fontWeight:'bold'}}>*</span> в качестве одной из мер государственной социальной поддержки предоставляется «скидка в размере 50 % с оплаты стоимости обучения при получении образования соответствующего уровня впервые на договорной форме обучения в государственных организациях начального профессионального, среднего профессионального и высшего профессионального образования».</p>
        <ul className="ul-price">
        Для <span style={{color:'#43a5ee', fontWeight:'bold'}}>получения </span>скидки при оплате за обучение студенту до <span style={{color:'#43a5ee', fontWeight:'bold'}}>20 сентября текущего года</span> необходимо предоставить в деканат дневного отделения (каб. 202) следующие документы:
            <li>заявление о предоставлении скидки (установленного образца);</li>
            <li>копию паспорта ПМР (1,2 страницы + прописка);</li>
            <li>копию действующего <span style={{color:'#43a5ee'}}>удостоверения о праве на льготы многодетным семьям.</span></li>
        </ul>

        <ul className="ul-price">
        Для <span style={{color:'#43a5ee', fontWeight:'bold'}}>продления </span>скидки при оплате за обучение студенту до <span style={{color:'#43a5ee', fontWeight:'bold'}}>20 сентября текущего года </span>необходимо предоставить в деканат дневного отделения (каб. 202) следующие документы:
            <li>заявление о продлении скидки (установленного образца);</li>
            <li>копию продленного удостоверения о праве на льготы многодетным семьям.</li>
        </ul>
        <br/>
        <p><span style={{color:'#43a5ee', fontWeight:'bold'}}>
        *Многодетная семья – семья, имеющая в своем составе 3 (трех) и более детей, включая усыновленных и принятых под опеку (попечительство), а также пасынков и падчериц, и воспитывающая их до восемнадцатилетнего возраста, а обучающихся в организациях общего и профессионального образования (вне зависимости от формы собственности организации образования) по очной (дневной) форме обучения, а также по заочной форме обучения, если обучающийся является инвалидом с детства I, II, III группы, – до окончания ими обучения, но не более чем до достижения ими возраста 23 (двадцати трех) лет.
        </span></p>
    </main>
    <Footer/>
    </> );
}
 
export default Price;