// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    max-width: 1160px;
    margin: 150px auto 20px;
}

p {
    font-size: 20px;
    line-height: 2;
    font-weight: 300;
}

.blue {
    color: #43a5ee;
    font-weight: bold;
}

.decan {
    margin-bottom: 30px;
}

h1 {
    color: #43a5ee;
    margin-bottom: 40px;
}

h3 {
    color: #43a5ee;
}

.history {
    display: flex;
    justify-content: space-between;
}

.decan-card {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.decan-card__item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.decan-card__item h3,
.decan-card__item p {
    margin-top: 5px;
    text-align: center;
}
.decan-card__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 360px;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/pages/history/style.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,SAAS;AACb;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;;IAEI,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".container {\r\n    max-width: 1160px;\r\n    margin: 150px auto 20px;\r\n}\r\n\r\np {\r\n    font-size: 20px;\r\n    line-height: 2;\r\n    font-weight: 300;\r\n}\r\n\r\n.blue {\r\n    color: #43a5ee;\r\n    font-weight: bold;\r\n}\r\n\r\n.decan {\r\n    margin-bottom: 30px;\r\n}\r\n\r\nh1 {\r\n    color: #43a5ee;\r\n    margin-bottom: 40px;\r\n}\r\n\r\nh3 {\r\n    color: #43a5ee;\r\n}\r\n\r\n.history {\r\n    display: flex;\r\n    justify-content: space-between;\r\n}\r\n\r\n.decan-card {\r\n    display: grid;\r\n    grid-template-columns: repeat(3, 1fr);\r\n    gap: 10px;\r\n}\r\n\r\n.decan-card__item img {\r\n    width: 100%;\r\n    height: 100%;\r\n    object-fit: cover;\r\n}\r\n\r\n.decan-card__item h3,\r\n.decan-card__item p {\r\n    margin-top: 5px;\r\n    text-align: center;\r\n}\r\n.decan-card__item {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    max-width: 360px;\r\n    width: 100%;\r\n    padding: 10px;\r\n    box-sizing: border-box;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
