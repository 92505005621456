import React from "react";
import styles from "./Rules.module.css"
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Areas2 = () => {
    return ( <>
        <Header/>
        <main>
            <h3 className={styles["h3-main"]}>Кафедра экономики и менеджмента</h3>
            <h3 className={styles["h3-main"]}>БАКАЛАВРИАТ</h3><br/>
            <p>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Направление подготовки:</span> 38.03.02 «Менеджмент»<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Профиль подготовки: </span>«Управление персоналом»<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Форма и сроки обучения:</span> очная, 4 года<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Профильные предметы (вступительные испытания):</span>
            <ul>
                <li>математика (профильный предмет);</li>
                <li>иностранный язык;</li>
                <li>родной язык.</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Описание направления подготовки: </span>формирует навыки построения успешных коммуникаций, владение навыками формирования эффективных систем оплаты и стимулирования труда, организационного обучения, управления конфликтами, оценки и прогнозирования поведения персонала и его развития, управления талантами и выбора эффективных стратегий развития персонала.<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Чему будут учить: </span>студентов данного профиля будут учить ставить перед подчинённым задачи, самым современным подходам, связанным с подбором, адаптацией и мотивацией персонала, возможности сформировать команду из профессиональных сотрудников с нуля или улучшить работу уже существующего персонала в компании.<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Эту профессию можно освоить: </span>на экономическом факультете при кафедре <span style={{color: '#43a5ee', fontWeight: 'bold'}}>экономики и менеджмента.</span><br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Кем можно будет работать (профессии): </span>выпускники данного профиля способны работать в качестве директора по персоналу различных организаций, менеджера по персоналу (HR-менеджера), специалистов службы управления персоналом предприятий и организаций (по кадровому делопроизводству; по подбору, оценке и аттестации, развитию персонала, по организации и оплате труда; по организации корпоративной социальной политики); сотрудников кадровых и консалтинговых агентств; экспертов и аналитиков в сфере управления человеческими ресурсами; преподавателей, воспитателей, научных работников, государственных или муниципальных служащих.<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Возможные места работы (организации, предприятия): </span>выпускникам данного профиля доверят управление кадрами в любых коммерческих и бюджетных организациях, в государственных и муниципальных учреждениях.<br/><br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Количество бюджетных мест – 2.</span>
            </p>
        </main>
        <Footer/>
        </> );
}
 
export default Areas2;