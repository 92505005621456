// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Rules_rules-main__Q--oa {
    padding-top: 50px;
}

.Rules_h2-main__pN14P {
    text-align: center;
    color: #43a5ee;
    margin-bottom: 20px;
}

.Rules_h3-main__XJ4zH {
    text-align: center;
    line-height: 38px;
    font-size: 28px;
}

.Rules_departments-areas__WQnh\\+ {
    display: grid;
    justify-content: center;
}

.Rules_departments-areases__pBTNe {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

`, "",{"version":3,"sources":["webpack://./src/pages/applicants/Rules.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,SAAS;AACb","sourcesContent":[".rules-main {\r\n    padding-top: 50px;\r\n}\r\n\r\n.h2-main {\r\n    text-align: center;\r\n    color: #43a5ee;\r\n    margin-bottom: 20px;\r\n}\r\n\r\n.h3-main {\r\n    text-align: center;\r\n    line-height: 38px;\r\n    font-size: 28px;\r\n}\r\n\r\n.departments-areas {\r\n    display: grid;\r\n    justify-content: center;\r\n}\r\n\r\n.departments-areases {\r\n    display: grid;\r\n    grid-template-columns: repeat(3, 1fr);\r\n    gap: 20px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rules-main": `Rules_rules-main__Q--oa`,
	"h2-main": `Rules_h2-main__pN14P`,
	"h3-main": `Rules_h3-main__XJ4zH`,
	"departments-areas": `Rules_departments-areas__WQnh+`,
	"departments-areases": `Rules_departments-areases__pBTNe`
};
export default ___CSS_LOADER_EXPORT___;
