import React from "react";
import { withNavigation } from "./withNavigation";
import News from "./News";
import Button from "./Button";
import { BrowserView, MobileView } from "react-device-detect";

class Users extends React.Component {
    constructor(props) {
        super(props);
        this.allUsers = News();
        const {type} = props;
        this.allUsers = News().filter(news => news.type.includes(props.type)); 
        this.state = { 
            visibleUsers: this.allUsers.slice(0, 6) 
        };
    }

    loadMoreUsers = () => {
        const { visibleUsers } = this.state;
        const currentLength = visibleUsers.length;
        const isMoreToLoad = currentLength < this.allUsers.length;

        if (isMoreToLoad) {
            const nextUsers = this.allUsers.slice(currentLength, currentLength + 6);
            this.setState({
                visibleUsers: visibleUsers.concat(nextUsers)
            });
        }
    };

    handleClick = (link, id) => {
        this.props.navigate(`/news/${id}`);
    };

    render() {
        const { title } = this.props;
        const allUsersLoaded = this.state.visibleUsers.length >= this.allUsers.length;

        return (
            <div className="news-page">
                <h1>{title}</h1>
                <BrowserView>
                    <div className="news">
                        {this.state.visibleUsers.map((el) => (
                            <div
                                className="news-list"
                                key={el.id}
                                onClick={() => this.handleClick(el.link, el.id)}
                            >
                                <div className="image">
                                    <img src={el.images[0]} alt={el.h1} style={{ width: "360px" }} />
                                </div>
                                <h6>{el.h1}</h6>
                                <p className="about">{el.p}</p>
                                <p className="data">{el.data}</p>
                            </div>
                        ))}
                    </div>
                </BrowserView>
                <MobileView>
                    <div className="news">
                        {this.state.visibleUsers.map((el) => (
                            <div
                                className="news-list"
                                key={el.id}
                                onClick={() => this.handleClick(el.link, el.id)}
                            >
                                <div className="image">
                                    <img src={el.images[0]} alt={el.h1} style={{ width: "100%" }} />
                                </div>
                                <h6>{el.h1}</h6>
                                <p className="about">{el.p}</p>
                                <p className="data">{el.data}</p>
                            </div>
                        ))}
                    </div>
                </MobileView>
                <Button onClick={this.loadMoreUsers} disabled={allUsersLoaded} />
            </div>
        );
    }
}

export default withNavigation(Users);
