import React from "react";
import styles from "./Rules.module.css"
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Areas1 = () => {
    return ( <>
    <Header/>
    <main>
        <h3 className={styles["h3-main"]}>Кафедра Бизнес информатики и информационных технологий</h3>
        <h3 className={styles["h3-main"]}>БАКАЛАВРИАТ</h3><br/>
        <p>
        <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Направление подготовки:</span> 38.03.05 «Бизнес информатика»<br/>
        <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Профиль подготовки:</span> «Применение и разработка информационных систем в экономике»<br/>
        <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Форма и сроки обучения:</span> очная, 4 года<br/>
        <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Профильные предметы (вступительные испытания):</span>
        <ul>
            <li>математика (профильный предмет);</li>
            <li>иностранный язык;</li>
            <li>родной язык.</li>
        </ul>
        <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Описание специальности:</span>
        <ul>Специализация «Применение и разработка информационных систем в экономике» дает комплекс знаний в области:
            <li>использование, Проектирование и Разработка информационных систем в производстве и экономике;</li>
            <li>проектирование архитектуры предприятия;</li>
            <li>стратегическое планирование развития ИС и ИКТ управления предприятием;</li>
            <li>организацию процессов жизненного цикла ИС и ИКТ управления предприятием;</li>
            <li>аналитическую поддержку процессов принятия решений для управления предприятием.</li>
        </ul>
        <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Чему будут учить:</span>
        <ul>
            <li>Автоматизированные информационные системы в экономике.</li>
            <li>Основы микро и макроэкономики.</li>
            <li>Программирование.</li>
            <li>Базы данных.</li>
            <li>1-С Программирование.</li>
            <li>Web-программирование.</li>
            <li>Web-дизайн.</li>
            <li>Информационный менеджмент.</li>
            <li>Архитектура предприятия.</li>
            <li>Разработка мобильных приложений.</li>
        </ul><br/>
        <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Эту профессию можно освоить: </span>на экономическом факультете при кафедре <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Бизнес информатики и информационных технологий</span>
        <ul><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Кем можно будет работать (профессии):</span>
            <li>Программист</li>
            <li>Бизнес-аналитик</li>
            <li>1С Консультант</li>
            <li>1С Программист</li>
            <li>Web-Разработчик</li>
        </ul>
        <ul><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Возможные места работы (организации, предприятия):</span>
            <li>программисты и Web-разработчики в организациях любой формы собственности;</li>
            <li>программисты в организациях банковской сферы.</li>
        </ul>
        <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Количество бюджетных мест – 3.</span>
        </p>
    </main>
    <Footer/>
    </> );
}
 
export default Areas1;