import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./style.css";
import decan from "./../../img/decan6.png";
import bosuk from "./../../img/decan1.png";
import dorof from "./../../img/dorof.png";
import miron from "./../../img/miron.png";
import zatica from "./../../img/zatica.png";

const Department5 = () => {
    return ( <>
    <Header />
    <div className="container-departments">
    <h1>Кафедра экономической теории и мировой экономики</h1>
            <div className="department1">
                <p>
                <ul>
                    <li>3300, г. Тирасполь, бульвар Гагарина 2, каб. №</li>
                    <li>533-79483</li>
                    <li>kafedra-etime@mail.ru</li>
                </ul>
                В соответствии с Приказом Министерства Народного образования №238 от <span style={{color: '#43a5ee', fontWeight: 'bold'}}>8 августа 1963 г.</span>, была образована кафедра "Политическая экономия". Первым заведующим кафедрой стал <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Гуров Василий Тимофеевич (1920-1997 г.г.).</span><br/>
                В 1995 году решением Ученого Совета ПГУ им. Т.Г. Шевченко кафедра переименована в стал <span style={{color: '#43a5ee', fontWeight: 'bold'}}>"Экономическая теория"</span>. С 2001 года кафедра входит в состав экономического факультета. В 2012 году кафедра переименована в кафедру <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Экономическая теория и мировая экономика"</span>.
                <br/><br/>
                <ul><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Специальности подготовки дипломированных специалистов:<br/>
                направления подготовки бакалавров:</span>
                    <li>Направление: <em>Экономика (38.03.01)</em></li>
                    <li>Профиль: <em>Внешнеэкономические связи и таможенный консалтинг</em></li>
                </ul>
                </p>               
                <figure>
                <img src={decan} className="righting" width="400px"/>
                    <figcaption style={{lineHeight: '2'}}><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Узун Иван Николаевич,</span> доцент, кандидат экономических наук, и.о. заведующего кафедрой</figcaption>
                </figure>
            </div>
            <br/>
            <p>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Направления подготовки магистрантов:</span>
            <ul>
                <li>Направление: <em>Экономика (38.04.01)</em></li>
                <li>Магистерская программа: <em>Международная экономика</em></li>
            </ul><br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Основные направления научной деятельности:</span><br/>
            <ul style={{listStyleType: 'none'}}>
                <li>
                    Сотрудники кафедры осуществляют активный научный поиск в области совершенствования социально-экономических отношений в ПМР.
                </li>
            </ul>
            </p>
        </div>

    <div className="container-departments">
        <div className="department1-items">
            <div className="department1-item">
                <div className="container-department1-image">
                    <img src={decan} alt="Узун Иван Николаевич" />
                </div>
                <h3>Узун Иван <br/>Николаевич</h3>
                <p>Доцент</p>
            </div>
            <div className="department1-item">
                <div className="container-department1-image">
                    <img src={dorof} alt="Дорофеева Любовь Васильевна" />
                </div>
                <h3>Дорофеева Любовь Васильевна</h3>
                <p>Доцент</p>
            </div>
            <div className="department1-item">
                <div className="container-department1-image">
                    <img src={bosuk} alt="Лабунский Владимир Владимирович" />
                </div>
                <h3>Лабунский Владимир Владимирович</h3>
                <p>Доцент</p>
            </div>
            <div className="department1-item">
                <div className="container-department1-image">
                    <img src={bosuk} alt="Брынза Елена Викторовна" />
                </div>
                <h3>Брынза Елена <br/>Викторовна</h3>
                <p>Ст .преподаватель</p>
            </div>
            <div className="department1-item">
                <div className="container-department1-image">
                    <img src={miron} alt="Мироненко Екатерина Николаевна" />
                </div>
                <h3>Мироненко Екатерина Николаевна</h3>
                <p>Ст .преподаватель</p>
            </div>
            <div className="department1-item">
                <div className="container-department1-image">
                    <img src={bosuk} alt="Параскева Сергей Петрович" />
                </div>
                <h3>Параскева Сергей Петрович</h3>
                <p>Ст .преподаватель</p>
            </div>
            <div className="department1-item">
                <div className="container-department1-image">
                    <img src={bosuk} alt="Чернова Елена Ивановна" />
                </div>
                <h3>Чернова Елена <br/>Ивановна</h3>
                <p>Ст .преподаватель</p>
            </div>
            <div className="department1-item">
                <div className="container-department1-image">
                    <img src={bosuk} alt="Единак Игорь Валентинович" />
                </div>
                <h3>Единак Игорь Валентинович</h3>
                <p>Ст .преподаватель</p>
            </div>
            <div className="department1-item">
                <div className="container-department1-image">
                    <img src={zatica} alt="Затыка Светлана Владимировна" />
                </div>
                <h3>Затыка Светлана Владимировна</h3>
                <p>Специалист</p>
            </div>
            <div className="department1-item">
                <div className="container-department1-image">
                    <img src={bosuk} alt="Поликанова Тамара Федоровна" />
                </div>
                <h3>Поликанова Тамара Федоровна</h3>
                <p>Старший лаборант</p>
            </div>
        </div>
    </div>
        
    <Footer />
    </> );
}
 
export default Department5;