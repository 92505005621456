import sched1 from "./../img/sched1.png";
import sched2 from "./../img/sched2.png";
import sched3 from "./../img/sched3.png";

const schedules = [
    {
        id: 1,
        image: sched1,
        text: "Расписание занятий",
        link: "/schedule1"
    },
    {
        id: 2,
        image: sched2,
        text: "Расписание зачетов и экзаменов",
        link: "/schedule2"
    },
    {
        id: 3,
        image: sched3,
        text: "Графики проведения контрольных и курсовых работ",
        link: "/schedule3"
    },
    {
        id: 4,
        image: sched1,
        text: "Графики учебного процесса",
        link: "/schedule4"
    }
];

export default schedules;