import React from "react";
import styles from "./Rules.module.css"
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Areas9 = () => {
    return ( <><Header/>
        <main>
            <h3 className={styles["h3-main"]}>Кафедра бухгалтерского учета и аудита</h3>
            <h3 className={styles["h3-main"]}>СПЕЦИАЛИТЕТ</h3><br/>
            <p>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Направление подготовки:</span> 38.05.01 «Экономическая безопасность»<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Профиль подготовки:</span> «Экономико-правовое обеспечение экономической безопасности»<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Форма и сроки обучения:</span> очная - 5 лет, заочная – 5 лет и 6 мес.<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Профильные предметы (вступительные испытания):</span>
            <ul>
                <li>математика (профильный предмет);</li>
                <li>иностранный язык;</li>
                <li>родной язык.</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Описание специальности: </span>Данная специальность сочетает в себе экономическое и юридическое образование. Специальность «Экономико-правовое обеспечение экономической безопасности» дает комплекс знаний в области:
            <ul>
                <li>выявления и оценка угроз экономической безопасности государства крупных компаний и корпораций;</li>
                <li>противодействия выявленным угрозам в финансовой и экономической сферах деятельности;</li>
                <li>обеспечения бескризисной работы экономических систем разного уровня.</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Чему будут учить:</span>
            <ul>
                <li>оценивать риски экономических субъектов;</li>
                <li>осуществлять анализ, координацию и регулирование деятельности экономических субъектов;</li>
                <li>отслеживать динамику деятельности экономических субъектов и определять их стратегию развития; </li>
                <li>обеспечивать экономическую безопасность экономических субъектов и защищать их от убытков.</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Эту профессию можно освоить: </span>на экономическом факультете при кафедре <span style={{color: '#43a5ee', fontWeight: 'bold'}}>бухгалтерского учета и аудита.</span><br/>
            <ul><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Кем можно будет работать (профессии):</span>
                <li>аналитик по безопасности в компаниях и госорганах;</li>
                <li>эксперт по экономической безопасности;</li>
                <li>специалист службы экономической безопасности (проведение внутренних расследований);</li>
                <li>риск-менеджер;</li>
                <li>начальник отдела службы экономической и информационной защиты;</li>
                <li>специалист по проверке надежности клиентов;</li>
                <li>ведущий эксперт-аналитик по верификации юридических лиц;</li>
                <li>специалист по предотвращению потерь;</li>
                <li>специалист управления сохранности активов;</li>
                <li>руководитель по защите финансовой информации;</li>
                <li>аналитик по работе с соискателями.</li>
            </ul>
            <ul><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Возможные места работы (организации, предприятия):</span>
                <li>Государственный таможенный комитет ПМР;</li>
                <li>ОБЭП (отдел по борьбе с экономическими преступлениями) и других органах государственной власти, осуществляющих контрольно-надзорные функции;</li>
                <li>правоохранительные органы, МГБ ПМР;</li>
                <li>казначейство;</li>
                <li>службы внутреннего аудита экономических субъектов;</li>
                <li>службы экономической безопасности крупных и средних предприятий;</li>
                <li>банки и страховые организации.</li>
            </ul>
            {/** 
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Количество бюджетных мест – 5.</span>*/}
            </p>
        </main>
        <Footer/>
    </> );
}
 
export default Areas9;