import React from 'react';
import { useParams } from 'react-router-dom';
import NewsSmi from './NewsSmi';

const VideoPage = () => {
    const { id } = useParams();
    const newsSmiData = NewsSmi();
    const newsSmiItem = newsSmiData.find(newssmi => newssmi.id === parseInt(id));

    if (!newsSmiItem || !newsSmiItem.link) {
        return (<div>Видео не найдено</div>);
    }

    return (
        <>
        <div className="video-page">
            <iframe
                width="960"
                height="515"
                src={newsSmiItem.link.replace("watch?v=", "embed/")}
                title={newsSmiItem.h1}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                ></iframe>
            </div>
        </>
    );
}

export default VideoPage;