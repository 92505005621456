import React from "react";
import styles from "./Rules.module.css"
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Areas16 = () => {
    return ( <><Header/>
        <main>
            <h3 className={styles["h3-main"]}>Кафедра экономической теории и мировой экономики</h3>
            <h3 className={styles["h3-main"]}>МАГИСТРАТУРА</h3><br/>
            <p>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Направление подготовки:</span> 38.04.01 «Экономика»<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Профиль подготовки:</span> «Международная экономика»<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Форма и сроки обучения:</span> заочная, 2 года и 6 месяцев<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Профильные предметы (вступительные испытания): </span>комплексный экзамен
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Описание направления подготовки: </span>Профиль «Международная экономика» дает комплекс углубленных знаний в области:
            <ul>
                <li>заключения международных сделок, проведения переговоров, деловых встреч и т. д;</li>
                <li>функционирования механизма мирового хозяйства, состояния и тенденций развития мировых рынков (рабочей силы, капитала, продовольствия, минеральных ресурсов);</li>
                <li>содержания и развития международных экономических отношений;</li>
                <li>реализации внешнеторговой политики государств;</li>
                <li>функционирования мировой валютной системы и форм осуществления валютных операций;</li>
                <li>организации внешнеэкономической деятельности, таможенного регулирования, налогообложения, ценообразования как в стране в целом, так и отдельных ее предприятий.</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Чему будут учить:</span>
            <ul>
                <li>общие гуманитарные и экономические дисциплины: микро- и макроэкономика (продвинутый курс), профессиональный иностранный язык (английский), международно-правовой порядок урегулирования международных экономических споров;</li>
                <li>математические и естественно-научные дисциплины: эконометрика (продвинутый курс), современные информационные технологии в экономической науке и практике;</li>
                <li>профессиональные дисциплины: национальные модели экономического развития, современные проблемы и тенденции в международных экономических отношениях, методология научного исследования.</li>
            </ul><br/>
            <ul><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Кем можно будет работать (профессии):</span>
                <li>специалист по внешнеэкономической деятельности;</li>
                <li>специалист таможенного регулирования;</li>
                <li>экономист-международник;</li>
                <li>менеджер по международной логистике;</li>
                <li>региональный представитель международной компании.</li>
            </ul>
            <ul><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Возможные места работы (организации, предприятия):</span>
                <li>внешнеэкономические отделы ведущих предприятий;</li>
                <li>валютные отделы финансово-кредитных учреждений;</li>
                <li>административные органы; таможенные органы;</li>
                <li>представительства международных компаний;</li>
                <li>международные аудиторские компании.</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Количество бюджетных мест – 5.</span>
            </p>
        </main>
        <Footer/></> );
}
 
export default Areas16;