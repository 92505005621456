import React from "react";
import styles from "./Rules.module.css"
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Areas7 = () => {
    return ( <><Header/>
        <main>
            <h3 className={styles["h3-main"]}>Кафедра экономики и менеджмента</h3>
            <h3 className={styles["h3-main"]}>БАКАЛАВРИАТ</h3><br/>
            <p>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Направление подготовки:</span> 38.03.01 «Экономика»<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Профиль подготовки:</span> «Экономика и менеджмент»<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Форма и сроки обучения:</span> очная, 4 года<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Профильные предметы (вступительные испытания):</span>
            <ul>
                <li>математика (профильный предмет);</li>
                <li>иностранный язык;</li>
                <li>родной язык.</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Описание направления подготовки: </span>формирует владение знаниями в области экономики и рациональной организации деятельности предприятия, правовых основ производственно-хозяйственной деятельности, финансово-экономического анализа и мониторинга деятельности организации, экономической оценки инвестиций, эффективного управления современной организации.<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Чему будут учить:</span>
            <ul>
                <li>организации процессов управления организациями различных организационно-правовых форм, государственного и муниципального управления;</li>
                <li>обретению навыков в расчетно-экономической, аналитической, научно-исследовательская, организационно-управленческой, учетной и расчетно-финансовой видах деятельности.</li>
            </ul><br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Эту профессию можно освоить: </span>на экономическом факультете при кафедре <span style={{color: '#43a5ee', fontWeight: 'bold'}}> экономики и менеджмента.</span><br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Кем можно будет работать (профессии): </span>
            выпускники данного профиля способны работать в качестве специалистов в экономических, финансовых, маркетинговых, производственно-экономических и аналитических службах организаций различных отраслей, сфер и форм собственности, в финансовых, кредитных и страховых учреждениях, в органах государственной и муниципальной власти, в научно-исследовательских организациях и в учреждениях системы среднего профессионального образования, среднего общего образования.
            <ul><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Возможные места работы (организации, предприятия):</span>
                <li>в органах государственного и муниципального управления;</li>
                <li>в структурах, в которых выпускники являются предпринимателями, создающими и развивающими собственное дело;</li>
                <li>на предприятиях любых отраслей промышленного производства любых форм собственности.</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Количество бюджетных мест – 2.</span>
            </p>
        </main>
        <Footer/></> );
}
 
export default Areas7;