import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const deadlines = () => {
    return ( <>
    <Header/>
    <main>
    <h2 style={{textAlign:'center', lineHeight:'1.5', color:'#43a5ee'}}>Экономический факультет Приднестровского государственного университета объявляет набор абитуриентов в 2024 году!</h2><br/>
    <p style={{textAlign:'center', lineHeight:'1.5'}}>Прием документов на обучение по программам высшего и дополнительного профессионального образования проводится</p><br/>
    <p style={{textAlign:'center', lineHeight:'1.5', color:'#43a5ee', fontWeight:'bold'}}>с 17 июня по 20 июля 2024 года.</p><br/>
    <p>Подать документы на обучение можно по адресу:<br/>
    Приднестровский государственный университет им. Т.Г. Шевченко корпус №2 кабинет №121<br/>
    г. Тирасполь, ул 25 Октября 128</p><br/>
    <p style={{lineHeight:'1.5', color:'#43a5ee', fontWeight:'bold'}}>Прием документов осуществляется с понедельника по пятницу с 9.00 до 17.00, обед с 12.00 до 13.00 и в субботу с 9.00 до 14.00 без обеда.</p><br/>
    <p style={{lineHeight:'1.5'}}>Вступительные испытания пройдут:<br/>
    <span style={{color:'#43a5ee', fontWeight:'bold'}}>с 25 по 27 июля 2024 года</span></p><br/>
    <p style={{lineHeight:'1.5'}}>Заключение трехсторонних договоров на оказание образовательных услуг за счет республиканского бюджета с абитуриентами состоится:<br/>
    <span style={{color:'#43a5ee', fontWeight:'bold'}}>с 3 по 8 августа 2024 года.</span></p><br/>
    <p style={{lineHeight:'1.5', color:'#43a5ee', fontWeight:'bold'}}>Абитуриенты, поступающие на договорную основу, оплачивают обучение до 08 августа 2024 года.</p><br/>
    <p>Будем рады видеть Вас в числе наших студентов!</p>
    </main>
    
    <Footer/>
    </> );
}
 
export default deadlines;