import dedication from "./../img/dedication.jpg";
import gramota2 from "./../img/gramota2.jpg";
import gramota3 from "./../img/gramota3.jpg";
import gramota4 from "./../img/gramota4.jpg";
import gramota5 from "./../img/gramota5.jpg";
import gramota6 from "./../img/gramota6.jpg";
import gramota from "./../img/gramota.jpg";
import student from "../img/IMG_3111.jpg";
import student1 from "./../img/student1.jpg";
import student2 from "./../img/student2.jpg";
import student4 from "../img/student4.jpg";
import student5 from "./../img/student5.jpg";
import student7 from "./../img/student7.jpg";
import student8 from "./../img/student8.jpg";
import student9 from "./../img/student9.jpg";
import student10 from "./../img/student10.jpg";
import student11 from "./../img/student11.jpg";
import student12 from "./../img/student12.jpg";
import student13 from "./../img/student13.jpg";
import photo from "../img/photo.jpg";
import best1 from "./../img/best1.jpg";
import best2 from "./../img/best2.jpg";
import best3 from "./../img/best3.jpg";
import best4 from "./../img/best4.jpg";
import best5 from "./../img/best5.jpg";
import best6 from "./../img/best6.jpg";
import best7 from "./../img/best7.jpg";
import best8 from "./../img/best8.jpg";
import best9 from "./../img/best9.jpg";
import best10 from "./../img/best10.jpg";
import best11 from "./../img/best11.jpg";
import best12 from "./../img/best12.jpg";
import best13 from "./../img/best13.jpg";
import photo_2 from "../img/photo_2.jpg";
import photo_3 from "../img/photo_3.jpg";
import File from "../img/File.png";
import photo_4 from "../img/photo_4.jpg";
import sovet from "../img/sovet.jpg";
import victory from "../img/victory.jpg";
import victory1 from "../img/victory1.jpg";
import victory2 from "../img/victory2.jpg";
import victory3 from "../img/victory3.jpg";
import victory4 from "../img/victory4.jpg";
import victory5 from "../img/victory5.jpg";
import victory6 from "../img/victory6.jpg";
import victory7 from "../img/victory7.jpg";
import victory8 from "../img/victory8.jpg";
import victory9 from "../img/victory9.jpg";
import photo_6 from "../img/photo_6.jpg";
import photo_5 from "../img/photo_5.jpg";
import rally1 from "./../img/rally1.jpg";
import rally2 from "./../img/rally2.jpg";
import rally3 from "./../img/rally3.jpg";
import rally4 from "./../img/rally4.jpg";
import rally5 from "./../img/rally5.jpg";
import rally6 from "./../img/rally6.jpg";
import image from "../img/image.png";
import round1 from "./../img/round1.png";
import round2 from "./../img/round2.png";
import round3 from "./../img/round3.png";
import round4 from "./../img/round4.png";
import round5 from "./../img/round5.png";
import round6 from "./../img/round6.png";
import round7 from "./../img/round7.png";
import round8 from "./../img/round8.png";
import round9 from "./../img/round9.png";
import round10 from "./../img/round10.png";
import round11 from "./../img/round11.png";
import round12 from "./../img/round12.png";
import round13 from "./../img/round13.png";
import round14 from "./../img/round14.png";
import round15 from "./../img/round15.png";
import image_1 from "../img/image_1.png"
import glebov1 from "./../img/glebov1.png";
import glebov2 from "./../img/glebov2.png";
import glebov3 from "./../img/glebov3.png";
import glebov4 from "./../img/glebov4.png";
import glebov5 from "./../img/glebov5.png";
import glebov6 from "./../img/glebov6.png";
import glebov7 from "./../img/glebov7.png";
import glebov8 from "./../img/glebov8.png";
import glebov9 from "./../img/glebov9.png";
import glebov10 from "./../img/glebov10.png";
import glebov11 from "./../img/glebov11.png";
import glebov12 from "./../img/glebov12.png";
import glebov13 from "./../img/glebov13.png";
import glebov14 from "./../img/glebov14.png";
import glebov15 from "./../img/glebov15.png";
import spring from "./../img/spring.jpg";
import relay from "./../img/relay.png";
import relay1 from "./../img/relay1.png";
import relay2 from "./../img/relay2.png";
import rallyEc from "./../img/rallyEc.png";
import rallyEc1 from "./../img/rallyEc1.png";
import rallyEc2 from "./../img/rallyEc2.png";
import rallyEc3 from "./../img/rallyEc3.png";
import rallyEc4 from "./../img/rallyEc4.png";
import rallyEc5 from "./../img/rallyEc5.png";
import rallyEc6 from "./../img/rallyEc6.png";
import rallyEc7 from "./../img/rallyEc7.png";
import volonteer from "./../img/volonteer.png";
import volonteer1 from "./../img/volonteer1.png";
import volonteer2 from "./../img/volonteer2.png";
import volonteer3 from "./../img/volonteer3.png";
import volonteer4 from "./../img/volonteer4.png";
import applicant from "./../img/applicant.png";
import homeland from "./../img/homeland.jpg";
import homeland1 from "./../img/homeland1.jpg";
import homeland2 from "./../img/homeland2.jpg";
import posvash from "./../img/posvash.jpg";
import kur1 from "./../img/1.jpg";
import kur2 from "./../img/2.jpg";
import kur3 from "./../img/3.jpg";
import kur4 from "./../img/4.jpg";
import kur5 from "./../img/5.jpg";
import kur6 from "./../img/6.jpg";
import kur7 from "./../img/7.jpg";
import kur8 from "./../img/8.jpg";
import start from "./../img/start.jpg";
import grant1 from "./../img/grant1.jpg";
import grant2 from "./../img/grant2.jpg";
import grant3 from "./../img/grant3.jpg";
import grant4 from "./../img/grant4.jpg";
import grant5 from "./../img/grant5.jpg";
import grant6 from "./../img/grant6.jpg";
import grant7 from "./../img/grant7.jpg";
import nabor11 from "./../img/nabor11.png";
import nabor11_1 from "./../img/nabor11(1).png";
import nabor11_2 from "./../img/nabor11(2).png";
import nabor11_3 from "./../img/nabor11(3).png";
import stud1 from "./../img/stud1.jpg";
import stud2 from "./../img/stud2.jpg";
import stud3 from "./../img/stud3.jpg";
import stud4 from "./../img/stud4.jpg";
import stud5 from "./../img/stud5.jpg";
import stud6 from "./../img/stud6.jpg";
import stud7 from "./../img/stud7.jpg";
import stud8 from "./../img/stud8.jpg";
import openday from "./../img/openday.jpg";
import sert1 from "./../img/sert1.jpg";
import sert2 from "./../img/sert2.jpg";
import sert3 from "./../img/sert3.jpg";
import sert4 from "./../img/sert4.jpg";
import opendoor1 from "./../img/opendoor1.jpg";
import opendoor2 from "./../img/opendoor2.jpg";
import opendoor3 from "./../img/opendoor3.jpg";
import opendoor4 from "./../img/opendoor4.jpg";
import repida from "./../img/repida.jpg";
import kviz1 from "./../img/kviz1.jpg";
import kviz2 from "./../img/kviz2.jpg";
import kviz4 from "./../img/kviz4.jpg";
import volonter1 from "./../img/volonter1.jpg";
import volonter2 from "./../img/volonter2.jpg";
import grant8 from "./../img/grant8.jpg";
import grant9 from "./../img/grant9.jpg";
import itogi from "./../img/itogi.jpg";
import decada1 from "./../img/decada1.jpg";
import decada2 from "./../img/decada2.jpg";
import priznanie from "./../img/priznanie.jpg";
import voleyball1 from "./../img/voleyball3.jpg";
import voleyball2 from "./../img/voleyball1.jpg";
import voleyball3 from "./../img/voleyball2.jpg";
import voleyball4 from "./../img/voleyball4.jpg";
import voleyball5 from "./../img/voleyball5.jpg";
import voleyball6 from "./../img/voleyball6.jpg";
import voleyball7 from "./../img/voleyball7.jpg";
import zel1 from "./../img/zel1.jpg";
import zel2 from "./../img/zel2.jpg";
import zel3 from "./../img/zel3.jpg";
import prof1 from "./../img/prof2.jpg";
import prof2 from "./../img/prof1.jpg";
import prof3 from "./../img/prof3.jpg";
import sumerki1 from "./../img/skam1.jpg";
import sumerki2 from "./../img/skam2.jpg";
import sumerki3 from "./../img/skam3.jpg";
import minfin1 from "./../img/minfin1.jpg";
import minfin2 from "./../img/minfin2.jpg";
import minfin3 from "./../img/minfin3.jpg";
import minfin4 from "./../img/minfin4.jpg";
import minfin5 from "./../img/minfin5.jpg";
import minfin6 from "./../img/minfin6.jpg";
import minfin7 from "./../img/minfin7.jpg";
import igra1 from "./../img/igra1.jpg";
import igra2 from "./../img/igra2.jpg";
import igra3 from "./../img/igra3.jpg";
import igra4 from "./../img/igra4.jpg";
import igra5 from "./../img/igra5.jpg";
import misseconom from "./../img/misseconom.jpg";
import misseconom1 from "./../img/misseconom1.jpg";
import miss from "./../img/photo_2024-12-20_20-29-49.jpg";
import miss1 from "./../img/photo_2024-12-20_20-29-50 (2).jpg";
import miss2 from "./../img/photo_2024-12-20_20-29-50 (3).jpg";
import miss3 from "./../img/photo_2024-12-20_20-29-50.jpg";
import miss4 from "./../img/photo_2024-12-20_20-29-51 (2).jpg";
import miss5 from "./../img/photo_2024-12-20_20-29-51 (3).jpg";
import miss6 from "./../img/photo_2024-12-20_20-29-51 (4).jpg";
import miss7 from "./../img/photo_2024-12-20_20-29-51.jpg";
import miss8 from "./../img/photo_2024-12-20_20-29-52 (2).jpg";
import miss9 from "./../img/photo_2024-12-20_20-29-52.jpg";
import hart from "./../img/photo_2024-12-23_13-46-23.jpg";
import sud from "./../img/photo_2024-12-23_21-57-12.jpg";
import dreconom from "./../img/photo_2024-12-28_21-13-44.jpg";
import dreconom1 from "./../img/photo_2024-12-28_21-13-45.jpg";
import dreconom2 from "./../img/photo_2024-12-28_21-13-46.jpg";
import studelder from "./../img/photo_2025-01-13_15-44-51.jpg";
import vr from "./../img/photo_2025-01-11_13-34-13.jpg";
import vr1 from "./../img/photo_2025-01-11_13-34-14.jpg";
import vr2 from "./../img/photo_2025-01-11_13-34-14 (2).jpg";
import rektor from "./../img/photo_rektor.jpg";
import online from "./../img/photo_2025-02-07_13-58-01.jpg";
import telekviz1 from "./../img/photo_2025-01-25_17-20-32.jpg";
import telekviz2 from "./../img/photo_2025-01-25_17-20-35.jpg";
import newtem1 from "./../img/photo_2025-02-12_12-35-44.jpg";
import newtem2 from "./../img/photo_2025-02-12_12-35-44 (2).jpg";
import newtem3 from "./../img/photo_2025-02-12_12-35-45.jpg";
import newtem4 from "./../img/photo_2025-02-12_12-35-45 (2).jpg";
import newtem5 from "./../img/photo_2025-02-12_12-35-46.jpg";
import newtem6 from "./../img/photo_2025-02-12_12-35-46 (2).jpg";
import newtem7 from "./../img/photo_2025-02-12_12-35-47.jpg";
import tem from "./../img/photo_2025-02-14_16-04-35.jpg";
import podgot from "./../img/01_file_01.png";
import starostat from "./../img/photo_2025-02-20_16-01-41.jpg";
import sovet08 from "./../img/photo_2025-02-08_13-10-11.jpg";
import fev23 from "./../img/photo_2025-02-23_09-47-11.jpg";
import vip1 from "./../img/photo_2025-02-26_16-52-51.jpg";
import vip2 from "./../img/photo_2025-02-26_16-52-51 (2).jpg";
import vip3 from "./../img/photo_2025-02-26_16-52-52.jpg";
import vip4 from "./../img/photo_2025-02-26_16-52-52 (2).jpg";
import masl from "./../img/photo_2025-02-28_00-44-45.jpg";
import s8marta from "./../img/photo_2025-03-08_08-58-05.jpg";
import kino1 from "./../img/photo_2025-03-12_16-23-32.jpg";
import kino2 from "./../img/photo_2025-03-12_16-23-33.jpg";
import esse from "./../img/photo_2025-03-11_15-14-56.jpg";
import dip1 from "./../img/photo_2025-03-15_15-00-28.jpg";
import dip2 from "./../img/photo_2025-03-15_15-00-29.jpg";
import dip3 from "./../img/photo_2025-03-15_15-00-29 (2).jpg";
import dip4 from "./../img/photo_2025-03-15_15-00-30.jpg";
import dip5 from "./../img/photo_2025-03-15_15-00-31.jpg";
import fin from "./../img/photo_2025-03-13_11-53-06.jpg";
import buxuch from "./../img/photo_2025-03-19_09-36-14.jpg";
import opendoor5 from "./../img/photo_2025-03-23_22-31-03.jpg";
//import opendoor6 from "./../img/photo_2025-03-23_22-31-04.jpg";
import intensive from "./../img/photo_2025-03-20_00-01-52.jpg";
import konkurs from "./../img/konkurs.png";
import tub from "./../img/photo_2025-03-24_15-00-16.jpg";
import mosk1 from "./../img/photo_2025-03-28_17-32-32.jpg";
import mosk2 from "./../img/photo_2025-03-28_17-32-33.jpg";
import tub1 from "./../img/photo_2025-03-28_17-35-48.jpg";
import tub2 from "./../img/photo_2025-03-28_17-35-49.jpg";
import tub3 from "./../img/photo_2025-03-28_17-35-50.jpg";
import tub4 from "./../img/photo_2025-03-28_17-35-50 (2).jpg";
import atm1 from "./../img/photo_2025-03-28_18-55-48.jpg";
import atm2 from "./../img/photo_2025-03-28_18-43-24.jpg";
import sov1 from "./../img/photo_2025-03-29_13-23-41.jpg";
import sov2 from "./../img/photo_2025-03-29_13-23-41 (2).jpg";
import starostat1 from "./../img/photo_2025-03-19_21-01-38.jpg";
import starostat2 from "./../img/photo_2025-03-19_21-01-39.jpg";
import volonteer5 from "./../img/photo_2025-04-02_19-04-52.jpg";
import volonteer6 from "./../img/photo_2025-04-02_19-04-53.jpg";
import volonteer7 from "./../img/photo_2025-04-02_19-04-54.jpg";

const newsData = [
    {
        id: 95,
        images: [volonteer5, volonteer6, volonteer7],
        h1: "Республиканская патриотическая акция «80 добрых дел»",
        p: "",
        data: "03.04.2025",
        type: "СТУДЕНТАМ ПОМНЮ",
        link: "",
        about: `<p>2 апреля 2025 года студенты нашего факультета приняли активное участие в волонтерской акции по уборке захоронений ветеранов Великой Отечественной войны. Это мероприятие стало важным шагом в сохранении памяти о героях, отдавших свои жизни за свободу и мир.</p>
        <p>Наша акция приурочена к Республиканской патриотической акции "80 добрых дел", организованной Министерством просвещения ПМР.</p>
        <p>В ходе акции студенты с особым вниманием и уважением привели в порядок могилы ветеранов, очистив их от травы и мусора.</p>`
    },
    {
        id: 94,
        images: [sovet],
        h1: "Информация о решениях Ученого совета экономического факультета – 27 марта 2025г.",
        p: "",
        data: "02.04.2025",
        type: "УЧЕНЫЙ СОВЕТ",
        link: "",
        about: `<p style="font-weight:bold">ПОВЕСТКА ЗАСЕДАНИЯ УЧЕНОГО СОВЕТА ЭКОНОМИЧЕСКОГО ФАКУЛЬТЕТА</p>
        <p>Протокол от 27.03.25г. №03<br/>
        Дата и время проведения: 27 марта 2025г., 14-45 час.<br/>
        Место проведения: ауд. 112, корпус 11.</p><br/>
        <p>
        <span style="font-weight:bold">01. </span>Об итогах самообследования готовности кафедр экономического факультета к работе в весеннем семестре 2024-2025 учебного года.<br/>
        <span style="font-weight:bold">02. </span>Об итогах зимней экзаменационной сессии на дневной форме обучения экономического факультета.</p>
        <span style="font-weight:bold">03. </span>Об итогах зимней экзаменационной сессии на заочной и очно-заочной форме обучения экономического факультета.</p>
        <p style="font-weight:bold; text-align:right">Информация декана И.Н. Узуна</p>
        <p>
        <span style="font-weight:bold">04. </span>О выделении дополнительного номера журнала «Вестник Приднестровского университета» для издания серии «Экономика и управление». <br/>
        <p style="font-weight:bold; text-align:right">Информация заместителя декана по научной работе В.Н. Босюка</p>
        <p>
        <span style="font-weight:bold">05. </span>О предоставлении академического отпуска.</p>
        </p>
        <p style="font-weight:bold; text-align:right">Информация декана И.Н. Узуна</p>
        <br/>`
    },
    {
        id: 93,
        images: [sov1, sov2],
        h1: "СОВЕТ КУРАТОРОВ АКАДЕМИЧЕСКИХ ГРУПП ЭКОНОМИЧЕСКОГО ФАКУЛЬТЕТА",
        p: "29 МАРТА 2025 ГОДА (ВРЕМЯ 11-10, ауд 112)",
        data: "29.03.2025",
        type: "ВОСПИТАТЕЛЬНАЯ РАБОТА",
        link: "",
        about: `<p>ПОВЕСТКА</p>
        <p>1. Об итогах процесса погашения академических задолженностей зимней экзаменационной сессии 2024-2025 уч. года.</p><br/>
        <p>2. О контроле за ходом текущей аттестации студентов курируемой группы во 2 семестре 2024-2025 уч. года.</p><br/>
        <p>3. О начале подготовки к V Республиканскому молодежному форуму ЭФ «Наш дом-эконом 2025» (25.04.25  лагерь Содружество)</p><br/>
        <p>4. О ежегодной акции по уходу за захоронениями ветеранов ВОВ и об участии студентов в экологических акциях в Содружестве (апрель) </p><br/>
        <p>5. Информация Д.В.Формусатий<br/>
        - посещение общежитий;<br/>
        - посещение музеев, совместные экскурсии и т.п.</p><br/>`
    },
    {
        id: 92,
        images: [starostat1, starostat2],
        h1: "Собрание старост академических групп",
        p: "Собрание старост академических групп: ключевые моменты",
        data: "29.03.2025",
        type: "СТАРОСТАТ",
        link: "",
        about: `<p style="font-weight:bold">Итоги старостата:</p>
        <p>Сегодня прошло заседание старостата, на котором обсудили ключевые вопросы учебной и внеучебной деятельности.</p>
        <p style="font-weight:bold">Академические задолженности</p>
        <p>Подведены итоги погашения задолженностей зимней экзаменационной сессии 2024–2025 учебного года.</p>
        <p style="font-weight:bold">Текущая аттестация</p>
        <p>Во втором семестре продолжается текущая аттестация: <br/>
        - Старосты должны предоставить журналы на проверку заместителю декана Павлин Е.В. 3 апреля.</p>
        <p style="font-weight:bold">Сроки аттестации:</p>
        <p>
         - 1, 2, 3 курсы, а также 401 и 406 группы – <span style="font-weight:bold">с 19 марта по 2 апреля.</span><br/>  
         - 4 курс завершил аттестацию <span style="font-weight:bold">15 марта</span>, журналы на проверку – <span style="font-weight:bold">20 марта.</span>  
        </p>
        <p>
        <span style="font-weight:bold">Факультетский слет «Наш дом – ЭКОНОМ» <br/> 
        - Дата:</span> 25 апреля  <br/>
        - <span style="font-weight:bold">Место:</span> лагерь «Содружество» (Кицканский лес) <br/> 
        Началась подготовка, к участию приглашаются все желающие.  <br/>

        <span style="font-weight:bold">Волонтерские акции</span>  <br/>
        - Пройдет ежегодная акция по уходу за захоронениями ветеранов Великой Отечественной войны.  <br/>
        - В апреле студенты примут участие в экологических мероприятиях по благоустройству территории лагеря «Содружество».  <br/>

        <span style="font-weight:bold">Общеуниверситетские мероприятия  <br/>
        - «Наш дом – Приднестровье»</span> – гала-концерт в новом формате. Идет набор участников.  <br/>
        - Сбор участников: <span style="font-weight:bold">21 марта, 14:30 в КПЦ.</span>  <br/>
        - <span style="font-weight:bold">Бессмертный полк</span> – в этом году шествие состоится, необходимо обновить портреты. <br/> 
        - <span style="font-weight:bold">Конкурс чтецов</span> – подробности уточняйте у организаторов.  <br/>
        - <span style="font-weight:bold">Кубок Ректора по КВН, «Битва Галактик»</span> – подготовка команд в разгаре.  <br/>

        <span style="font-weight:bold">Дополнительная информация</span>  <br/>
        Формусатий Д.В. рассказал о стипендиях и льготных категориях студентов.</p><br/>`
    },
    {
        id: 91,
        images: [atm1, atm2],
        h1: "27 марта будущие студенты погрузились в атмосферу экономического факультета ПГУ им. Т.Г. Шевченко! ",
        p: "",
        data: "29.03.2025",
        type: "АБИТУРИЕНТ",
        link: "",
        about: `<p>Мы не просто рассказали, а показали, что значит быть частью нашего дружного и амбициозного сообщества.</p><br/>
        
        <p>🔹<span style = "font-weight: bold; "> Знакомство началось с лекции по макроэкономике,</span> где декан факультета раскрыл секреты больших экономических процессов.</p>
        <p>🔹<span style = "font-weight: bold; "> Студенческий актив поделился лайфхаками</span> – как учёба, мероприятия и саморазвитие могут идти рука об руку.</p>
        <p>🔹<span style = "font-weight: bold; "> IQuiz по финансовой грамотности</span> – азарт, смекалка и неожиданные открытия!</p>
        <p>🔹<span style = "font-weight: bold; "> Открытый диалог с руководством</span> – без скучных речей, только честные ответы на самые важные вопросы.</p>
        <br/>
        <p>Спасибо всем, кто был с нами в этот день! Эконом – это не просто про цифры, это про будущее! 🚀</p>
        <br/>
        <p>Помощник медиа комитета - Руссу Ксения.</p>
        <br/>`
    },
    {
        id: 90,
        images: [tub1, tub2, tub3, tub4],
        h1: "24 марта отмечался Всемирный день борьбы с туберкулёзом. В рамках этого дня наш факультет организовал акцию по добровольной сдаче крови.",
        p: "",
        data: "29.03.2025",
        type: "СТУДЕНТАМ ВОЛОНТЕР",
        link: "",
        about: `<p>Сегодня студенты нашего факультета посетили Республиканскую клиническую больницу (РКБ) и сдали кровь. Эта акция не только помогла пополнить запасы крови в больнице, но и стала важным вкладом в дело борьбы с туберкулёзом, так как здоровые доноры способствуют обеспечению качественной медицинской помощи пациентам.</p>`
    },
    {
        id: 89,
        images: [mosk1, mosk2],
        h1: "Историческая игра «Отстоим Москву» прошла в ПГУ с участием студентов экономического факультета",
        p: "",
        data: "29.03.2025",
        type: "СТУДЕНТАМ ПОМНЮ",
        link: "",
        about: `<p>27 марта в Культурно-просветительском центре ПГУ прошла историческая интеллектуальная игра «Отстоим Москву», в которой приняли участие студенты экономического факультета.</p>
        <p>Игра включала шесть туров, включая музыкальные конкурсы и вопросы о Великой Отечественной войне, где участники продемонстрировали свои знания и активно участвовали в угадывании военных песен.</p>
        <p>Организатором мероприятия стал Контактный центр университета в сотрудничестве с Россотрудничеством.</p>`
    },
    {
        id: 88,
        images: [tub],
        h1: "Всемирный день борьбы с туберкулёзом",
        p: "24 марта во всём мире отмечается День борьбы с туберкулёзом – дата, напоминающая о серьёзности этого заболевания и необходимости его своевременного выявления и лечения.",
        data: "24.03.2025",
        type: "СТУДЕНТАМ ВОЛОНТЕР",
        link: "",
        about: `<p>Туберкулёз – это инфекционное заболевание, которое передаётся воздушно-капельным путём и может поражать лёгкие и другие органы. Однако современная медицина позволяет успешно его диагностировать и лечить, если вовремя обратиться к врачу.</p>
        <p>❗️Как защитить себя и близких?</p>
        <p>Регулярно проходить флюорографию (1 раз в год).</p>
        <p>Вести здоровый образ жизни и укреплять иммунитет.</p>
        <p>Соблюдать правила гигиены и избегать контакта с инфицированными.</p>
        <p>При появлении тревожных симптомов (длительный кашель, слабость, потеря веса) – сразу обращаться к врачу.</p>
        <br/>
        <p style = "font-weight: bold; text-decoration: underline; ">Также в честь этого дня на этой неделе мы проводим акцию по сдаче донорство крови!</p>
        <br/>
        <p>Борьба с туберкулёзом – задача каждого! Будьте внимательны к своему здоровью и заботьтесь о тех, кто рядом.</p>`
    },
    {
        id: 87,
        images: [tem],
        h1: "В школе №9 состоялась II Республиканская интеллектуальная игра «Будь в теме!», организованная Экономическим факультетом.",
        p: "",
        data: "24.03.2025",
        type: "БУДЬ В ТЕМЕ!",
        link: "",
        about: `<p>В ходе соревнования участники продемонстрировали эрудицию, логику и умение работать в команде, решая экономические задачи, участвуя в квизе и игре «Экономический Элиас».</p><br/>
        <p style = "font-weight: bold; ">🏆 Итоговые результаты:</p>
        <p>🥇<span style = "font-weight: bold; "> 1 место – команда «Эльдорадо»</span> – 70 баллов</p>
        <p>🥈<span style = "font-weight: bold; "> 2 место – команда «План Б»</span> – 65 баллов</p>
        <p>🥉<span style = "font-weight: bold; "> 3 место – команда «Великолепная семерка»</span> – 63 балла (включая 8 дополнительных баллов за 1 доп. этап и 11 баллов за 2 доп. этап)</p>
        <p>🔹<span style = "font-weight: bold; "> 4 место – команда «Кибер лапки»</span> – 62 балла (включая 8 дополнительных баллов за 1 доп. этап и 8 баллов за 2 доп. этап)</p>
        <br/>
        <p>Конкуренция была очень высокой! Особенно напряженной оказалась борьба за <span style = "font-weight: bold; ">третье место</span>, где команды «Великолепная семерка» и «Кибер лапки» показали равные результаты. В дополнительном этапе ребята проявили себя достойно, и лишь несколько баллов решили исход игры.</p>
        <br/>
        <p>Хотим выразить <span style = "font-weight: bold; ">огромную благодарность председателю научного комитета</span> Юлии Жильцовой за организацию мероприятия! А также, ведущим, фотографам и судейской комиссии за профессионализм и четкость в проведении игры. <span style = "font-weight: bold; ">Особая признательность нашим организаторам и партнерам</span>, которые поддержали инициативу и внесли вклад в развитие интеллектуального потенциала школьников!</p>
        <br/>
        <p>🔹 Приднестровский республиканский банк</p>
        <p>🔹 Министерство иностранных дел ПМР</p>
        <p>🔹 Министерство просвещения ПМР</p>
        <p>🔹 Агропромбанк</p>
        <p>🔹 IQ Games Club</p>
        <p>🔹 Первый Приднестровский</p>
        <p>🔹 ТСШ №9</p>
        <br/>
        <p>Спасибо всем участникам за увлекательную игру, яркие эмоции и честную борьбу! Желаем дальнейших успехов и новых побед!</p><br/>
        <p>Помощник медиа комитета - Руссу Ксения.</p>`
    },
    {
        id: 86,
        images: [intensive],
        h1: "Образовательный интенсив «Разработка бизнес-проектов»",
        p: "Хотите научиться создавать рабочие бизнес-стратегии, оценивать риски и продвигать проекты? Тогда этот интенсив для вас! ",
        data: "24.03.2025",
        type: "ПРОФРАЗВИТИЕ",
        link: "",
        about: `<p style="font-weight:bold; ">25 марта | 14:45 | аудитория 200, корпус 11</p>
        <p><span style="font-weight:bold; ">Александр Войков </span>– Директор группы компаний Екатерины Гушан и сооснователь консалтингового агентства «Точка зрения». Поможем вашим самым актуальным и перспективным разработкам не остаться без внимания из-за трудностей с их презентацией и продажей. <br/>
        <a href="https://forms.gle/pvojirzvz3Wrry3r9" target = "_blank" style = "text-decoration: none; color: #43a5ee; ">Ссылка на регистрацию</a>.</p>`
    },
    {
        id: 85,
        images: [opendoor5],
        h1: "Дорогие будущие студенты!",
        p: "Приглашаем вас на день открытых дверей нашего факультета!",
        data: "24.03.2025",
        type: "АБИТУРИЕНТ",
        link: "",
        about: `<p style = "text-align: center; ">Это уникальная возможность познакомиться с нашим факультетом, задать интересующие вопросы и узнать о студенческой жизни из первых уст. <a href = "https://forms.gle/2VU1Hp5b8yYgC9g4A" target = "_blank" style = "text-decoration: none; color: #43a5ee; ">Ссылка на регистрацию</a>.</p>
        <div style = "align-items: center; display: flex; flex-direction: column; background-color: #343434; max-width: 580px; margin: 0 auto; padding: 20px; ">
        <br/>
            <p style="font-weight:bold; font-size: 28px; color: #ffb424; text-transform: uppercase; text-align: center; line-height: 36px ">Регистрация участников</p>
            <p style ="color: #fff; text-transform: uppercase; text-align: center; line-height: 36px; ">Корпус №4, аудитория 208<br/>
            Время: 9.30 - 9.40</p><br/>
            <p style="font-weight:bold; font-size: 28px; color: #ffb424; text-transform: uppercase; text-align: center; line-height: 36px">Лекция по макроэкономике с деканом ЭФ</p>
            <p style =" color: #fff; text-transform: uppercase; text-align: center; line-height: 36px; ">Корпус №4, аудитория 306<br/>
            Время: 9.40 - 11.10</p><br/>
            <p style="font-weight:bold; font-size: 28px; color: #ffb424; text-transform: uppercase; text-align: center; line-height: 36px">Встреча со студенческим активом факультета</p>
            <p style =" color: #fff; text-transform: uppercase; text-align: center; line-height: 36px; ">Корпус №11, аудитория 200<br/>
            Время: 11.25 - 11.55</p><br/>
            <p style="font-weight:bold; font-size: 28px; color: #ffb424; text-transform: uppercase; text-align: center; line-height: 36px">IQUIZ по финансовой грамотности</p>
            <p style ="color: #fff; text-transform: uppercase; text-align: center; line-height: 36px; ">Корпус №11, аудитория 200<br/>
            Время: 12.00 - 12.55</p><br/>
            <p style="font-weight:bold; font-size: 28px; color: #ffb424; text-transform: uppercase; text-align: center; line-height: 36px">«Вопрос-ответ» с руководством факультета</p>
            <p style ="color: #fff; text-transform: uppercase; text-align: center; line-height: 36px; ">Корпус №11, аудитория 200<br/>
            Время: 13.05 - 14.00</p><br/>
            <p style ="color: #fff; text-transform: uppercase; text-align: center; line-height: 36px; ">Контакты: Формусатий Диана Викторовна<br/>
            Зам.декана ЭФ по воспитательной работе<br/>
            077809857</p><br/>
        </div>`
    },
    {
        id: 84,
        images: [buxuch],
        h1: "Уважаемые студенты и преподаватели!",
        p: "Приглашаем вас на открытое практическое занятие, которое проведёт Пасичник Наталья Владимировна, старший преподаватель кафедры «Бухгалтерский учет и аудит».",
        data: "19.03.2025",
        type: "КОНКУРСЫ СТУДЕНТАМ",
        link: "",
        about: `<p>Дата и время проведения: 25 марта 2025 года, 4 пара. Группа: 405.</p>
        <p>Дисциплина: «Учёт затрат и калькулирование в отраслях экономики».</p>
        <p>Тема занятия: «Учёт затрат и калькулирование себестоимости в транспортных организациях».</p>
        <p>Занятие пройдёт в рамках учебного процесса и будет интересно всем, кто хочет углубить свои знания в области бухгалтерского учёта и калькулирования затрат.</p>
        <p>Ждём всех желающих!<br/>
        Место проведения: уточняйте на кафедре.</p>
        <p>Не упустите возможность узнать что-то новое и полезное!</p>`
    },
    {
        id: 83,
        images: [fin],
        h1: "Образовательный интенсив «Разработка бизнес-проектов»",
        p: "Хотите научиться создавать рабочие бизнес-стратегии, оценивать риски и продвигать проекты? Тогда этот интенсив для вас!",
        data: "18.03.2025",
        type: "ПРОФРАЗВИТИЕ",
        link: "",
        about: `<p>Два дня, два эксперта, максимум практической информации.</p>
        <p style="font-weight:bold; ">19 марта | 14:45 | аудитория 200, корпус 11</p>
        <p><span style="font-weight:bold; ">Инна Барышникова</span> – руководитель Ассоциации социальных предпринимателей, эксперт по финансам и управлению проектами. Узнаем, как грамотно строить финансовый план и привлекать ресурсы. <a href = "http://forms.gle/XiDTVKhPCfxCTDDAA" target = "_blank">Ссылка на регистрацию.</a></p>
        <p><span style="font-weight:bold; ">Участие бесплатное, но требуется предварительная регистрация по ссылке! </span>Не упустите возможность прокачать свои знания и получить ценные советы от профессионалов!</p>`
    },
    {
        id: 82,
        images: [konkurs],
        h1: "Приднестровский государственный университет им. Т.Г. Шевченко объявляет выборы заведующего кафедрой университета",
        p: "",
        data: "17.03.2025",
        type: "КОНКУРСЫ",
        link: "",
        about: `<p>Приднестровский государственный университет им. Т.Г. Шевченко объявляет выборы заведующего кафедрой университета:</p>
        <ul style = "list-style-type: none">
            <li style = "font-weight: bold; ">по кафедре бизнес-информатики и математических методов в экономике</li>
        </ul>
        <p>Лица, желающие участвовать в выборах, подают в управление кадров университета заявление на имя ректора с приложением документов с установленным перечнем.</p>
        <p>Срок подачи документов - не позднее одного месяца со дня опубликования объявления.</p>
        <p>С условиями конкурса можно ознакомится в управлении кадров университета по адресу: г.Тирасполь, ул. 25 Октября, 128, корпус № 1, кабинет № 205.</p>
        <p></p>
        <p></p>`
    },
    {
        id: 81,
        images: [konkurs],
        h1: "Приднестровский государственный университет им. Т.Г. Шевченко объявляет конкурс на замещение штатных должностей",
        p: "",
        data: "17.03.2025",
        type: "КОНКУРСЫ",
        link: "",
        about: `<p>Считать вакантными должности, занимаемые нижеуказанными преподавателями и объявить конкурс на их замещение:</p>
        <ul style = "list-style-type: none">
            <li><span style = "font-weight: bold; ">по кафедре бизнес-информатики и математических методов в экономике:</span> доцент - 1</li>
            <li><span style = "font-weight: bold; ">по кафедре экономической теории и мировой экономики:</span> доцент - 1</li>
            <li><span style = "font-weight: bold; ">по кафедре бухгалтерского учета и аудита:</span> старший преподаватель - 3</li>
        </ul>
        <p>Лица, желающие участвовать в выборах, подают в управление кадров университета заявление на имя ректора с приложением документов с установленным перечнем.</p>
        <p>Срок подачи документов - не позднее одного месяца со дня опубликования объявления.</p>
        <p>С условиями конкурса можно ознакомится в управлении кадров университета по адресу: г.Тирасполь, ул. 25 Октября, 128, корпус № 1, кабинет № 205.</p>
        <p></p>
        <p></p>`
    },
    {
        id: 80,
        images: [tem],
        h1: "Результаты заочного тура «Будь в теме!»",
        p: "Подведены итоги заочного этапа конкурса «Будь в теме»!",
        data: "17.03.2025",
        type: "БУДЬ В ТЕМЕ!",
        link: "",
        about: `<p>Уровень представленных видеороликов был настолько высоким, что мы приняли решение пригласить на финал не 6, а 8 команд.</p>
        <p>Поздравляем финалистов:</p>
        <ul style="font-weight:bold; ">
            <li>План Б</li>
            <li>Эльдорадо</li>
            <li>Профит</li>
            <li>Ловцы фейков</li>
            <li>Кибер лапки</li>
            <li>Великолепная семёрка</li>
            <li>Агора</li>
            <li>Мошенники</li>
        </ul>
        <p>Ознакомиться с видео роликами можно по <a href = "https://m.youtube.com/@Econompgu" target = "_blank">ссылке</a>. Благодарим всех участников за проделанную работу и ждем финалистов на следующем этапе соревнования!</p>`
    },
    {
        id: 79,
        images: [dip1, dip2, dip3, dip4, dip5],
        h1: "Вручение дипломов студентам-заочникам экономического факультета ",
        p: "",
        data: "17.03.2025",
        type: "СТУДЕНТАМ",
        link: "",
        about: `<p>15 марта в Культурно-просветительском центре ПГУ им. Т. Г. Шевченко состоялось торжественное вручение дипломов выпускникам заочного отделения экономического факультета.</p>
        <p>
        Заветные документы об образовании получили студенты магистратуры и специалитета.
        </p>
        <p>Поздравляем выпускников и желаем им успехов. В добрый путь!</p>`
    },
    {
        id: 78,
        images: [esse],
        h1: "Минфин объявляет Республиканский конкурс эссе ко Дню работника финансовой системы ПМР!",
        p: "Работы принимаются с 10 марта по 11 апреля 2025 года.",
        data: "13.03.2025",
        type: "БУДЬ В ТЕМЕ!",
        link: "",
        about: `<p>Участвуют школьники (10-11 классы), студенты колледжей и вузов (до 23 лет).</p>
        <p style="font-weight:bold">Тематические направления эссе:</p>
        <p>
        «Роль финансиста на современном этапе развития ПМР»<br/>
        «Как финансовая грамотность меняет жизнь?»<br/>
        «История развития финансовой системы ПМР»<br/>
        «Какие качества отличают успешного финансиста?»<br/>
        «Финансы будущего: цифровая трансформация»<br/>
        «Мои финансовые лайфхаки: как управлять личным бюджетом?»
        </p>
        <p>Победителей наградят в мае 2025 года. Лучшая работа будет опубликована на сайте Минфина и в газете «Днестровская правда».</p>
        <p><span style="font-weight:bold">Подробности: </span>0 533 7 86 49</p>
        <p>Положение <a href = "https://minfin.gospmr.org/obyavlen-respublikanskij-konkurs-esse-ko-dnyu-rabotnika-finansovoj-sistemy-pmr/" target = "_blank">конкурса по ссылке.</a></p>`
    },
    {
        id: 77,
        images: [kino1, kino2],
        h1: "Студенты приняли участие в дискуссионном вечере и просмотре фильма «Ржев»",
        p: "",
        data: "13.03.2025",
        type: "СТУДЕНТАМ",
        link: "",
        about: `<p>12 марта в Культурно-просветительском центре ПГУ им. Т. Г. Шевченко состоялся дискуссионный вечер, посвященный 80-летию Победы в Великой Отечественной войне. В мероприятии приняли участие студенты экономического факультета, которые обсудили историческое значение сражений и посмотрели художественный фильм «Ржев».</p>`
    },
    {
        id: 76,
        images: [s8marta],
        h1: "Уважаемые коллеги, преподаватели, сотрудницы, аспирантки и студентки Приднестровского государственного университета им. Т.Г. Шевченко!",
        p: "Сердечно поздравляю вас с Международным женским днём – праздником весны, красоты и душевного тепла! ",
        data: "08.03.2025",
        type: "",
        link: "",
        about: `<p>Этот день наполнен особым смыслом, ведь он символизирует не только пробуждение природы, но и искреннюю благодарность вам – хранительницам гармонии, вдохновения и мудрости.</p>
        <p>Роль женщины в обществе бесценна – она воплощает собой любовь, заботу, вдохновение, но при этом демонстрирует высокий профессионализм, ответственность и стремление к великим целям. <br/>
        Вы – душа нашего университета, его сила и источник света. Ваш труд, терпение, энергия и стремление к знаниям создают атмосферу созидания и прогресса. Вы воспитываете, наставляете, исследуете, создаёте, открываете новое и при этом дарите окружающим тепло своих сердец. В вас удивительным образом сочетаются нежность и сила, мудрость и решительность, стойкость и великодушие.<br/>
        Среди вас – выдающиеся учёные, талантливые педагоги, успешные управленцы, целеустремлённые студентки и будущие первооткрыватели. Вы – гордость университета, его настоящее и будущее!<br/>
        В этот праздничный день примите слова глубокой признательности за вашу работу, вашу доброту и вашу невероятную силу духа. Пусть ваша жизнь будет наполнена радостью, уважением, счастьем и любовью. Пусть каждый день приносит вам улыбки, гармонию и благополучие! <br/>
        Дорогие женщины! <br/>
        Желаю вам весеннего настроения, вдохновения, исполнения всех желаний, крепкого здоровья и новых достижений! Пусть ваш путь будет освещён теплотой близких людей, а успех сопутствует во всех начинаниях! <br/>
        С праздником! <br/>
        </p>
        <p>
        Ректор ПГУ им. Т.Г. Шевченко, <br/>
        профессор В.В. Соколов.</p>`
    },
    {
        id: 75,
        images: [sovet],
        h1: "Информация о решениях Ученого совета экономического факультета – 27 февраля 2025г.",
        p: "",
        data: "28.02.2025",
        type: "УЧЕНЫЙ СОВЕТ",
        link: "",
        about: `<p style="font-weight:bold">ПОВЕСТКА ЗАСЕДАНИЯ УЧЕНОГО СОВЕТА ЭКОНОМИЧЕСКОГО ФАКУЛЬТЕТА</p>
        <p>Протокол от 27.02.25г. №06<br/>
        Дата и время проведения: 27 февраля 2025г., 14-45 час.<br/>
        Место проведения: ауд. 112, корпус 11.</p><br/>
        <p>
        <span style="font-weight:bold">01. </span>Об отборе контингента выпускников для работы в университете.<br/>
        <span style="font-weight:bold">02. </span>Об утверждении плана работы экономического факультета в области международного сотрудничества на 2025-2026 уч. год.</p>
        <p style="font-weight:bold; text-align:right">Информация декана И.Н. Узуна</p>
        <p>
        <span style="font-weight:bold">03. </span>Об итогах научной деятельности экономического факультета в 2024 году.<br/>
        <span style="font-weight:bold">04. </span>04.	Об утверждении плана проведения научных мероприятий на экономическом факультете в 2026 году.</p>
        <p style="font-weight:bold; text-align:right">Информация заместителя декана по научной работе В.Н. Босюка</p>
        
        <p>
        <span style="font-weight:bold">05. </span>О рекомендации к зачислению в качестве соискателей ученой степени кандидата наук для подготовки кандидатской диссертации по специальности 5.2.3. «Региональная и отраслевая экономика» при кафедре экономики и менеджмента экономического факультета и об утверждении тем диссертационной работы соискателей.</p>
        <p style="font-weight:bold; text-align:right">Информация заведующего кафедрой экономики и менеджмента Н.Н. Смоленского</p><br/>`
    },
    {
        id: 74,
        images: [masl],
        h1: "Масленичные гуляния в ПГУ: праздник удался!",
        p: "",
        data: "28.02.2025",
        type: "СТУДЕНТАМ",
        link: "",
        about: `<p>В ПГУ прошел веселый и красочный праздник Масленицы! Студенты представили творческие номера, продемонстрировали кулинарные таланты в конкурсе блинов и активно участвовали в масленичных забавах.</p><br/>
        <p>Экономический факультет занял почетное второе место и был отмечен специальным призом от Россотрудничества. Праздник получился теплым, душевным и подарил море положительных эмоций!</p><br/>`
    },
    {
        id: 73,
        images: [vip1, vip2, vip3, vip4],
        h1: "Выпускникам экономического факультета рассказали об условиях распределения",
        p: "24 февраля в актовом зале корпуса «А» прошла встреча студентов выпускных курсов экономического факультета с представителями отдела правового обеспечения и деканом факультета.  ",
        data: "26.02.2025",
        type: "СТУДЕНТАМ",
        link: "",
        about: `<br/><p style="font-weight:bold">Обсудили важные вопросы:</p><br/>
        <p>Как проходит распределение выпускников?<br/>
        В какие организации можно попасть на отработку?<br/>
        Что делать, если работодатель отказывается принимать выпускника?<br/>
        Можно ли заменить отработку индивидуальным предпринимательством?<br/>
        Засчитываются ли служба в армии и декретный отпуск в срок отработки?</p><br/>
        <p style="font-weight:bold">Главные моменты:</p><br/>
        <p>Распределение в первую очередь идет в госорганы и организации с госучастием.<br/>
        При отказе работодателя необходимо запросить письменный отказ и обратиться в университет.  <br/>
        Предпринимательская деятельность по патенту в срок отработки не засчитывается.</p><br/>`
    },
    {
        id: 72,
        images: [fev23],
        h1: "Уважаемые преподаватели, сотрудники, студенты и аспиранты Приднестровского государственного университета! Поздравляю вас с Днем защитника Отечества!",
        p: "",
        data: "23.02.2025",
        type: "",
        link: "",
        about: `<p>День защитника Отечества символизирует мужество, отвагу и честь. Это праздник доблести и верности долгу истинных патриотов свой земли. Тех, кто носит погоны, и тех, кто работает на развитие страны, живет ее интересами, кто готов к решительным действиям во имя благополучия республики и ее граждан, кто мирным трудом и воинской доблестью добивается благополучия и стабильности внутри государства, укрепляет его мощь и авторитет на мировой арене. Это праздник тех, кто является защитой и надежной опорой семьи, родных и близких.<br/>
        В понятие Родина входит очень многое. Это отчий дом, семья, дорогие и близкие сердцу люди, страна, к которой ощущаешь свою сопричастность, и работа – необходимая и полезная стране, в которой родился и живешь. Защищать Родину в мирное время – значит честно трудиться на ее благо, чтя славные традиции отцов и дедов. </p><br/>
        <p>Этот день имеет особое значение для всего нашего народа, который склоняет голову перед доблестными подвигами сынов Отечества, отдавших свои жизни на полях сражений. В памяти нынешнего и будущих поколений навсегда сохранятся подвиги воинов-защитников, их безграничная преданность и любовь к родной земле, героизм и мужество.</p><br/>
        <p>Уважаемые коллеги! Дорогие друзья!</p><br/>
        <p>Сегодня мы отдаем дань глубокого уважения всем, кто несет службу в рядах Вооруженных Сил, обеспечивает стабильность и безопасность нашего государства. Особые слова благодарности ветеранам, участникам боевых действий, всем, кто своим мужеством и самоотверженностью защищал и продолжает защищать родную землю.</p><br/>
        <p>В этот знаменательный день желаю вам крепости духа, несокрушимой воли, благополучия и успехов в каждом начинании! Пусть в вашем сердце всегда живёт гордость за Отечество, стремление к высоким идеалам и уверенность в собственных силах!</p><br/>
        <p>Ректор ПГУ им. Т.Г. Шевченко,<br/>
        профессор В.В. Соколов.</p><br/>`
    },
    {
        id: 71,
        images: [starostat],
        h1: "Собрание старост академических групп",
        p: "Собрание старост академических групп: ключевые моменты",
        data: "21.02.2025",
        type: "СТАРОСТАТ",
        link: "",
        about: `<p style="font-weight:bold">🔹 Итоги зимней сессии</p><br/>
        <p>Рассмотрены результаты экзаменационного периода 2024-2025 учебного года и сроки погашения академических задолженностей.</p><br/>
        <p style="font-weight:bold">🔹 Анкетирование и диагностика</p><br/>
        <p>Студенты 3 курса пройдут психологическую диагностику образовательной среды вуза, а выпускники – анкетирование о деятельности ПГУ.</p><br/>
        <p style="font-weight:bold">🔹 Стипендии и внутренний распорядок</p><br/>
        <p>Старосты ознакомились с Приказом на стипендию по итогам зимней сессии и напомнили о правилах внутреннего распорядка университета.</p><br/>
        <p style="font-weight:bold">🔹 Участие в мероприятиях</p><br/>
        <p>Студенты Экономического факультета приглашены к участию в крупных событиях:<br/>
        - Масляничный разгуляй (27 февраля, корпус А)<br/>
        - Праздничный концерт ко Дню защитника Отечества (21 февраля, 13:00, КПЦ)<br/>
        - Онлайн-игра «1418» – викторина о ВОВ (20 марта)<br/>
        - Республиканская игра для школьников «Будь в теме (отборочный – 17 февраля, финал – 21 марта)<br/>
        - Конкурс поэзии и тематический конкурс для проживающих в общежитии «Мы помним, мы гордимся!»</p><br/>`
    },
    {
        id: 70,
        images: [podgot],
        h1: "Подготовительные курсы для школьников",
        p: "",
        data: "18.02.2025",
        type: "АБИТУРИЕНТ",
        link: "",
        about: `<p style="font-weight:bold; text-align :center">Уважаемые выпускники общеобразовательных организаций и их родители!</p><br/>
        <p>Приднестровский государственный университет им. Т.Г. Шевченко объявляет набор слушателей на подготовительные курсы по подготовке к ЕГЭ и вступительным испытаниям ПГУ им. Т.Г. Шевченко 2025 года.</p><br/>
        <p style="text-align :center">Срок обучения – 3 месяца (48 часов). </p><br/>
        <p style="text-align :center">Занятия по каждому предмету проводят преподаватели университета 1 раз в неделю (2 пары).</p><br/>
        <p style="text-align :center">Стоимость обучения по одному предмету за весь курс: 423 руб. 30 коп.</p><br/>
        <p>Заявления принимаются до 28 февраля 2025 года:</p><br/>
        <p>- по адресу: город Тирасполь, ПГУ им. Т.Г. Шевченко, ул. 25 Октября, 128, корпус №3;</p><br/>
        <p>- по телефону: (0533) 7-95-05;</p><br/>
        <p>- онлайн: по QR-коду</p><br/>
        <img src="http://spsu.ru/images/2025_PGU_v_SMI/2/QR-code.JPG" />`
    },
    {
        id: 69,
        images: [tem],
        h1: "II РЕСПУБЛИКАНСКАЯ ИНТЕЛЛЕКТУАЛЬНАЯ ИГРА ДЛЯ ШКОЛЬНИКОВ ПО ФИНАНСОВОЙ ГРАМОТНОСТИ «БУДЬ В ТЕМЕ»",
        p: "",
        data: "14.02.2025",
        type: "БУДЬ В ТЕМЕ!",
        link: "",
        about: `<p style="font-weight:bold">Уважаемые учителя, ученики и родители!</p><br/>
        <p>Мы рады представить уникальную возможность для школьников проявить свои знания, творческий потенциал и навыки в области финансовой грамотности!</p><br/>
        <p>Мы убеждены, что финансовая грамотность – это неотъемлемая часть успешного будущего каждого человека. В современном мире умение правильно распоряжаться финансами становится всё более важным навыком. </p><br/>
        <p><span style="font-weight:bold">II Республиканская интеллектуальная игра среди школьников «Будь в теме»</span> создана для того, чтобы поддерживать школьников в этом важном образовательном путешествии.</p><br/>
        <p style="font-weight:bold">Почему стоит принять участие?</p><br/>
        <p>1. Развитие финансовых навыков: Участие в игре поможет школьникам глубже понять мир экономики и финансов и научиться принимать взвешенные решения в этой области.</p><br/>
        <p>2. Творческая самореализация: участникам предоставляется уникальная возможность выразить свои идеи, творческий подход и оригинальный взгляд на экономические вопросы через видеоролики.</p><br/>
        <p>3. Подготовка к будущему: Участие в игре станет замечательным опытом для будущих лидеров, предпринимателей и успешных специалистов.</p><br/>
        <p>4. Призы и награды: Школьники могут выиграть ценные призы и сертификаты, подчеркивающие их успехи и старания.</p><br/>
        <p style="font-weight:bold">Как принять участие?</p><br/>
        <p>1. Познакомьтесь с <a href="https://drive.google.com/file/d/1w0MSDcehL-HRRYZtVBTaFH5KSNKq5nfa/view?usp=drive_link" target="_blank">Положением об игре</a> и заполните регистрационную форму </p><br/>
        <p>2. Сформируйте команду: состав команды: 5-7 учащихся в возрасте от 15 до 17 лет от одного образовательного учреждения.</p><br/>
        <p>3. Подготовьте креативный видеоролик на тему: «Распознаем признаки финансового мошенничества» (длительностью не более 5 минут). Дайте свободу вашему воображению!</p><br/>
        <p>4. Отправьте заявку: загрузите видеоролик (ссылку для скачивания) и заявку от команды (фото) в период с 17 февраля по 9 марта 2025 года по  ссылке: 
        <a href="https://forms.gle/BgQMA7i7p6fBtQhbA" target="_blank">https://forms.gle/BgQMA7i7p6fBtQhbA</a>. </p><br/>
        <p>По итогам отборочного тура будут отобраны шесть команд.</p><br/>
        <p><span style="font-weight:bold">Финал игры</span> состоится 21 марта 2025 года в очном формате.</p><br/>
        <p style="font-weight:bold">Организаторы игры:</p><br/>
        <p>Министерство просвещения ПМР;<br/>
        Министерство иностранных дел ПМР;<br/>
        Приднестровский республиканский банк;</p><br/>
        <p>Экономический факультет ГОУ «Приднестровского государственного университета им. Т.Г. Шевченко».</p><br/>
        <p>Мы верим в талантливость и потенциал каждого ученика, и этот конкурс - отличная возможность для них проявить себя и обрести новые знания. Присоединяйтесь к нам в этом захватывающем путешествии к экономической и финансовой грамотности!</p><br/>
        <p style="font-weight:bold">К кому можно обратиться по вопросам участия в игре?</p><br/>
        <p>Координатор игры - Жильцова Юлия Дмитриевна, председатель научного комитета Студенческого самоуправления экономического факультета ГОУ «ПГУ им. Т.Г. Шевченко» - (номер телефона 777-69379).</p><br/>
        <p>Ответственный за организацию игры - Узун Иван Николаевич, декан экономического факультета ГОУ «ПГУ им. Т.Г. Шевченко» (номер телефона (533) 79-474).</p><br/>
        <p style="font-weight:bold">Помогите нам распространить информацию:</p><br/>
        <p>• Поделитесь информацией о конкурсе среди коллег, одноклассников и их родителей.</p><br/>
        <p>• Разместите информационные листы в школьных коридорах и на досках объявлений.</p><br/>
        <p>• Включите тему конкурса в вашу электронную рассылку.</p><br/>`
    },
    {
        id: 68,
        images: [newtem1,newtem2,newtem3,newtem4,newtem5,newtem6,newtem7],
        h1: "Научно-исследовательская лаборатория «Экономические исследования» приступила к реализации новой темы исследования.",
        p: "",
        data: "12.02.2025",
        type: "НАУКА",
        link: "",
        about: `<p>Научно-исследовательская лаборатория «Экономические исследования» приступила к реализации новой темы исследования: «Формирование потребности в кадрах и системы заказа бюджетных мест для подготовки профессиональных кадров всех уровней для экономики Приднестровской Молдавской Республики с акцентом на целевое направление на обучение». В связи с этим 11 февраля в конференц-зале Министерства просвещения ПМР, которое выступило заказчиком темы, состоялся одноименный круглый стол.</p><br/>`
    },
    {
        id: 67,
        images: [sovet08],
        h1: "СОВЕТ КУРАТОРОВ АКАДЕМИЧЕСКИХ ГРУПП ЭКОНОМИЧЕСКОГО ФАКУЛЬТЕТА",
        p: "8 ФЕВРАЛЯ 2025 ГОДА (ВРЕМЯ 11-10, ауд. 112)",
        data: "08.02.2025",
        type: "ВОСПИТАТЕЛЬНАЯ РАБОТА",
        link: "",
        about: `<p>1. Об итогах зимней экзаменационной сессии 2024-2025 уч. года и контроле за процессом погашения академических задолженностей </p><br/>
        <p>2. О ходе погашения задолженности по оплате за обучение студентов очной формы ЭФ (2 этап оплаты)</p><br/>
        <p>3. Об итогах участия кураторов всех академических групп ЭФ в Школе куратора (с 3.02 по 7.02)</p><br/>
        <p>4. Отчет по результатам психологической диагностики студентов 1 курса по программе «Адаптация» и студентов-выпускных курсов (4,5 и 2 магистратура) по программе «Профессиональное становление»</p><br/>
        <p>5. О составлении и утверждении графика проведения кураторских часов на 2 семестр 2024-2025 уч. года.</p><br/>
        <p>6. О продолжении выполнения графика посещения общежития и контроля за порядком проживания студентов ЭФ в общежитии.</p><br/>
        <p>7. О мероприятиях февраля-марта 2025 (общеуниверситетских и факультетских).</p><br/>
        <p>8. Информация Д.В.Формусатий.</p><br/>`
    },
    {
        id: 66,
        images: [online],
        h1: "УВАЖАЕМЫЕ СТУДЕНТЫ!",
        p: "",
        data: "07.02.2025",
        type: "СТУДЕНТАМ УМР",
        link: "",
        about: `<p>В экономическом факультет ГОУ «ПГУ им. Т.Г. Шевченко» с 10 февраля 2025 года возобновляется учебный процесс по основным образовательным программам бакалавриата, магистратуры, специалитета,  очной, очно-заочной и заочной форм обучения, с первого по выпускные курсы – в аудиторном формате.</p><br/>`
    },
    {
        id: 65,
        images: [telekviz1, telekviz2],
        h1: "В День студенчества состоялся увлекательный телеквиз, в котором приняли участие две команды от экономического факультета: «Тирионы» и «Везунчики».",
        p: "",
        data: "25.02.2024",
        type: "СТУДЕНТАМ ПРОФРАЗВИТИЕ",
        link: "",
        about: `<p>В напряжённой борьбе команда «Тирионы» заняла почётное второе место, отстав от победителя всего на 0,5 балла! Состав команд украсили Иван Николаевич Узун  и Диана Викторовна Формусатий. <br/>Поздравляем всех участников с ярким выступлением и благодарим за активное участие!</p><br/>
        <p>Напоминаем, что присоединиться к нашим командам может каждый желающий — для этого достаточно написать председателю научного комитета.</p><br/>`
    },
    {
        id: 64,
        images: [rektor],
        h1: "Дорогие студенты! Сердечно поздравляю вас с замечательным праздником – Днём студенчества!",
        p: "",
        data: "25.01.2024",
        type: "СТУДЕНТАМ",
        link: "",
        about: `<p>Студенчество – это яркий период, который становится основой для личностного и профессионального роста, это время открытий и смелых свершений. Студенческая жизнь – яркий калейдоскоп событий, где лекции сменяются дружескими посиделками, зачеты – незабываемыми праздниками, а серьезные научные проекты соседствуют с неожиданными творческими идеями. Это время, когда можно не бояться ошибок, а смело пробовать новое и ставить перед собой самые амбициозные цели.</p><br/>
        <p>Каждый из вас уникален, каждый вносит свою лепту в общую историю Приднестровского госуниверситета. Ваши первые шаги в аудиториях, ваши интересные вопросы, ваши смелые проекты, ваши победы в различных конкурсах и спортивных мероприятиях – это и есть те кирпичики, которые укрепляют фундамент нашего вуза. Спасибо, что стали частью нашей большой университетской семьи! Ваши достижения вдохновляют и нас, ваших наставников.</p><br/>
        <p>Руководители, преподаватели и сотрудники университета стремятся создать для вас комфортные условия, чтобы каждая ваша мечта могла превратиться в реальность. Мы гордимся, что вы выбрали наш университет, доверили нам свое будущее, амбиции и надежды.  Убежден, что ваши старания приведут вас к профессиональным вершинам и личностному успеху.<br/>
        Особые слова благодарности преподавателям, наставникам, чья самоотдача, забота и поддержка вдохновляют студентов на новые достижения, помогают раскрыть их таланты и уверенно идти к цели.</p><br/>
        <p style="font-weight:bold">Дорогие студенты!</p><br/>
        <p>Смело идите к своей мечте, будьте активными, инициативными и решительными.  Студенческие годы – это не только пора знаний, но и время дружбы, новых знакомств и первых побед.<br/>
        Старайтесь быть лучшей версией себя каждый день, ведь именно ваши усилия сегодня определят ваш успех завтра.<br/>
        Никогда не бойтесь трудностей – они лишь укрепляют ваш дух.<br/>
        Дерзайте, стремитесь к высотам и помните: будущее принадлежит вам!</p><br/>
        <p>Ректор ПГУ им. Т.Г. Шевченко,<br/>
        профессор В.В. Соколов.</p><br/>`
    },
    {
        id: 63,
        images: [studelder],
        h1: "Онлайн-встреча с деканом для обсуждения организации учебного процесса",
        p: "",
        data: "14.01.2024",
        type: "СТАРОСТАТ",
        link: "",
        about: `<p>13 января состоялась онлайн-встреча декана экономического факультета со старостами всех групп. Цель встречи — обсудить особенности организации учебного процесса в условиях чрезвычайной ситуации.</p><br/>`
    },
    {
        id: 62,
        images: [vr,vr1,vr2],
        h1: "",
        p: "Сегодня 11 января 2025 года на экономическом факультете состоялся расширенный Совет кураторов, на котором присутствовали также заведующие кафедрами, специалисты, штатные преподаватели и совместители.",
        data: "11.01.2024",
        type: "ВОСПИТАТЕЛЬНАЯ РАБОТА",
        link: "",
        about: `<p>На Совете  обсуждался основной вопрос об особенностях организации учебного процесса в период действия ЧП в Республике.</p><br/>`
    },
    {
        id: 61,
        images: [dreconom,dreconom1,dreconom2],
        h1: "25 декабря экономический факультет отметил свой 34-й день рождения.",
        p: "",
        data: "25.12.2024",
        type: "",
        link: "",
        about: `<p>Декан факультета Иван Николаевич Узун поздравил студентов и преподавателей, подчеркнув значимость этого события и единство коллектива.</p><br/>
        <p>Главным событием дня стал финал игры "MONEY GRAM", в котором участвовали лучшие студенты. Финал добавил азарта празднику, а победителем стал студент второго курса Илья Гаина. Поделившись впечатлениями, он отметил:</p><br/>
        <p><p style="border-radius: 5px; background-color: rgb(249 233 239); border-left: 4px solid rgb(210 83 129); padding: 10px;">
        Игра оказалась интересной и познавательной, она позволила увидеть, как можно применять финансовые знания в реальных ситуациях. Были моменты, когда приходилось быстро принимать решения, что добавляло напряжённости и учило думать наперёд. Она помогла лучше понять, как важно планировать свои расходы и инвестировать деньги, чтобы добиться успеха.
        </p><br/>
        <p>День завершился на яркой и вдохновляющей ноте, подчеркнув дух единства факультета.</p><br/>
        <p>Помощник медиа комитета - Алина Репида.</p><br/>`
    },
    {
        id: 60,
        images: [sud],
        h1: "Встреча студентов экономического факультета с Сергеем Михайловичем Степановым",
        p: "",
        data: "25.12.2024",
        type: "СТУДЕНТАМ",
        link: "",
        about: `<p>23 декабря на юридическом факультете прошла встреча студентов экономического факультета с Сергеем Михайловичем Степановым — судьей-секретарем Конституционного суда ПМР. Тема обсуждения — Конституция и её роль в современном обществе. Сергей Михайлович рассказал о ключевых положениях Конституции и её влиянии на правовую и экономическую систему.</p><br/>`
    },
    {
        id: 59,
        images: [hart],
        h1: "Финал турнира «HART Money Gram!»",
        p: "25 декабря 2024 года состоится долгожданный финал турнира экономического факультета по игре «HART Money Gram!»",
        data: "20.12.2024",
        type: "СТУДЕНТАМ ПРОФРАЗВИТИЕ",
        link: "",
        about: `<p>В решающей схватке встретятся лучшие из лучших — победители предыдущих игр, сильнейшие игроки со своих курсов: </p><br/>
        <p>— <span style="font-weight: bold;">1 курс: </span>Клименко Александр Андреевич и Врабие Николай</p><br/>
        <p>— <span style="font-weight: bold;">2 курс: </span>Константин Микулец и Гаина Илья</p><br/>
        <p>— <span style="font-weight: bold;">3 курс: </span>Турей Дмитрий, Олейник Никита и Макеева Екатерина</p><br/>
        <p>— <span style="font-weight: bold;">5 курс: </span>Глинка Денис</p><br/>
        <p>Начало финала в <span style="font-weight: bold;">9:40</span> в кабинете <span style="font-weight: bold;">306.</span></p><br/>
        <p>Желаем ребятам удачи!</p><br/>`
    },
    {
        id: 58,
        images: [miss,miss1,miss2,miss3,miss4,miss5,miss6,miss7,miss8,miss9],
        h1: "18 декабря в Культурно-просветительском центре ПГУ прошел конкурс «Мисс Эконом 2024» под девизом «Путешествие с Экономом без границ».",
        p: "Мероприятие не только выявило самых обаятельных студенток, но и поддержало 13-летнюю Анну Колесниченко из Тирасполя в ее борьбе с онкологическим заболеванием.",
        data: "20.12.2024",
        type: "СТУДЕНТАМ МИССЭКОНОМ",
        link: "",
        about: `<p>В этом году за титул боролись 8 участниц, каждая из которых продемонстрировала красоту и творческие способности, отражающие богатство культур. Победительницей стала Алина Репида (105 группа), 1 вице-мисс — Ксения Руссу (108 группа), а титул 2 вице-мисс получил Ирина Спанаке (105 группа).</p><br/>
        <p>Участницы были награждены в уникальных номинациях: </p><br/>
        <p>— <span style="font-weight: bold;">Мисс артистичность </span>— Екатерина Якименко (101 группа)</p><br/>
        <p>— <span style="font-weight: bold;">Мисс скромность </span>— Мария Факира (102 группа)</p><br/>
        <p>— <span style="font-weight: bold;">Мисс харизма </span>— Екатерина Шонц (103 группа)</p><br/>
        <p>— <span style="font-weight: bold;">Мисс совершенство </span>— Алла Лукьянчук (104 группа)</p><br/>
        <p>— <span style="font-weight: bold;">Мисс очарование </span>— Алина Репида (105 группа)</p><br/>
        <p>— <span style="font-weight: bold;">Мисс загадочность </span>— Ирина Спанаке (105 группа)</p><br/>
        <p>— <span style="font-weight: bold;">Мисс доброта </span>— Ирина Погребан (108 группа)</p><br/>
        <p>— <span style="font-weight: bold;">Мисс грация </span>— Ксения Руссу (108 группа)</p><br/>
        <p>Особую награду <span style="font-weight: bold;">«Мисс зрительских симпатий»</span> получила Екатерина Шонц (103 группа).</p><br/>
        <p>Мы благодарим спонсоров конкурса: ОАО «Эксимбанк», Ассоциацию фитнес-индустрии «Фитнес-мафия», Радио ПМР, магазин «Kokо», фотографа А.В. Ефодиеву и партнёров — С.А. Казмалы, К.О. Дадашеву и К.А. Звинаревскую за их неоценимый вклад. Всем им были вручены благодарственные письма.</p><br/>
        <p>Благодаря зрителям и волонтёрам проекта «Добро вокруг нас» было собрано 4305 рублей на лечение Анны Колесниченко.</p><br/>
        <p>Помощник медиа комитета - Алина Репида.</p><br/>`
    },
    {
        id: 57,
        images: [itogi],
        h1: "Итоги собрания старост академических групп",
        p: "",
        data: "19.12.2024",
        type: "СТУДЕНТАМ",
        link: "",
        about: `<p>18 декабря 2024 года состоялось собрание старост академических групп, на котором были обсуждены ключевые вопросы, касающиеся учебного процесса и предстоящих мероприятий.</p><br/>
        <p style="font-weight: bold;">Основные темы встречи: </p><br/>
        <p>1. <span style="font-weight: bold;">Подготовка к зимней экзаменационной сессии 2024–2025 учебного года. </span><br/>Обсуждались сроки, формат и особенности сдачи экзаменов, а также правила посещения консультаций.</p><br/>
        <p>2. <span style="font-weight: bold;">Информация о тарифах оплаты за обучение в 2025 году.</span><br/>
        Студентов проинформировали о новых предельных тарифах, которые будут действовать в следующем учебном году.</p><br/>
        <p>3. <span style="font-weight: bold;">Мероприятие «Мисс ЭФ 2024».</span><br/>Старосты пригласили студентов активно поддержать участниц конкурса, который пройдет 18 декабря в 17:00 в КПЦ.</p><br/>
        <p>4. <span style="font-weight: bold;">День самоуправления.</span><br/>Был анонсирован финал мероприятия, приуроченного ко Дню рождения экономического факультета, который пройдет 25 декабря.</p><br/>
        <p>5. <span style="font-weight: bold;">Выступление Д.В. Формусатий.</span><br/>
        Были освещены вопросы компенсации за питание, обновления рейтинговой таблицы, выплат стипендий, а также организации трудового семестра.</p><br/>
        <p>Собрание прошло продуктивно, старосты получили актуальную информацию для дальнейшей работы со своими группами.</p><br/>`
    },
    {
        id: 56,
        images: [misseconom1],
        h1: "Сегодня состоится одно из самых ярких мероприятий года - «Мисс Эконом»!",
        p: "",
        data: "18.12.2024",
        type: "СТУДЕНТАМ МИССЭКОНОМ",
        link: "",
        about: `<p>Мы все в невероятном предвкушении и волнении. Пока мы готовимся встречать гостей и участников, слово берет председатель нашего творческого комитета - Дарья Брыжко.</p><br/>
        <p>🔗Смотрим по ссылке: <a href="https://www.instagram.com/reel/DDt00wzt2Ud/?igsh=d2s1ajhsend3Zmwx" target="_blank">https://www.instagram.com/reel/DDt00wzt2Ud/?igsh=d2s1ajhsend3Zmwx</a></p><br/>`
    },
    {
        id: 55,
        images: [misseconom],
        h1: "Пристегните ремни — мы взлетаем!",
        p: "18 декабря в 17:00 состоится долгожданный факультетский конкурс «Мисс Эконом»!",
        data: "14.12.2024",
        type: "СТУДЕНТАМ МИССЭКОНОМ",
        link: "",
        about: `<p>На этот раз темой вечера станет <i>«Путешествие с Экономом без границ»</i>. Конкурсантки погрузят зрителей в атмосферу невероятных приключений, раскрывая талант, харизму и грацию.</p><br/>
        <p>Мероприятие пройдёт в КПЦ, стоимость входа — 20 рублей. Все собранные средства будут направлены на благотворительность.</p><br/>`
    },
    {
        id: 54,
        images: [igra4,igra5],
        h1: "Финансовая игра, в которой ранее участвовали 2 и 3 курс, теперь дошла и до первокурсников! ",
        p: "",
        data: "14.12.2024",
        type: "СТУДЕНТАМ ПРОФРАЗВИТИЕ",
        link: "",
        about: `<p>Новички смогли попробовать свои силы в моделировании экономических ситуаций, начиная с нуля и принимая важные решения для достижения финансового успеха.</p><br/>`
    },
    {
        id: 53,
        images: [igra1,igra2,igra3],
        h1: "Студенты третьего курса испытали себя в финансовой игре.",
        p: "Третьекурсники приняли участие в увлекательной финансовой игре, моделирующей реальные экономические ситуации.",
        data: "14.12.2024",
        type: "СТУДЕНТАМ ПРОФРАЗВИТИЕ",
        link: "",
        about: `<p>Задачей участников было разбогатеть с нуля, применяя стратегии и принимая важные решения.</p><br/>
        <p>Победители курса готовятся к финальной битве, где встретятся сильнейшие из всех потоков!</p><br/>`
    },
    {
        id: 52,
        images: [minfin1,minfin2,minfin3,minfin4,minfin5,minfin6,minfin7],
        h1: "Студенты 1 курса Экономического факультета ПГУ посетили Налоговую инспекцию Тирасполя.",
        p: "",
        data: "14.12.2024",
        type: "СТУДЕНТАМ АКТУАЛЬНОЕ",
        link: "",
        about: `<p>Молодёжь познакомилась с работой инспекторов, узнала об их обязанностях и о том, как проходят камеральные проверки. Специалисты рассказали, чем занимается управление отчётности, отделы по работе с физлицами и юридический отдел.</p><br/>
        <p>Руководство инспекции отметило, что готово принять студентов на практику и трудоустройство в будущем. Напомнили, что выпускники-бюджетники обязаны отработать установленный срок в республиканских организациях, и такие экскурсии помогают увидеть реальные перспективы.</p><br/>`
    },
    {
        id: 51,
        images: [sumerki1,sumerki2,sumerki3],
        h1: "КиноКвиз по «Сумеркам»!",
        p: "",
        data: "14.12.2024",
        type: "СТУДЕНТАМ АКТУАЛЬНОЕ",
        link: "",
        about: `<p>КиноКвиз, посвященный культовой саге «Сумерки», прошел при поддержке Greenhouse и Бизнес Хаба. Команда экономического факультета «Вечность» показала отличные знания и заняла первое место.</p><br/>
        <p>Победители получили кружки и сертификаты. Поздравляем с победой и благодарим организаторов за прекрасное мероприятие!</p><br/>`
    },
    {
        id: 50,
        images: [prof1,prof2,prof3],
        h1: "28 ноября: День профсоюзника",
        p: "",
        data: "14.12.2024",
        type: "СТУДЕНТАМ АКТУАЛЬНОЕ",
        link: "",
        about: `<p>28 ноября в университете прошел праздничный концерт, посвященный Дню профсоюзника. Это ежегодное мероприятие собрало студентов, преподавателей и гостей, чтобы отметить вклад профсоюзов в жизнь учебного заведения.</p><br/>
        <p>Факультеты подготовили яркие и креативные номера, продемонстрировав свои таланты в танцах, вокале и театральных постановках. Среди участников особенно выделилась команда экономического факультета, которая заняла почетное третье место.</p><br/>
        <p>Поздравляем экономистов с заслуженной наградой и благодарим всех участников за атмосферу праздника и сплоченности! День профсоюзника в очередной раз доказал, что вместе мы сильнее.</p><br/>`
    },
    {
        id: 49,
        images: [zel1,zel2,zel3],
        h1: "Выставка «Н.Д.Зелинский - гордость отечественной науки»",
        p: "",
        data: "14.12.2024",
        type: "СТУДЕНТАМ",
        link: "",
        about: `<p>Сегодня 29 ноября студенты 1 курса посетили передвижную баннерную выставку "Н.Д.Зелинский - гордость отечественной науки", посвященной выдающемуся ученому-химику Николаю Дмитриевичу Зелинскому.</p><br/>
        <p>С экспозицией ребят познакомила старший методист отдела социальной защиты и психологического сопровождения Ю.В.Нечаева.</p><br/>`
    },
    {
        id: 48,
        images: [voleyball1,voleyball2,voleyball3,voleyball4,voleyball5,voleyball6,voleyball7],
        h1: "Турнир по волейболу: борьба за Кубок декана🏐✨",
        p: "",
        data: "14.12.2024",
        type: "СТУДЕНТАМ СПОРТ",
        link: "",
        about: `<p>24 ноября состоялся захватывающий турнир по волейболу, где команды боролись за Кубок декана. Этот день подарил участникам и зрителям море драйва, эмоций и настоящего спортивного азарта. </p><br/>
        <p>
        🥇 Победителем стала команда "Экономики", показавшие мастерство и слаженную игру.<br/>
        🥈 Второе место досталось команде "Дембеля", которые упорно сражались до самого финала.<br/>
        🥉 Почётное третье место заняли "Выпускники", порадовавшие зрителей яркой игрой.<br/>
        </p><br/>
        <p style="font-weight: bold;">Организация турнира была на высшем уровне!</p><br/>
        <p>- За музыкальное сопровождение и поднятие настроения отвечал Никита Дабижа, создавая атмосферу праздника.<br/>
        - Счёт вёл Александр Дрига, чья точность и оперативность обеспечили ясность в динамике матчей.<br/>
        - Главные спонсоры турнира — <span style="font-weight: bold;">Эксим Банк</span> и <span style="font-weight: bold;">Фитнес Мафия</span> — сделали возможным проведение этого яркого мероприятия.<br/></p><br/>
        <p>Поздравляем победителей и благодарим всех участников за яркие эмоции, красивую игру и незабываемый турнир! Ждём всех на следующих спортивных событиях. 💪</p><br/>`
    },
    {
        id: 47,
        images: [priznanie],
        h1: "✨21 ноября 2024 года в КПЦ прошел финал конкурса «Признание», который собрал студентов со всего университета.",
        p: "В этот день зрителей ждала яркая программа, наполненная творчеством, вдохновением и настоящими талантами.",
        data: "14.12.2024",
        type: "СТУДЕНТАМ АКТУАЛЬНОЕ",
        link: "",
        about: `<p>СЖюри, которое с большим вниманием оценивало каждое выступление, решило наградить лучших из лучших:</p><br/>
        <p>
        🏆 <span style="font-weight: bold;">1 место</span> — Шойко Виталий (101 группа)<br/>
        🏆 <span style="font-weight: bold;">1 место</span> — Почтарь Иван (105 группа)<br/>
        🥈 <span style="font-weight: bold;">2 место</span> — Сафонова Екатерина (305 группа)<br/>
        🥈 <span style="font-weight: bold;">2 место</span> — Холоденко Валерия (401 группа)<br/>
        </p><br/>
        <p>Конкурс «Признание» стал настоящим праздником для всех участников, зрителей и организаторов. Также он стал отличной возможностью для студентов заявить о себе и получить заслуженное признание за свои усилия.</p><br/>
        <p>Помощник медиа комитета - Иванов Андрей.</p><br/>`
    },
    {
        id: 46,
        images: [decada1,decada2],
        h1: "Экономисты в гостях у медиков: экскурсия в рамках Декады молодежи и студентов ",
        p: "",
        data: "14.12.2024",
        type: "СТУДЕНТАМ",
        link: "",
        about: `<p>Студенты экономического факультета продолжают активно участвовать в мероприятиях, приуроченных к Декаде молодежи и студентов. Одним из ярких событий стала экскурсия на медицинский факультет, которая прошла в увлекательной и познавательной атмосфере.</p><br/>
        <p>Участники познакомились с учебным процессом будущих медиков, посетили специализированные лаборатории и учебные аудитории.</p><br/>
        <p>Благодарим организаторов экскурсии за теплый прием и увлекательную программу!</p><br/>`
    },
    {
        id: 45,
        images: [itogi],
        h1: "Итоги собрания старост академических групп",
        p: "",
        data: "14.12.2024",
        type: "СТУДЕНТАМ",
        link: "",
        about: `<p>1. <span style="font-weight: bold;">Акция «Курить уже не модно!»</span>Представлена презентация и распространены информационные буклеты среди старост.</p><br/>
        <p>2. <span style="font-weight: bold;">Поощрение студентов по итогам 2023-2024 учебного года.</span><br/>
         - 10 студентов будут удостоены Грамоты университета, вручение состоится на Ученом совете факультета.<br/>
         - 10 студентов получат благодарность с внесением в личное дело.<br/>
          - 10 студентов будут награждены денежной премией в размере 150 рублей.  
        </p><br/>
        <p>3. <span style="font-weight: bold;"> Промежуточная аттестация.</span> Отмечено, что старостам необходимо усилить контроль за успеваемостью студентов.</p><br/>
        <p>4. <span style="font-weight: bold;">Диагностика студентов 1 курса по программе «Адаптация». </span> – Напомнили о необходимости завершить диагностику до 20 ноября.</p><br/>
        <p>5. <span style="font-weight: bold;">Анонс ближайших мероприятий:</span><br/>
         - Спортивный фестиваль по волейболу (24 ноября, 10:00)<br/>
         - Подведение итогов фотоконкурса «Мы встретились на экономе» (21 ноября)<br/>
         - Финал конкурса «Признание» (21 ноября, 15:00, КПЦ)<br/>
         - Творческий вечер, посвященный поэту Владимиру Маяковскому (26 ноября, 15:30, КПЦ)<br/>
         - День профсоюзника и закрытие Декады молодежи (28 ноября, 17:00, КПЦ)<br/>
         - Мисс Экономический факультет (18 декабря, 17:00, КПЦ)
        </p><br/>`
    },
    {
        id: 44,
        images: [grant9,grant8],
        h1: "",
        p: "14 ноября прошел заключительный этап Образовательного интерактива «социальные гранты: от идеи проекта до его реализации», на котором участники представили свои проекты",
        data: "14.12.2024",
        type: "СТУДЕНТАМ АКТУАЛЬНОЕ НАУКА",
        link: "",
        about: `<p>1. <span style="font-weight: bold;">«Мы рядом!»</span> – проект, направленный на оказание социальной помощи детям с особенностями в развитии.</p><br/>
        <p>2. <span style="font-weight: bold;">«Юридическая консультация»</span> – предоставление бесплатной юридической помощи для социально-незащищенных категорий населения.</p><br/>
        <p>3. <span style="font-weight: bold;">«Содружество»</span> – восстановление лагеря "Содружество" для проведения мероприятий, способствующих сплочению студентов и преподавателей.</p><br/>
        <p>4. <span style="font-weight: bold;">«Школа будущих профессий»</span> – профориентационная программа для школьников, где в игровом формате демонстрируются возможности различных направлений обучения.</p><br/>
        <p>5. <span style="font-weight: bold;">«В ритме будущего»</span> – создание пространства для поддержки и развития талантов музыкантов республики.</p><br/>
        <p>По итогам мероприятия участникам были вручены сертификаты. Однако, несмотря на завершение презентаций, работа над проектами продолжается. Каждая инициатива будет дорабатываться под руководством Александра Владимировича.</p><br/>`
    },
    {
        id: 43,
        images: [volonter1,volonter2],
        h1: "«Центр помощи животным» в городе Бендеры",
        p: "",
        data: "14.12.2024",
        type: "СТУДЕНТАМ ВОЛОНТЕР",
        link: "",
        about: `<p>Сегодня волонтеры экономического факультета посетили "Центр помощи животным" в городе Бендеры. Целью поездки было помочь приюту с уборкой территории. Наши волонтеры подметали и выносили листву.</p><br/>
        <p>Работники приюта и руководитель Плагова Ирина Георгиевна поблагодарили наш факультет за помощь.</p><br/>`
    },
    {
        id: 42,
        images: [kviz1,kviz2,kviz4],
        h1: "Интеллектуальный квиз «Что? Где? Когда?»",
        p: "",
        data: "14.12.2024",
        type: "СТУДЕНТАМ АКТУАЛЬНОЕ",
        link: "",
        about: `<p>Сегодня состоялся интеллектуальный квиз "Что? Где? Когда?", в котором команда экономического факультета проявила себя наилучшим образом и заняла первое место. В напряженной борьбе они обошли соперников, демонстрируя глубокие знания, командный дух и эрудицию.</p><br/>
        <p>🏆Поздравляем с сегодняшней победой и желаем дальнейших успехов в интеллектуальных состязаниях!</p><br/>`
    },
    {
        id: 41,
        images: [repida],
        h1: "МОЛОДЕЖЬ ПМР ПРОТИВ КОНТРАФАКТА / А.Репида",
        p: "",
        data: "14.12.2024",
        type: "СТУДЕНТАМ",
        link: "",
        about: `<p>⭐️Студенческий декан экономического факультета Артем Репида под руководством Татьяны Петровны Стасюк подготовил аналитическую статью на тему "Молодежь ПМР против контрафакта".</p><br/>
        <p>В своей публикации, размещенной на платформе EurasiaUN, поднимается вопрос о значительном влиянии контрафактной продукции на экономику и общество Приднестровской Молдавской Республики.</p><br/>
        <p>В статье обращают внимание на опасности, которые несет контрафакт для общества, отмечая:</p><br/>
        <p style="border-radius: 5px; background-color: rgb(249 233 239); border-left: 4px solid rgb(210 83 129); padding: 10px;">"Контрафактные товары представляют серьезную угрозу для общества, поскольку они подрывают доверие потребителей и наносят ущерб репутации честных производителей."</p><br/>
        <p>Артем также подчеркивает роль молодежи в борьбе с подделками, добавляя, что студенты могут стать важными участниками в информировании населения о вреде и последствиях использования контрафактной продукции: </p><br/>
        <p style="border-radius: 5px; background-color: rgb(249 233 239); border-left: 4px solid rgb(210 83 129); padding: 10px;">"Молодежь должна активно участвовать в распространении знаний о вреде поддельных товаров, способствуя формированию правосознания и соблюдению правопорядка."</p><br/>
        <p>🔗Полностью данную статью можно прочитать по ссылке:<br/>
        <a href="https://eurasiaun.org/tpost/drfmcpbl31-molodezh-pmr-protiv-kontrafakta-arepida" target="_blank" style="text-decoration: none;">https://eurasiaun.org/tpost/drfmcpbl31-molodezh-pmr-protiv-kontrafakta-arepida</a></p><br/>`
    },
    {
        id: 40,
        images: [opendoor1,opendoor2,opendoor3,opendoor4],
        h1: "День открытых дверей на экономическом факультете",
        p: "На экономическом факультете успешно прошел День открытых дверей, собравший  будущих студентов.",
        data: "14.12.2024",
        type: "АБИТУРИЕНТ",
        link: "",
        about: `<p>Это мероприятие предоставило уникальную возможность для всех присутствующих глубже познакомиться с образовательными программами и особенностями учебного процесса.</p><br/>
        <p>✨Одним из ключевых моментов мероприятия стала встреча с деканом факультета, который поделился информацией о возможностях обучения и карьерного роста. Он подробно объяснил, какие специальности предлагает факультет, и какие навыки будут особенно полезны в будущей профессиональной деятельности.</p><br/>
        <p>✅ Финансовая игра, организованная в рамках мероприятия, позволила школьникам продемонстрировать свои знания и навыки в управлении финансами. Участники узнали о востребованных профессиях в области экономики и финансов, а также о том, какие практические шаги они могут предпринять для успешного старта своей карьеры.</p><br/>
        <p>Помощник медиа комитета - Руссу Ксения.</p>`
    },
    {
        id: 39,
        images: [sert1, sert2, sert3, sert4],
        h1: "Международная школа СНО: новые горизонты для студенческой науки",
        p: "С 17 по 19 октября состоялась Международная школа СНО, организованная МГУ им. М.В. Ломоносова.",
        data: "02.11.2024",
        type: "СТУДЕНЧЕСКАЯ НАУКА",
        link: "",
        about: `<p>В онлайн-формате прошли интенсивные тренинги и мастер-классы, посвященные актуальным вопросам развития студенческой науки.</p><br/>
        <p>⭐️От нашего университета в школе приняли участие руководители научных кружков: Ляшкова Е.А., Человская Е.И., Формусатий Д.В., а также председатель научного комитета ЭФ Жильцова Юлия и студентка 1 курса Репида Алина.</p><br/>
        <p>🗣️Участники школы познакомились с лучшими практиками организации СНО в различных ВУЗах, обсудили существующие проблемы и разработали эффективные решения. По итогам мероприятия все участники получили сертификаты.</p><br/>`
    },
    {
        id: 38,
        images: [openday],
        h1: "Дорогие будущие студенты!",
        p: "Приглашаем вас на день открытых дверей нашего факультета! ",
        data: "02.11.2024",
        type: "АБИТУРИЕНТ",
        link: "",
        about: `<p>Это уникальная возможность познакомиться с нашим факультетом, задать интересующие вопросы и узнать о студенческой жизни из первых уст.</p><br/>`
    },
    {
        id: 37,
        images: [stud1, stud2, stud3, stud4, stud5, stud6, stud7, stud8],
        h1: "Посвящение в студенты на экономическом факультете",
        p: "Торжественное посвящение первокурсников в студенты экономического факультета 29 октября проходило в КПЦ ПГУ.",
        data: "31.10.2024",
        type: "СТУДЕНТАМ",
        link: "",
        about: `<p>Партнёрами мероприятия стали Министерство просвещения ПМР, ассоциация социальных предпринимателей, агентство инноваций и развития, профком студентов ПГУ.</p><br/> 
        <p>Перед началом посвящения ведущие объявили об организации благотворительного сбора в рамках волонтёрского движения экономического факультета «Добро вокруг нас». Сбор средств проводился для четырёхлетнего Саши Садковского, нуждающегся в дорогостоящем лечении после перенесенного коронавируса с тяжелыми осложнениями.</p><br/>
        <p>Концертную программу открыл образцовый танцевальный коллектив «Жемчужинка» Центра детско-юношеского творчества г. Слободзея под руководством С.Н. Сауленко.</p><br/>
        <p>Церемония посвящения в студенты проходила в формате конкурса творческих визиток. Задача студентов состояла в том, чтобы представить свою группу как можно ярче и креативнее. Всего было 9 команд.  Совместно с кураторами групп первокурсники устроили настоящее шоу, с танцами, веселыми скетчами и презентационными видеороликами. Каждая команда проявила оригинальность в создании творческих номеров, начиная с музыкальной подборки и заканчивая сценическими образами.</p><br/>
        <p>Выступления оценивались жюри, в состав которого вошли руководитель образцового танцевального коллектива «Жемчужинка» С.Н. Сауленко, начальник Управления по молодежной политике и дополнительному образованию Министерства просвещения ПМР Г.В. Ратушняк, главный специалист УМПиДО В.И. Циненко, проректор по молодежной политике ПГУ И.А. Спивак, директор агентства инноваций и развития Т.В. Жолобова.</p><br/>
        <p>Пока жюри обсуждало презентации команд, на сцену для вручения зачётных книжек был приглашён декан <a href="#" target="_blank" style="text-decoration:none;">экономического факультета</a> И.Н. Узун. Иван Николаевич тепло поздравил первокурсников с началом их студенческой жизни. «Сегодняшний день показал, что вы, действительно, молодцы! Вы учитесь, стараетесь, и у вас это получается», – отметил декан. После вручения зачётных книжек первокурсники по традиции принесли «Клятву студента».</p><br/>
        <p>Затем были оглашены команды-победители творческого конкурса. Первое место заняла группа специальности «Финансово-экономическая безопасность», второе – группа профиля «Бухгалтерский учет, анализ и аудит», третье – группа профиля «Финансы и кредит». Призёры и участники конкурса получили грамоты и сладкие призы от спонсоров.  Руководителю танцевального коллектива «Жемчужинка» было вручено благодарственное письмо от Министерства просвещения. За активное участие в церемонии посвящения в студенты и артистизм грамотами Министерства просвещения были награждены студентки первого курса Алина Ефодьева и Дарья Землянцева, за активное и креативное сопровождение в социальных сетях мероприятий слёта <a href="#" target="_blank" style="text-decoration:none;">экономического факультета</a> – студентка второго курса Анастасия Шокот.</p><br/>
        <p>Для комфортного спортивного досуга студентов   Министерство просвещения пополнило спортинвентарь <a href="#" target="_blank" style="text-decoration:none;">экономического факультета</a> новыми мячами и дартсом. Ассоциация социальных предпринимателей и агентство инноваций и развития каждой кафедре подарили набор канцелярских товаров.</p><br/>        
        <p>… Студенты и преподаватели спускались со сцены под звуки гимна «Эконома» (так любовно называют экономический факультет),  «Эконом – теперь и ваш дом!» могли бы они сказать первокурсникам, получившим свой первый студенческий документ.</p><br/>
        <p style="text-align: right;">Николай Зинченко.</p><br/>`
    },
    {
        id: 36,
        images: [nabor11, nabor11_1, nabor11_2, nabor11_3],
        h1: "Объявление о наборе на программу «Основы экономики и финансовой грамотности»",
        p: "Экономический факультет ГОУ «ПГУ им. Т.Г. Шевченко» с 2010 года проводит обучение по дополнительной программе «Основы экономики и финансовой грамотности». Приглашаются учащиеся 11 классов из всех городов Приднестровья.",
        data: "21.10.2024",
        type: "АБИТУРИЕНТ",
        link: "",
        about: `<p>Занятия стартуют в ноябре и будут проводиться два раза в месяц по субботам в комбинированном формате. Для участия в собеседовании необходимо пройти электронную регистрацию до 31 октября 2024 года. Успешно завершившие программу получат сертификаты, которые дают льготы при поступлении на экономический факультет.</p><br/>        
        <p>Дополнительную информацию можно получить по телефону (0533) 79483 или по электронному адресу<br/> <a href="#" target="_blank" style="text-decoration:none;">kafedra-etime@mail.ru.</a></p><br/>
        <p>Регистрация - <a href="https://forms.gle/E4SrbS6MQxnvpTr47" target="_blank" style="text-decoration:none;">https://forms.gle/E4SrbS6MQxnvpTr47</a>
        </p><br/>`
    },
    {
        id: 35,
        images: [grant1, grant2, grant3, grant4, grant5, grant6, grant7],
        h1: "Гранты: от идеи проектов – до их реализации",
        p: "Образовательный интерактив «Социальные гранты: от идеи проектов – до их реализации» для студентов и преподавателей Приднестровского государственного университета стартовал 17 октября.",
        data: "19.10.2024",
        type: "СТУДЕНЧЕСКАЯ НАУКА АКТУАЛЬНОЕ",
        link: "",
        about: `<p>Мероприятие прошло под руководством начальника отдела по работе с общественными объединениями Управления Президента ПМР по внутренней и внешней политике А.В. Березовского.</p><br/>
        <p>Образовательная программа представляет собой отличную платформу для студентов и преподавателей, которые хотят реализовать свои идеи на практике и внести вклад в развитие Приднестровья.</p><br/>        
        <p>На первом занятии А.В. Березовский познакомил присутствующих с Положением о грантах Президента Приднестровской Молдавской Республики, объяснил, кто имеет право подавать заявки на участие и какие существуют требования к проектам. Александр Владимирович подробно рассказал, как правильно оформлять заявление и отметил, что средства, выделяемые на реализацию проекта, могут использоваться исключительно по целевому назначению. Грантополучатели будут обязаны отчитываться о расходах перед государством, а сумма, выделяемая на один проект, составляет от 150 до 900 тысяч рублей ПМР.</p><br/>
        <p>После теоретической части в рамках интерактива участникам предложили сформировать инициативные группы и определиться с номинацией. Конкурс охватывает четырнадцать номинаций, среди которых семейная, демографическая и миграционная политика, содействие занятости населения, развитие социальной среды, общественное здоровье, физическая культура и спорт, сохранение окружающей среды, развитие культуры и искусства, туризм и т.д. Каждое из направлений дает возможность участникам проявить креативность и предложить действенные решения для решения актуальных социальных проблем.</p><br/>
        <p>Образовательный интерактив разделен на несколько этапов: знакомство и формирование команд, обзор заявок, представление и защита проектов. Курс продлится четыре недели, участники будут встречаться раз в неделю для обсуждения и отчета о своих достижениях.</p><br/>
        <p>Инициатива представляет собой значимый шаг на пути к активному вовлечению молодежи в социальные преобразования и реализацию идей, способствующих улучшению общественной жизни в республике.</p><br/>`
    },
    {
        id: 34,
        images: [start],
        h1: "Приглашаем всех студентов и преподавателей принять участие в образовательном интерактиве «Социальные гранты: от идеи проектов - до их реализации»!",
        p: "Александр Владимирович Березовский совместно с ЭФ запускает уникальную программу для развития ваших социальных проектов.",
        data: "18.10.2024",
        type: "СТУДЕНТАМ",
        link: "",
        about: `<p>Когда: Начало с 17 октября (четверг) в 15:00.<br/>
        Где: ЭФ 309 аудитория (для очной формы)<br/>
        Для кого: Студенты (с 18 лет) и преподаватели<br/>
        Что: 4-недельная интенсивная программа с практическими занятиями и поддержкой эксперта.</p>
        <br/>        
        <p>Регистрируйся и реализуй свой проект прямо сейчас!⬇️
        <a href="https://docs.google.com/forms/d/1SS9Wrhgqm0YXIT17enfaFQzTxKo4xvhlw4xnH2iaTWw/edit" target="_blank" style="text-decoration:none;">https://docs.google.com/forms/d/1SS9Wrhgqm0YXIT17enfaFQzTxKo4xvhlw4xnH2iaTWw/edit</a></p>`
    },
    {
        id: 33,
        images: [kur1, kur2, kur3, kur4, kur5, kur6, kur7, kur8],
        h1: "14 октября в актовом зале главного корпуса университета состоялся единый кураторский час",
        p: "",
        data: "18.10.2024",
        type: "СТУДЕНТАМ",
        link: "",
        about: `<p>14 октября в актовом зале главного корпуса университета состоялся единый кураторский час – первый из цикла, в рамках которого студенты и преподаватели  всех факультетов, институтов и филиалов будут просматривать видеозапись открытого урока на тему  «Великая Отечественная война 1941-1945 годов», который в свое время провёл для молодёжи Президент ПМР В.Н. Красносельский.  Тема кураторского часа – «Запрет на использование термина «Транснистрия» в качестве названия Республики». В ходе видеоурока  глава нашего государства подробно объясняет, почему это недопустимо по отношению к Приднестровью.</p><br/>
        <p>Заместитель декана по воспитательной работе <a href="http://spsu.ru/university/struct/econom" target="_blank" style="text-decoration:none;">экономического факультета</a> Д.В. Формусатий ознакомила присутствующих в зале студентов экономического и <a href="http://spsu.ru/university/struct/egf" target="_blank" style="text-decoration:none;">естественно-географического факультетов</a> с информацией о том, что Верховный Совет ПМР одобрил законодательную инициативу Президента ПМР о недопустимости использования термина «Транснистрия» в отношении Приднестровья и сразу в двух чтениях принял законопроекты о внесении изменений в Закон о противодействии экстремистской деятельности, Закон об увековечивании победы советского народа в Великой Отечественной войне и в Кодекс об административных правонарушениях.</p><br/>
        <p>В ходе открытого урока В.Н. Красносельский рассказал об основных вехах Великой Отечественной войны, её идеологических предпосылках,  ключевых сражениях и военачальниках, повлиявших на благополучный исход боевых действий. Президент остановился на подвиге блокадного  Ленинграда, битве под Москвой, Сталинградской и Курской битвах, о  наступательных операциях советских войск в 1944-1945 годах, приведших к победе над фашизмом. Затрагивались самые тяжёлые, связанные с войной темы: голод, концентрационные лагеря, бесчеловечные опыты нацистов над людьми, вклад молодых людей – вчерашних школьников и детей - в Победу. «Они были такими же, как вы: любили, мечтали, хотели жить, творить и созидать», – подчеркнул Вадим Николаевич, приведя примеры  подвигов героев Великой Отечественной войны, отмеченных высокими наградами и безымянных.</p><br/>
        <p>Один из таких героев – 20-летний старший сержант артиллерийских войск Николай Сиротинин. Во время Смоленского сражения, прикрывая отступление своего полка, в одиночку в одном бою уничтожил 11 танков, 7 бронемашин, 57 солдат и офицеров противника. За высочайшую степень отваги был похоронен с почестями немецкими солдатами. О его подвиге стало известно из дневника погибшего офицера вермахта. Несмотря на то, что подвиг Николая Сиротинина благодаря стараниям работников архива Советской Армии был признан еще в 1960 году, звание Героя Советского Союза ему присвоено не было. Помешало до боли нелепое обстоятельство: у семьи солдата не оказалось его фотографии. А она необходима для подачи документов на высокое звание. В год 20-летия Победы старший сержант Сиротинин был награжден Орденом Отечественной войны первой степени посмертно. «А сколько таких подвигов, о которых мы не узнаем», – заключил Президент.</p><br/>
        <p>Вадим Николаевич в ходе урока разъяснил, что «Губернаторство Транснистрия» было следствием румынской оккупации Одесской, Винницкой, Николаевской областей Украинской ССР и левобережной части Молдавской ССР во время Второй мировой войны. Оно было ликвидировано <a href="https://ru.wikipedia.org/wiki/%D0%A0%D0%B0%D0%B1%D0%BE%D1%87%D0%B5-%D0%BA%D1%80%D0%B5%D1%81%D1%82%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B0%D1%8F_%D0%9A%D1%80%D0%B0%D1%81%D0%BD%D0%B0%D1%8F_%D0%B0%D1%80%D0%BC%D0%B8%D1%8F" target="_blank" style="text-decoration:none;">советскими</a>  войсками в марте 1944 года <a href="https://ru.wikipedia.org/wiki/20_%D0%BC%D0%B0%D1%80%D1%82%D0%B0" target="_blank" style="text-decoration:none;">в ходе Уманско-Ботошанской  и Днепровско-Карпатской наступательных  операций</a>. Поэтому связанный с  массовым террором и геноцидом  мирного населения термин «Транснистрия» недопустим в отношении Приднестровской Молдавской Республики.</p><br/>
        <p>Николай Зинченко.</p>`
    },
    {
        id: 32,
        images: [posvash],
        h1: "Стоимость билета составит 5 рублей. Все вырученные средства пойдут на благотворительность",
        p: "",
        data: "18.10.2024",
        type: "СТУДЕНТАМ",
        link: ""
    },
    {
        id: 31,
        images: [dedication],
        h1: "1 октября в Культурно-просветительском центре им. святых равноапостольных Кирилла и Мефодия состоялась церемония посвящения первокурсников в студенты, приуроченная ко «Дню университета»",
        p: "",
        data: "01.10.2024",
        type: "СТУДЕНТАМ",
        link: "",
        about: `<p>1 октября в Культурно-просветительском центре им. святых равноапостольных Кирилла и Мефодия состоялась церемония посвящения первокурсников в студенты, приуроченная ко Дню университета. </p><br/>
        <p>Также на мероприятии наградили грамотами студенток нашего факультета, которые приняли участие в патриотическом Конкурсе медиа-материалов «За это я люблю Республику мою!»</p><br/>
        <p>Студентка 106 группы Лыкова Полина  заняла 3 место в номинации «Родины просторы».</p><br/>
        <p>Студентка 405 группы Добровольская Екатерина была награждена за участие  в номинации «Приднестровье: история которую я хочу рассказать».</p><br/>
        <p>Также была награждена студентка 108 группы Руссу Ксения  в  номинации  «Мы частичка России»</p><br/>
        <p>Поздравляем девушек !</p>`
    },
    {
        id: 30,
        images: [gramota2, gramota3, gramota4, gramota5, gramota6],
        h1: "Ученый совет  экономического факультета",
        p: "26 сентября 2024 года состоялся Ученый совет  экономического факультета.",
        data: "26.09.2024",
        type: "УЧЕНЫЙ СОВЕТ",
        link: "",
        about: `<p>Заседание началось с вручения Благодарственных писем Ректора ПГУ 4 студенткам ЭФ (Барба Анна, Григораш Алена, Кандит Валерия и Стерликова Ирина), которые  помогали в профориентационной работе в 2023-2024 г. </p><br/>
        <p>Агитационная работа с привлечением студентов - это отличный опыт прошлого года!</p><br/>`
    },
    {
        id: 29,
        images: [gramota],
        h1: "Легкоатлетический забег",
        p: "20 сентября состоялся легкоатлетический забег, приуроченный ко «Дню физической культуры и спорта Приднестровской Молдавской Республики»",
        data: "20.09.2024",
        type: "СПОРТ",
        link: ""
    },
    {
        id: 28,
        images: [student, student1, student2, student4, student5, student7, student8, student9, student10, student11, student12, student13],
        h1: "От студенчества к профессиональной деятельности",
        p: "Вручение дипломов выпускникам экономического факультета прошло 20 июля в Культурно-просветительском центре ПГУ.",
        data: "21.07.2024",
        type: "СТУДЕНТАМ",
        link: ""
    },
    {
        id: 27,
        images: [photo, best1, best2, best3, best4, best5, best6, best7, best8, best9, best10, best11, best12, best13],
        h1: "Названы лучшие студенты экономического факультета",
        p: "18 июня в актовом зале главного корпуса состоялось подведение итогов научно-исследовательской работы студентов экономического факультета за 2023-2024 учебный год.",
        data: "01.07.2024",
        type: "НАУКА СТУДЕНЧЕСКАЯ БИЗНЕС-ШКОЛА",
        link: ""
    },
    {
        id: 26,
        images: [photo_2],
        h1: "Порядок отбора кандидатов на получение государственной стипендии Президента Приднестровской Молдавской Республики",
        p: "",
        data: "19.06.2024",
        type: "",
        link: ""
    },
    {
        id: 25,
        images: [photo_3],
        h1: "Ежегодная церемония подведения итогов научной работы студентов экономического факультета ГОУ «ПГУ им. Т.Г. Шевченко» за 2023-2024 уч. год",
        p: "18 июня в 13:00 состоится ежегодная церемония  подведения итогов научной работы студентов экономического факультета ГОУ «ПГУ им. Т.Г. Шевченко» за 2023-2024 уч. год",
        data: "14.06.2024",
        type: "СТУДЕНТАМ",
        link: ""
    },
    {
        id: 24,
        images: [File],
        h1: "Бесплатные курсы по Технологическому предпринимательству и E-commerce",
        p: "Уважаемые друзья! Мы рады пригласить вас на бесплатные курсы по Технологическому предпринимательству и E-commerce!",
        data: "12.06.2024",
        type: "",
        link: ""
    },
    {
        id: 23,
        images: [photo_4],
        h1: "Экономический факультет Приднестровского государственного университета объявляет набор абитуриентов в 2024 году",
        p: "Прием документов на обучение по программам высшего и дополнительного профессионального образования проводится с 17 июня по 20 июля 2024 года.",
        data: "08.06.2024",
        type: "АБИТУРИЕНТ",
        link: ""
    },
    {
        id: 22,
        images: [image, round1, round2, round3, round4, round5, round6, round7, round8, round9, round10, round11, round12, round13, round14, round15],
        h1: "Круглый стол на экономическом факультете",
        p: "На кафедре экономики и менеджмента экономического факультета 30 мая состоялся круглый стол «Основные макроэкономические показатели в Приднестровской Молдавской Республике и формирование мер по их позитивному изменению».",
        data: "31.05.2024",
        type: "НАУКА",
        link: ""
    },
    {
        id: 21,
        images: [sovet],
        h1: "Информация о решениях Ученого совета экономического факультета – 30 мая 2024г",
        p: "",
        data: "30.05.2024",
        type: "УЧЕНЫЙ СОВЕТ",
        link: ""
    },
    {
        id: 20,
        images: [photo_5, rally1, rally2, rally3, rally4, rally5, rally6],
        h1: "Наши студенты приняли участие на слёте Весна Победы-2024",
        p: "В лагере «Сэнэтате» в Кицканском лесу 24 мая  прошёл гражданско-патриотический спортивный слёт «Весна Победы - 2024». Его участниками стали 11 студенческих команд Приднестровского госуниверситета им. Т. Г. Шевченко.",
        data: "28.05.2024",
        type: "СПОРТ",
        link: ""
    },
    {
        id: 19,
        images: [image_1, glebov1, glebov2, glebov3, glebov4, glebov5, glebov6, glebov7, glebov8, glebov9, glebov10, glebov11, glebov12, glebov13, glebov14, glebov15],
        h1: "Виталий Иванович Глебов: тридцать лет служения Приднестровью",
        p: "16 мая в конференц-зале главного корпуса ПГУ состоялась презентация книги воспоминаний о Виталии Ивановиче Глебове – выдающемся общественном деятеле Приднестровья, стоявшем у истоков создания республики.",
        data: "19.05.2024",
        type: "",
        link: ""
    },
    {
        id: 18,
        images: [photo_6, victory1, victory2, victory3, victory4, victory5, victory6, victory7, victory8, victory9],
        h1: "С Днём Победы!",
        p: "9 мая на площадке перед главным корпусом университета состоялся митинг-концерт в честь Дня Победы.",
        data: "09.05.2024",
        type: "",
        link: ""
    },
    {
        id: 17,
        images: [victory],
        h1: "Наши студенты приняли участие в проекте «Я ПОМНЮ, Я ГОРЖУСЬ!»",
        p: "7 мая наши студенты приняли участие в уборке могил участников ВОВ в рамках проекта «Я ПОМНЮ, Я ГОРЖУСЬ!».",
        data: "07.05.2024",
        type: "ВОЛОНТЕР",
        link: ""
    },
    {
        id: 16,
        images: [spring],
        h1: "С праздником весны и труда!",
        p: "Уважаемые преподаватели, сотрудники, студенты и аспиранты Приднестровского государственного университета им. Т.Г. Шевченко! Сердечно поздравляю вас с прекрасным праздником весны, труда и созидания!",
        data: "01.05.2024",
        type: "",
        link: ""
    },
    {
        id: 15,
        images: [rallyEc, rallyEc1, rallyEc2, rallyEc3, rallyEc4, rallyEc5, rallyEc6, rallyEc7],
        h1: "24 апреля состоялся IV Республиканский слет «Наш дом – Эконом».",
        p: "В слете приняли участие студенты и преподаватели экономического факультета, а также выпускники факультета, члены Молодежного парламента.",
        data: "29.04.2024",
        type: "СТУДЕНТАМ НАШ ДОМ - ЭКОНОМ",
        link: ""
    },
    {
        id: 14,
        images: [relay, relay1, relay2],
        h1: "Студенты нашего факультета приняли участие в эстафете",
        p: "12 апреля состоялась 75-я городская традиционная легкоатлетическая эстафета, посвященная 80-летию со дня освобождения Тирасполя от фашистской оккупации.",
        data: "15.04.2024",
        type: "СПОРТ",
        link: ""
    },
    {
        id: 13,
        images: [volonteer, volonteer1, volonteer2, volonteer3, volonteer4],
        h1: "Студенты участвовали в субботнике",
        p: "В воскресенье экономический факультет пригласили в Республиканский специализированный дом ребенка, на проведение субботника, целью которого было обновить краску на детской площадке и перилах здания.",
        data: "15.04.2024",
        type: "ВОЛОНТЕР",
        link: ""
    },
    {
        id: 12,
        images: [applicant],
        h1: "Приднестровский государственный университет им. Т.Г. Шевченко проводит предварительное тестирование для желающих поступить в университет.",
        p: "",
        data: "08.04.2024",
        type: "АБИТУРИЕНТ",
        link: ""
    },
    {
        id: 11,
        images: [homeland, homeland1, homeland2],
        h1: "Прошёл кураторский час на тему: «Малая Родина»",
        p: "Сегодня , 25 марта , в Актовом зале прошёл кураторский час на тему: «Малая Родина», приуроченный к трагическим событиям, произошедшим 2 марта 1992 года - дню начала отражения широкомасштабной агрессии РМ против ПМР.",
        data: "25.03.2024",
        type: "СТУДЕНТАМ",
        link: ""
    },
];

const News = () => {
    return newsData;
}

export default News;