import chelovskaya from "./../../img/finIkredit/chelovskaya.jpg";
import kornanu from "./../../img/finIkredit/kornanu.jpg";
import izmest from "./../../img/finIkredit/izmest.png";
import kirika from "./../../img/finIkredit/kirika.jpg";
import safronova from "./../../img/finIkredit/safr.jpg";
import mazur from "./../../img/finIkredit/mazur.jpg";
import safronov from "./../../img/finIkredit/safronov.jpg";
import grosul from "./../../img/finIkredit/grosul.jpg";
import gushkan from "./../../img/finIkredit/gushkan.jpg";
import undefined from "./../../img/finIkredit/undefined.png";
import vasilatii from "./../../img/finIkredit/vasilat.jpg";
import stratulat from "./../../img/finIkredit/stratulat.jpg";
import uzun from "./../../img/ekTeorImirec/uzun.jpg";
import labunskii from "./../../img/ekTeorImirec/Labunskii.png";
import dorofeeva from "./../../img/ekTeorImirec/Dorofeeva.png";
import brinza from "./../../img/ekTeorImirec/Brinza.png";
import mironenko from "./../../img/ekTeorImirec/Mironenko.png";
import zatika from "./../../img/ekTeorImirec/Zatika.png";
import kurpatenko from "./../../img/ekTeorImirec/Kurpatenko.png";
import makarovaD from "./../../img/ekTeorImirec/MakarovaD.png";
import chebanenko from "./../../img/ekTeorImirec/Chebanenko.png";
import lyu from "./../../img/BIiMME/lyu.png";
import bosuk from "./../../img/decan1.png";
import solomatina from "./../../img/BIiMME/solomatina.png";
import dorosh from "./../../img/BIiMME/dorosh.png";
import malaxova from "./../../img/BIiMME/malax.png";
//import safronova from "./../../img/EiM/Safronova.jpg";
import Grebeniuk from "./../../img/EiM/Grebeniuk.jpg";
import Pavlin from "./../../img/EiM/Pavlin.png";
import Ionku from "./../../img/EiM/Ionku.jpg";
import Kemel from "./../../img/EiM/Kemel.jpg";
import Krivizkaia from "./../../img/EiM/Krivizkaia.jpg";
import Malarenko from "./../../img/EiM/Malarenko.jpg";
import Markov from "./../../img/EiM/Markov.jpg";
import Moroz from "./../../img/EiM/Moroz.jpg";
import Ponurkevich from "./../../img/EiM/Ponurkevich.jpg";
import Savenko from "./../../img/EiM/Savenko.jpeg";
import Sirotenko from "./../../img/EiM/Sirotenko.jpg";
import smolenskii from "./../../img/smolenskii.png";

import stasuk_2 from "./../../img/BuchUch/stasuk_2.png";
import burduga from "./../../img/BuchUch/burduga.png";
import dmitrieva from "./../../img/BuchUch/dmitrieva.jpg";
import gigareva from "./../../img/BuchUch/gigareva.jpg";
import zelenin from "./../../img/BuchUch/zelenin.jpg";
import laskovskaya from "./../../img/BuchUch/laskovskaya.jpg";
import lashkova from "./../../img/BuchUch/lashkova.png";
import muraveva from "./../../img/BuchUch/muraveva.jpg";
import murahovskaya from "./../../img/BuchUch/murahovskaya.jpg";
import pasichnik from "./../../img/BuchUch/pasichnik.jpg";
import pochtar from "./../../img/BuchUch/pochtar.jpg";
import prodius from "./../../img/BuchUch/prodius.jpg";
import tanasenko from "./../../img/BuchUch/tanasenko.jpg";
import trushin from "./../../img/BuchUch/trushin.jpg";
import formusatii from "./../../img/BuchUch/formusatii.jpg";
import curkan from "./../../img/BuchUch/curkan.jpg";

const listDep = [
    {
        departmentTitle:"Кафедра финансов и кредита",
        employees:[
            {
                id:1,
                image:kornanu,
                fio:"Корняну Елена Владимировна",
                strucunit:"Кафедра финансов и кредита",
                jobtitle:"Старший преподаватель, без ученой степени, без учёного звания",
                listdiscip:"«Организация и методика проведения налоговых проверок»; «Правовое регулирование налоговых отношений»; «Налоговое производство»"
            },
            {
                id:2,
                image:izmest,
                fio:"Изместьева Светлана Юрьевна",
                jobtitle:"Доцент, без ученой степени, без учёного звания",
                listdiscip:"«Налогообложение»; «Налоги и налогообложение»; «Налоговое право»; «Налогообложение организаций»"
            },
            {
                id:3,
                image:safronova,
                fio:"Сафронова Людмила Михайловна",
                jobtitle:"Доцент, кандидат экономических наук",
                listdiscip:"«Банковский менеджмент»; «Зарубежные финансовые рынки»; «Инвестиционное кредитование»; «Международные производные финансовые инструменты»; «Международный рынок инвестиций»; «Международный банковский бизнес»; «Организованный рынок ценных бумаг»; «Производные финансовые инструменты»; «Рынок ценных бумаг»; «Система косвенных налогов»; «Страхование и страховые риски в международных экономических отношениях»; «Ценообразование на информационном рынке»; «Ценообразование»; «Дивидендная политика организации»; «Мировой опыт финансирования корпораций»"
            },
            {
                id:4,
                image:kirika,
                fio:"Кирика Наталья Дмитриевна",
                jobtitle:"Старший преподаватель, без ученой степени, без учёного звания",
                listdiscip:"«Налоги с юридических и физических лиц»; «Налоговое администрирование и контроль»; «Современные подходы при осуществлении налогового администрирования»"
            },
            {
                id:5,
                image:chelovskaya,
                fio:"Человская Екатерина Ивановна",
                jobtitle:"Доцент",
                listdiscip:"«Банковские платежные системы»; «Банковское дело»; «Деньги, кредит, банки»; «Развитие надзора за деятельностью финансово- кредитных институтов»; «Современная инфраструктура кредитной системы»; «Управление рисками валютных, кредитных и финансовых операций»; «Учет и операционная деятельность в банках»; «Международная практика валютных и финансовых операций в банках»; «Банковские платежные системы»; «Новации в развитии национальной платежной системы»; «Международная практика управления рисками валютных, кредитных и финансовых операций»."
            },
            {
                id:6,
                image:mazur,
                fio:"Мазур Елена Сергеевна",
                jobtitle:"Доцент, кандидат экономических наук, доцент",
                listdiscip:"«Банковский бизнес»; «Финансовый анализ (продвинутый уровень)»; «Банковское законодательство»; «Валютное регулирование и валютный контроль»; «Корпоративное управление»; «Корпоративные стратегии»; «Международные валютно-кредитные отношения»; «Международный банковский бизнес»; «Международный финансовый менеджмент»; «Методика и методология научного исследования»; «Оценка стоимости бизнеса»; «Финансовые рынки и финансово-кредитные институты»; «Финансовый механизм государственных закупок»; «Документооборот в финансово-экономической деятельности»; «Управление стоимостью компании»; «Финансовые рынки»; «Реорганизация бизнеса: слияния и поглощения»; «Особенности анализа в сегментах бизнеса»; «Информационная безопасность и электронный документооборот в финансовой отрасли»; «Современные методики анализа финансовых рынков»"
            },
            {
                id:7,
                image:safronov,
                fio:"Сафронов Юрий Михайлович",
                jobtitle:"Заведующий кафедрой, кандидат экономических наук, доцент",
                listdiscip:"«Деньги. Кредит. Банки»; «Организация денежно-кредитного регулирования»; «Организация деятельности центрального банка и оценка деятельности коммерческого банка»; «Современные финансовые отношения»; «Современные концепции финансов и кредита»; «Финансовые и денежно-кредитные методы регулирования экономики»; «Валютно-финансовые системы развитых и развивающихся стран»"
            },
            {
                id:8,
                image:grosul,
                fio:"Гросул Наталья Сергеевна",
                jobtitle:"Старший преподаватель, без ученой степени, без учёного звания",
                listdiscip:"«Бизнес-аналитика»; «Бюджетная система ПМР»; «Введение в бизнес-анализ»; «Корпоративные финансы»; «Корпоративные финансы (продвинутый уровень)»; «Маркетинг в коммерческом банке»; «Моделирование банковской и финансовой деятельности»; «Налогообложение финансово-кредитных институтов»; «Страхование»; «Корпоративное страхование»;  «Финансы бюджетных организаций»; «Финансы организаций»; «Количественные методы корпоративных финансов»; «Бизнес-прогнозирование и финансовое моделирование»; «Управление внешним долгом»"
            },
            {
                id:9,
                image:gushkan,
                fio:"Гушкан Ирина Николаевна",
                jobtitle:"Старший преподаватель, без ученой степени, без учёного звания",
                listdiscip:"«Налоги и налогообложение»"
            },
            {
                id:10,
                image:undefined,
                fio:"Есир Алла Ивановна",
                jobtitle:"Старший преподаватель, без ученой степени, без учёного звания",
                listdiscip:"«Долгосрочная и краткосрочная финансовая политика»; «Инвестиции»; «Инвестиционная стратегия»; «Инвестиционный анализ»; «Краткосрочная и долгосрочная финансовая политика»; «Финансовая среда организации»; «Финансовая среда предпринимательства и предпринимательские риски»; «Международное инвестиционное кредитование»; «Финансово-инвестиционные расчеты»; «Финансовые расчеты для нефинансовых и финансовых организаций»; «Финансовый менеджмент»"
            },
            {
                id:11,
                image:undefined,
                fio:"Кискул Ольга Александровна",
                jobtitle:"Старший преподаватель, без ученой степени, без учёного звания",
                listdiscip:"«Введение в профессиональную деятельность»; «Финансы, денежное обращение и кредит»; «Финансы»; «Публичные финансы: мировой опыт»; «Финансы и финансовые операции в общественном секторе»; «Финансы и кредит»"
            },
            {
                id:12,
                image:undefined,
                fio:"Зинченко Григорий Сергеевич",
                jobtitle:"Старший преподаватель, без ученой степени, без учёного звания",
                listdiscip:"«Теневая экономика»"
            },
            {
                id:13,
                image:vasilatii,
                fio:"Василатий Олег Валерьевич",
                jobtitle:"Старший преподаватель, без ученой степени, без учёного звания",
                listdiscip:"«Основы взаимодействия государства и бизнеса»; «Бюджетная система и бюджетный процесс в ПМР»"
            },
            {
                id:14,
                image:undefined,
                fio:"Колесник Яна Андреевна",
                jobtitle:"Специалист, без ученой степени, без учёного звания",
                listdiscip:""
            },
            {
                id:15,
                image:stratulat,
                fio:"Стратулат Симона Эдуардовна",
                jobtitle:"Специалист, без ученой степени, без учёного звания",
                listdiscip:""
            },
        ]
    },
    {
        departmentTitle:"Кафедра экономической теории и мировой экономики",
        employees: [
            {
                id:16,
                image:uzun,
                fio:"Узун Иван Николаевич",
                jobtitle:"Доцент, кандидат экономических наук",
                listdiscip:"«Микроэкономика»; «Макроэкономика»; «Экономика и основы финансовой грамотности»; «Микроэкономика (продвинутый курс)»; «Международные валютно-финансовые отношения (продвинутый курс)»; «Методика и методология научного исследования»"
            },
            {
                id:17,
                image:labunskii,
                fio:"Лабунский Владимир Владимирович",
                jobtitle:"Доцент, кандидат с/х наук",
                listdiscip:"«Финансово-экономический практикум»; «Экономика и основы финансовой грамотности»; «Финансово-экономический практикум»; «Мировая экономика и международные экономические отношения»; «Внешнеэкономическая политика и внешнеэкономическая деятельность государства»; «Внешнеэкономическая деятельность предприятия»; «Современные проблемы и тенденции в МЭО»"
            },
            {
                id:18,
                image:dorofeeva,
                fio:"Дорофеева Любовь Васильевна",
                jobtitle:"Доцент, кандидат экономических наук",
                listdiscip:"«Экономика и основы финансовой грамотности»; «Современные проблемы и тенденции в МЭО»; «Мировая торговля»; «Международный бизнес»; «Финансово-экономический практикум»; «Налоги и налоговые системы»; «Национальные налоговые системы ведущих стран мира»; «Экономика государственного и муниципального сектора»; «Стратегия ценообразования на мировых рынках»"
            },
            {
                id:19,
                image:brinza,
                fio:"Брынза Елена Викторовна",
                jobtitle:"Старший преподаватель",
                listdiscip:"«Экономика и основы финансовой грамотности»; «Микроэкономика»; «Введение в профессиональную деятельность»; «Экономическая теория»; «Макроэкономика»; «Институциональная экономика»"
            },
            {
                id:20,
                image:mironenko,
                fio:"Мироненко Екатерина Николаевна",
                jobtitle:"Старший преподаватель",
                listdiscip:"«Экономика и основы финансовой грамотности»; «Финансово-экономический практикум»; «Международный финансовый рынок»; «Организация и техника внешнеторговых операций»; «Международные фондовые и биржевые операции»"
            },
            {
                id:21,
                image:zatika,
                fio:"Затыка Светлана Владимировна",
                jobtitle:"Преподаватель",
                listdiscip:"«Микроэкономика»; «Макроэкономика»;  «»; «»; «»; «»; «»; «»"
            },
            {
                id:22,
                image:kurpatenko,
                fio:"Курпатенко Екатерина Владимировна",
                jobtitle:"Старший преподаватель",
                listdiscip:"«Экономика и основы финансовой грамотности»; «»; «»; «»; «»; «»; «»"
            },
            {
                id:23,
                image:undefined,
                fio:"Кротенко Юрий Иванович",
                jobtitle:"Профессор, доктор экономических наук",
                listdiscip:"«Основы цифровой экономики»; «Экономика стран и регионов»; «Региональные аспекты международного бизнеса»; «Цифровая экономика»"
            },
            {
                id:24,
                image:undefined,
                fio:"Савенко Лариса Васильевна",
                jobtitle:"Доцент, кандидат экономических наук",
                listdiscip:"«Моделирование макроэкономики»; «Макроэкономика (продвинутый курс)»"
            },
            {
                id:25,
                image:undefined,
                fio:"Единак Игорь Валентинович",
                jobtitle:"Старший преподаватель",
                listdiscip:"«Международное коммерческое право»; «Международно-правовой порядок урегулирование международных экономических споров»"
            },
            {
                id:26,
                image:undefined,
                fio:"Параскева Сергей Петрович",
                jobtitle:"Старший преподаватель",
                listdiscip:"«Таможенное дело»; «Таможенное регулирование в международной торговле»"
            },
            {
                id:27,
                image:undefined,
                fio:"Зинченко Григорий Сергеевич",
                jobtitle:"Старший преподаватель",
                listdiscip:"«Налоги и налоговые системы»; «Экономика   и основы финансовой грамотности»; «Национальные модели экономического развития»"
            },
            {
                id:28,
                image:makarovaD,
                fio:"Макарова Дарья Вадимовна",
                jobtitle:"Специалист кафедры",
                listdiscip:""
            },
            {
                id:29,
                image:chebanenko,
                fio:"Чебаненко Анна Анатольевна",
                jobtitle:"Старший лаборант кафедры",
                listdiscip:""
            },
            {}
        ]
    },
    {
        departmentTitle:"Кафедра бизнес-информатики и математических методов в экономике",
        employees: [
            {
                id:30,
                image:lyu,
                fio:"Надькин Леонид Юрьевич",
                jobtitle:"Доцент, кандидат физико-математических наук",
                listdiscip:"«Автоматизированные информационные системы бухгалтерского учета»; «Объектно-ориентированные анализ и программирование»; «Web программирование (back-end)»; «Торговые роботы»; «Программная инженерия проектов»; «Проектирование управлениями базами данных»; «Программирование в системе 1С предприятие»"
            },
            {
                id:31,
                image:bosuk,
                fio:"Босюк Виктор Николаевич",
                jobtitle:"Профессор, доктор экономических наук",
                listdiscip:"«Эконометрика»; «Экономико-математические методы прикладных исследований»; «Методы оптимальных решений»; «Финансовые вычисления»; «Web программирование (back-end)»; «Экономико-математические методы прикладных исследований»; «Информационные системы в экономике»"
            },
            {
                id:32,
                image:solomatina,
                fio:"Саломатина Елена Васильевна",
                jobtitle:"Доцент, кандидат технических наук",
                listdiscip:"«Основы искусственного интеллекта»; «Архитектура предприятия»; «WEB-дизайн»; «Электоронный бизнес»; «Системы управления проектами»; «Эффективность ИТ»"
            },
            {
                id:33,
                image:malaxova,
                fio:"Малахова Мария Владимировна",
                jobtitle:"Старший преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Введение в профессиональную деятельность»; «Программирование»; «Информатика»; «Теоретические основы информатики»; «Информационная безопасность и электронный документооборот в финансовой отрасли»; «Защита компьютерной информации»; «Информационные технологии в профессиональной деятельности и безопасность электронного документооборота»; «Информационные технологии в экономике»; «Базы данных»; «Информационные системы в экономике»; «Информационная безопасность в профессиональной деятельности»"
            },
            {
                id:34,
                image:dorosh,
                fio:"Дорошенко Анна Владимировна",
                jobtitle:"Старший преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Информатика»; «Экономическая информатика»; «Информационные технологии в менеджменте»; «Корпоративные информационные системы»; «Рынки ИКТ и организация продаж»; «Стандартизация, сертификация и управление качеством программного обеспечения»; «Управление жизненым циклом ИС»; «Методы и программный инструментарий аналитических данных»; «Стратегии электронного бизнеса»; «Информационные системы управления эффективностью бизнеса»; «Информационные технологии»; «Web программирование (back-end)»; «Информационные системы в экономике»"
            },
            {
                id:35,
                image:undefined,
                fio:"Джалая Лариса Васильевна",
                jobtitle:"Преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Моделирование производственно-коммерческой деятельности»"
            },
            {
                id:36,
                image:undefined,
                fio:"Гощина Наталья Николаевна",
                jobtitle:"Преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Деловые коммуникации в сфере информационных технологий»; «Основы разработки мобильных приложений»; «Системы искусственного интеллекта»; «Web программирование (back-end)»; «Программирование мобильных устройств»"
            },
            {
                id:37,
                image:undefined,
                fio:"Зинган Анна Петровна",
                jobtitle:"Доцент, кандидат физико-математических наук",
                listdiscip:"«Математика в экономике»"
            },
            {
                id:38,
                image:undefined,
                fio:"Косюк Наталья Валерьевна",
                jobtitle:"Старший преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Математика в экономике»"
            },
            {
                id:39,
                image:undefined,
                fio:"Ляхомская Ксения Даниловна",
                jobtitle:"",
                listdiscip:"«Дифференциальные и разностные уравнения»"
            },
            {
                id:40,
                image:undefined,
                fio:"Ноур Олег Радович",
                jobtitle:"Преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«WEB - программирование»"
            },
            {
                id:41,
                image:undefined,
                fio:"Старчук Татьяна Ивановна",
                jobtitle:"Старший преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Теория вероятностей и математическая статистика»; «Математическое моделирование в экономике»; «Управление ИТ-сервисами и контентом»"
            },
            {
                id:42,
                image:undefined,
                fio:"Почтарь Олег Владимирович",
                jobtitle:"Преподаватель, без ученой степени, без ученого звания",
                listdiscip:"Консалтинг в IT сфере"
            },
            {
                id:43,
                image:undefined,
                fio:"Охомуш Светлана Алексеевна",
                jobtitle:"Специалист кафедры",
                listdiscip:""
            },
            {
                id:44,
                image:undefined,
                fio:"Юрковская Людмила Алексеевна",
                jobtitle:"Специалист-инженер-программист",
                listdiscip:""
            },
            {}
        ]
    },
    {
        departmentTitle:"Кафедра экономики и менеджмента",
        employees: [
            {
                id:45,
                image:safronova,
                fio:"Сафронова Людмила Михайловна",
                jobtitle:"Доцент, кандидат экономических наук",
                listdiscip:"Руководство соискателями и аспирантами"
            },
            {
                id:46,
                image:bosuk,
                fio:"Босюк Виктор Николаевич",
                jobtitle:"Профессор, доктор экономических наук",
                listdiscip:"Руководство соискателями и аспирантами"
            },
            {
                id:47,
                image:undefined,
                fio:"Джалая Лариса Васильевна",
                jobtitle:"Преподаватель, специалист кафедры",
                listdiscip:"«Бизнес-аналитика»; «Командообразование»; «Проектный менеджмент»; «Корпоративная социальная ответственность»; «Организация и технология документационного обеспечения управления»; «Макроэкономическое планирование и прогнозирование»; «Проектный менеджмент на производстве»; «Управление организацией и бизнесом»"
            },
            {
                id:48,
                image:undefined,
                fio:"Бурдюжа Наталья Владимировна",
                jobtitle:"Старший преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Бухгалтерский учет и финансовая отчетность»"
            },
            {
                id:49,
                image:undefined,
                fio:"Голубь Наталья Александровна",
                jobtitle:"Доцент, кандидат экономических наук",
                listdiscip:"«Маркетинг  и менеджмент»; «Экономика в профессиональной сфере»; «Управление персоналом»; «Маркетинг в сфере ФКиС»; «Управление развитием  бизнеса»; «Отраслевые рынки: исследования и оценка состояния»; «Бизнес планирование деятельности медицинской организации»; «Управление человеческими ресурсами в здравоохранении»"
            },
            {
                id:50,
                image:Grebeniuk,
                fio:"Гребенюк Светлана Анатольевна",
                jobtitle:"Доцент",
                listdiscip:"«Управленческие решения»; «Методы принятия управленческих решений»; «Основы бизнеса»; «Государственное регулирование деятельности предприятий»; «Стратегический менеджмент и стратегическое планирование»; «Государственное регулирование национальной экономики»; «Государственное регулирование АПК»; «Государственное регулирование экономики»; «Правовое обеспечение профессиональной деятельности»; «Управленческая экономика»; «Современный стратегический анализ»; «Теория и механизмы государственного управления»; «Стратегический менеджмент»; «Экономическая и финансовая деятельность организаций здравоохранения»; «Механизм управления регионом»; «Региональная и отраслевая экономика»"
            },
            {
                id:51,
                image:Pavlin,
                fio:"Павлин Евгения Васильевна ",
                jobtitle:"Старший преподаватель, зам. декана по учебно-методической работе",
                listdiscip:"«Маркетинг»; «Инновационный менеджмент»"
            },
            {
                id:52,
                image:Ionku,
                fio:"Ионку Олег Юрьевич",
                jobtitle:"Доцент",
                listdiscip:"«Маркетинговые стратегии в международном бизнесе»; «Процесс создания продукта»; «Управление сбытом и сервисом промышленного предприятия»"
            },
            {
                id:53,
                image:Kemel,
                fio:"Кеммель Любовь Владимировна",
                jobtitle:"Старший преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Бизнес-планирование»; «Управление затратами в отраслях»; «Технико-экономическое обоснование проектной деятельности предприятий»; «Оценка стоимости имущественного комплекса»; «Планирование в здравоохранении»; «Организация и экономика здравоохранения»; «Управление частной медицинской организацией»"
            },
            {
                id:54,
                image:Krivizkaia,
                fio:"Кривицкая-Шолпан Оксана Валерьевна",
                jobtitle:"Старший преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Правовое обеспечение государственного и частного здравоохранения»"
            },
            {
                id:55,
                image:undefined,
                fio:"Кротенко Юрий Иванович ",
                jobtitle:"Профессор, доктор экономических наук",
                listdiscip:"«Бизнес-планирование»"
            },
            {
                id:56,
                image:kurpatenko,
                fio:"Курпатенко Екатерина Владимировна",
                jobtitle:"Старший преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Экономика, организация и управление сельскохозяйственным производством»; «Организация и управление предприятиями»; «Экономика организации»; «Экономика недвижимости и предприятий»; «Управление проектами»; «Управление персоналом»; «Антикризисное управление»; «Мотивационный менеджмент»; «Мотивация трудовой деятельности в АПК»"
            },
            {
                id:57,
                image:labunskii,
                fio:"Лабунский Владимир Владимирович",
                jobtitle:"Доцент, кандидат сельскохозяйственных наук",
                listdiscip:"«Экономическая теория»"
            },
            {
                id:58,
                image:Malarenko,
                fio:"Маляренко Лина Павловна",
                jobtitle:"Старший преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Планирование и прогнозирование деятельности предприятий»"
            },
            {
                id:59,
                image:Markov,
                fio:"Марков Дмитрий Александрович",
                jobtitle:"Доцент, кандидат физико-математичнских наук",
                listdiscip:"«Информационно-аналитические технологии в здравоохранении»"
            },
            {
                id:60,
                image:Moroz,
                fio:"Мороз Наталья Андреевна",
                jobtitle:"Старший преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Экономика фирмы»; «Экономика организации и внутрифирменное бюджетирование»; «Экономика отраслей АПК»; «Прогнозирование и планирование в АПК»"
            },
            {
                id:61,
                image:Ponurkevich,
                fio:"Понуркевич Елена Александровна",
                jobtitle:"Старший преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Антикризисное управление»"
            },
            {
                id:62,
                image:Savenko,
                fio:"Савенко Лариса Васильевна",
                jobtitle:"Доцент, кандидат экономических наук",
                listdiscip:"«Антикризисное управление»"
            },
            {
                id:63,
                image:Sirotenko,
                fio:"Сиротенко Галина Александровна",
                jobtitle:"Старший преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Теория менеджмента»; «Менеджмент»; «Теория организации»; «Теория менеджмента»; «Сравнительный менеджмент»; «Производственный менеджмент»; «Инвестиционный менеджмент в производственной сфере»"
            },
            {
                id:64,
                image:smolenskii,
                fio:"Смоленский Николай Николаевич",
                jobtitle:"Доцент, кандидат экономических наук",
                listdiscip:"«Введение в профессиональную деятельность»; «Экономика труда»; «Управление в АПК»; «Методика и методология научного исследования»"
            },
            {
                id:65,
                image:Savenko,
                fio:"Струнгар Алла Николаевна",
                jobtitle:"Старший преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Экономика и организация деятельности экономических агентов, отраслей и территорий»; «Организация производства»; «Логистика»; «Логистика и управление цепями поставок»; «Маркетинг»; «Управление изменениями и реструктуризация бизнеса»; «Организация производства на предприятиях АПК»; «Теория организации»; «Организация предпринимательской деятельности в АПК»; «Теория организации и организационное поведение»"
            },
        ]
    },
    {
        departmentTitle:"Кафедра бухгалтерского учета и аудита",
        employees: [
            {
                id:66,
                image:stasuk_2,
                fio:"Стасюк Татьяна Петровна",
                jobtitle:"Заведующая кафедрой «Бухгалтерский учет и аудит», доцент, кандидат экономических наук",
                listdiscip:"«Бухгалтерский учет»; «Лабораторный практикум по бухгалтерскому учету», «Бухгалтерский управленческий учет», «Аудит», «Учет затрат и калькулирование в отраслях производства», «Международные стандарты учета и финансовой отчетности», «Бухгалтерское дело»; «Особенности отраслевого учета», Управленческий учет (продвинутый курс), «Финансовый консалтинг», «Внутренний аудит»."
            },
            {
                id:67,
                image:burduga,
                fio:"Бурдюжа Наталья Владимировна",
                jobtitle:"Старший преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Аудит в государственных учреждениях», «Бюджетный учет и отчетность», «Бухгалтерский учет в бюджетных организациях», «Комплексный экономический анализ хозяйственной деятельности», «Экономический анализ»"
            },
            {
                id:68,
                image:undefined,
                fio:"Глодя Дмитрий Борисович",
                jobtitle:"Старший преподаватель, без учено степени, без ученого звания",
                listdiscip:"«Преступления в сфере экономической деятельности», «Спецкурс: «Основы оперативно-розыскной деятельности в экономической сфере»"
            },
            {
                id:69,
                image:dmitrieva,
                fio:"Дмитриева Наталья  Николаевна",
                jobtitle:"Старший преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Экономический анализ», «Комплексный экономический анализ хозяйственной деятельности», «Инвестиционный анализ», «Инвестиционный и инновационный анализ», «Управленческий анализ в отраслях экономики», «Финансовый анализ консалтинговой деятельности», «Анализ и оценка инновационных проектов», «Анализ хозяйственной деятельности предприятий АПК», «Методы и модели финансово-экономического планирования»"
            },
            {
                id:70,
                image:gigareva,
                fio:"Жигарева Елена Леонидовна",
                jobtitle:"Старший преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Экономический анализ»; «Учет, анализ и аудит внешнеэкономической деятельности»; «Учет и анализ банкротств»; «Оценка рисков»; «Управленческий анализ в отраслях (продвинутый уровень)»; «Учет, анализ и аудит в период антикризисного управления»"
            },
            {
                id:71,
                image:zelenin,
                fio:"Зеленин Николай Валерьевич",
                jobtitle:"Старший преподаватель высшей категории, без ученой степени, без ученого звания",
                listdiscip:"«Деловое общение и документационное обеспечение управленческой деятельности»; «Документационное обеспечение управления»; «Бухгалтерский учет и финансы в АПК»; «Конкурентная разведка»; «Профессиональная этика и служебный этикет»; «Информационная безопасность в профессиональной деятельности»; «Профессиональное суждение бухгалтера и аудитора»"
            },
            {
                id:72,
                image:laskovskaya,
                fio:"Лясковская Елена Игоревна",
                jobtitle:"Старший преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Контроль и ревизия»; «Экономический анализ»"
            },
            {
                id:73,
                image:lashkova,
                fio:"Ляшкова Елена Анатольевна",
                jobtitle:"Старший преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Аудиторское право»; «Бухгалтерский учет»; «Бухгалтерский управленческий учет»; «Ознакомительная практика»; «Особенности учета в отраслях экономики»; «Отраслевая статистика»; «Статистика»; «Учет, анализ и аудит в некоммерческих организациях»; «Страховое дело и учет в страховании»; «Введение в профессиональную деятельность»;   «Деловое общение и документационное обеспечение управленческой деятельности»; Документационное обеспечение управления»"
            },
            {
                id:74,
                image:muraveva,
                fio:"Муравьева Наталья Юрьевна",
                jobtitle:"Старший преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Контроллинг», «Налоговый учет и организация и методика проведения налоговых проверок», «Налоговый учет и отчетность», «Бухгалтерский учет в торговле», «Контроллинг и управление рисками», «МСФО (продвинутый уровень)», «Учет на предприятиях малого бизнеса», «Финансовый анализ (продвинутый уровень)»"
            },
            {
                id:75,
                image:murahovskaya,
                fio:"Мураховская Александра Валерьевна",
                jobtitle:"Старший преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Бухгалтерский учет»; «Бухгалтерский управленческий учет»; «Управленческий учет и учет персонала»"
            },
            {
                id:76,
                image:pasichnik,
                fio:"Пасичник Наталья Владимировна",
                jobtitle:"Старший преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Бухгалтерский учет», «Спецподготовка: Национальная безопасность», «Особенности отраслевого учета»,  «Учет затрат и калькулирование в отраслях экономики», «Финансовый риск-анализ», «Финансовый учет и отчетность», «История бухгалтерского учета», «Управленческий учет и учет персонала», «Внутренний аудит»."
            },
            {
                id:77,
                image:pochtar,
                fio:"Почтарь Олег Валерьевич",
                jobtitle:"Старший преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Специальная подготовка: Основы контрольно-надзорной деятельности»; «АнтиКризисный Консалтинг»; «Особенности Аудита в Отраслях Экономики»; «Финансовый консалтинг»; «Международные стандарты аудита»; «Аудит»; «Управленческий учет»"
            },
            {
                id:78,
                image:prodius,
                fio:"Продиус Сергей Петрович",
                jobtitle:"Старший преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Бухгалтерский учет в банках»; «Банковский аудит»; «Современные платежные системы и их безопасность»"
            },
            {
                id:79,
                image:tanasenko,
                fio:"Танасенко Андрей Сергеевич",
                jobtitle:"Старший преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Введение в профессиональную деятельность»; «Экономическая безопасность»; «Кадровая безопасность предприятия»; «Международные стандарты финансовой отчётности»"
            },
            {
                id:80,
                image:trushin,
                fio:"Трушин Владислав Михайлович",
                jobtitle:"Старший преподаватель",
                listdiscip:"«Экономическая безопасность»; «Антикоррупционное законодательство и политика»; «Экономико-правовые основы противодействия коррупции»; «Антикоррупционный аудит»"
            },
            {
                id:81,
                image:formusatii,
                fio:"Формусатий Диана Викторовна",
                jobtitle:"Старший преподаватель высшей квалификационной категории, без ученой степени, без ученого звания",
                listdiscip:"«Основы бухгалтерского учета»; «Бухгалтерский учет»; «Лабораторный практикум по бухгалтерскому учету»; «Кадровая безопасность предприятия»; «Международные стандарты финансовой отчетности и аудита»"
            },
            {
                id:82,
                image:curkan,
                fio:"Цуркан Анжела Александровна",
                jobtitle:"Старший преподаватель, без ученой степени, без ученого звания",
                listdiscip:"«Бухгалтерский финансовый учет» ; «Бухгалтерский учет»; «Бухгалтерский и управленческий учет»; «Финансовый учет» (продвинутый курс); «Финансовый учет и отчетность» (продвинутый курс); «Бухгалтерская финансовая отчетность»; «Учет затрат и ценообразование»; «Бухгалтерский учет в страховании»; «Аудит» (продвинутый курс); «Аудит в страховых организациях» «Страховое дело и учет в страховании» «Судебно-экономическая экспертиза»; «Судебно-бухгалтерская экспертиза»"
            }
        ]
    }
]
const Dep = () => {
    return listDep;
}

export default Dep;