import React from "react";
import styles from "./Rules.module.css"
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Areas10 = () => {
    return ( <><Header/>
        <main>
            <h3 className={styles["h3-main"]}>Кафедра финансов и кредита</h3>
            <h3 className={styles["h3-main"]}>СПЕЦИАЛИТЕТ</h3><br/>
            <p>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Направление подготовки:</span> 38.05.01 «Экономическая безопасность»<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Профиль подготовки:</span> «Финансово-экономическая безопасность»<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Форма и сроки обучения:</span> очная, 5 лет; заочная, 5,5 лет<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Профильные предметы (вступительные испытания):</span>
            <ul>
                <li>математика (профильный предмет);</li>
                <li>иностранный язык;</li>
                <li>родной язык.</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Описание специальности: </span>Специализация «Финансово-экономическая безопасность» дает комплекс знаний в области:
            <ul>
                <li>права, экономики, финансов, оценки рисков, методов финансового контроля;</li>
                <li>обоснования финансовых, инвестиционных и организационно-управленческих решений, проведения их экспертизы, оценки потенциального воздействия на уровень финансово-экономической безопасности субъектов различных уровней;</li>
                <li>формирования рекомендаций по совершенствованию финансовой стратегии государственных и бизнес-структур;</li>
                <li>оценки финансово-экономических рисков и угроз, выявления финансовых и экономических правонарушений;</li>
                <li>выявления противоправных экономических действий и типовых схем отмывания преступных доходов;</li>
                <li>осуществления финансового контроля и планирования;</li>
                <li>осуществления контроля за соблюдением законодательных и нормативно-правовых актов, регулирующих финансовую деятельность экономических субъектов.</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Чему будут учить:</span>
            <ul>
                <li>финансовая безопасность, судебная финансово-экономическая экспертиза, национальная и международная экономическая безопасность, финансово-экономические риски в системе обеспечения экономической безопасности, экономическая и финансовая безопасность организации, основы расследования и квалификации экономических правонарушений и преступлений, мониторинг и оценка финансового состояния организации, мониторинг теневых процессов в экономике, основы контрольно-надзорной деятельности, современные формы и способы противодействия налоговым правонарушениям, валютное регулирование и валютный контроль, международная система противодействия отмыванию доходов и финансированию терроризма;</li>
                <li>международные валютно-кредитные отношения, государственное регулирование экономики, контроль и ревизия, аудит, налоговое администрирование и контроль, финансы организаций, национальные и международные платежные системы, денежно-кредитное регулирование экономики, финансовый менеджмент, краткосрочная и долгосрочная финансовая политика компании, банковское дело, инвестиции и инвестиционная безопасность.</li>
            </ul><br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Эту профессию можно освоить: </span>на экономическом факультете при кафедре <span style={{color: '#43a5ee', fontWeight: 'bold'}}> финансов и кредита.</span><br/>
            <ul><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Кем можно будет работать (профессии):</span>
                <li>внутренний финансовый контролер (аудитор);</li>
                <li>налоговый инспектор;</li>
                <li>налоговый консультант;</li>
                <li>риск-менеджер;</li>
                <li>специалист по финансовому мониторингу;</li>
                <li>специалист по экономической безопасности;</li>
                <li>банковский специалист всех уровней менеджмента;</li>
                <li>специалиста и служащий государственного и муниципального управления;</li>
                <li>специалист в аналитических подразделениях правоохранительных органов, осуществляющих экспертизу по экономико-правовым вопросам;</li>
                <li>финансовый аналитик и консультант.</li>
            </ul>
            <ul><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Возможные места работы (организации, предприятия):</span>
                <li>органы законодательной и исполнительной власти;</li>
                <li>органы финансового контроля;</li>
                <li>службы внутреннего контроля, экономической безопасности банков, страховых компаний, ломбардов, риэлтеров;</li>
                <li>налоговые органы, правоохранительные органы;</li>
                <li>службы безопасности и другие финансово-экономические службы предприятий (организаций) различных отраслей, сфер, форм собственности;</li>
                <li>аудиторские и консалтинговые компании.</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Количество бюджетных мест – 5.</span>
            </p>
        </main>
        <Footer/></> );
}
 
export default Areas10;