import smi1 from "./../img/smi1.png";
import smi2 from "./../img/smi2.png";
import smi3 from "./../img/smi3.png";
import smi4 from "./../img/smi4.png";
import smi5 from "./../img/smi5.png";
import smi6 from "./../img/smi6.png";
import smi7 from "./../img/smi7.png";
import smi8 from "./../img/smi8.png";
import smi9 from "./../img/smi9.png";
import stud4 from "./../img/stud4.jpg";
import smi11 from "./../img/smi11.png";
//import misseconom1 from "./../img/misseconom1.jpg";
import interv from "./../img/photo_2025-02-19_23-56-48.jpg";
import butvteme1 from "./../img/photo_2025-02-21_09-24-28.jpg";
import butvteme2 from "./../img/photo_2025-02-21_14-18-38.jpg";
import butvteme3 from "./../img/photo_2025-03-09_23-09-56.jpg";
import butvteme4 from "./../img/photo_2025-03-22_11-25-38.jpg";

const newsSmiData = [
    {
        id: 17,
        image: [butvteme4],
        h1: "«Будь в теме!»  игра для финансово грамотных школьников страны",
        p: "Финал II Республиканской интеллектуальной игры для  школьников «Будь в теме!»",
        type: "БУДЬ В ТЕМЕ!",
        data: "25.03.2025",
        link: "https://youtu.be/xjre-i5IjYA",
    },
    {
        id: 16,
        image: [butvteme3],
        h1: "«Финансовая грамотность с Юлией Жильцовой: Сюжет ТСВ",
        p: "Реально ли научить подростка экономить и откладывать? Об этом нам расскажет студентка экономического факультета Юлия Жильцова.",
        type: "БУДЬ В ТЕМЕ!",
        data: "10.03.2025",
        link: "https://www.youtube.com/watch?v=b0jmyxWf2Jc",
    },
    {
        id: 15,
        image: [butvteme2],
        h1: "«Будь в теме». Игра для школьников на финансовую грамотность",
        p: "С помощью мобильного телефона школьники 9-10-11 классов снимают ролики «Распознаем признаки финансового мошенничества». Это отборочный этап для игры по финансовой грамотности «Будь в теме».",
        type: "БУДЬ В ТЕМЕ!",
        data: "21.02.2025",
        link: "https://youtu.be/0jeKlODy2ag?si=Axb08pznEhm9-9yE",
    },
    {
        id: 14,
        image: [butvteme1],
        h1: "Анонс игры для школьников по финансовой грамотности «Будь в теме-2025»",
        p: "",
        type: "БУДЬ В ТЕМЕ!",
        data: "21.02.2025",
        link: "https://youtu.be/oZmg4NiH1YM?si=VPzk3EJFoGhew2ng",
    },
    {
        id: 13,
        image: [interv],
        h1: "Юлия Жильцова: Будь в теме! Финансовая грамотность для школьников – просто о важном",
        p: "Конкурс по финансовой грамотности для школьников. Как пройти путь от азов финансовой грамотности до уверенного управления своими финансами? Подробности — в нашем интервью!",
        type: "БУДЬ В ТЕМЕ!",
        data: "20.02.2025",
        link: "https://youtu.be/Lc_1mSKk1lo?si=BFHSJMXhktv6rSOb&t=203",
    },
    /*
    {
        id: 12,
        image: [misseconom1],
        h1: "Сегодня состоится одно из самых ярких мероприятий года - «Мисс Эконом»!",
        p: "Мы все в невероятном предвкушении и волнении. Пока мы готовимся встречать гостей и участников, слово берет председатель нашего творческого комитета - Дарья Брыжко.",
        type: "",
        data: "18.12.2024",
        link: "https://www.instagram.com/reel/DDt00wzt2Ud/?igsh=d2s1ajhsend3Zmwx",
    },
    */
    {
        id: 11,
        image: [smi11],
        h1: "Вложись в велосипеды – новая финансовая пирамида?",
        p: "Милиция предупреждает о подозрительном финансовом проекте, который похож на пирамиду. На днях МВД опубликовало историю тираспольчанки, которая с этим столкнулась. Людям предлагают вложить деньги в покупку велосипедов где-то в Китае, которые будут сдавать в аренду, а участнику проекта обещают проценты и часть денег от новых участников. Их надо привлекать в проект самостоятельно.",
        type: "",
        data: "02.11.2024",
        link: "https://www.youtube.com/watch?v=3aWAAAOE5tM"
    },
    {
        id: 10,
        image: [stud4],
        h1: "Студенты помогли 4-летнему Саше Садковскому",
        p: "Первокурсников-экономистов сегодня посвящали в студенты. Концерт был благотворительный по платному входу. Собранные деньги факультет отправит 4-летнему Саше Садковскому на лечение.",
        type: "ВОЛОНТЕР",
        data: "31.10.2024",
        link: "https://www.youtube.com/watch?v=Fbn1klLgCrk"
    },
    {
        id: 9,
        image: [smi9],
        h1: "Вступительная компания 2024",
        p: "",
        type: "",
        data: "01.07.2024",
        link: "https://www.youtube.com/watch?v=bRTTGI1LGWU&t=891s"
    },
    {
        id: 8,
        image: [smi8],
        h1: "Профессии для жизни",
        p: "В Госуниверситете и Слободзейском техникуме открыли новые специальности и места специально для выпускников коррекционных школ.",
        type: "",
        data: "01.07.2024",
        link: "https://www.youtube.com/watch?v=kfuiP6SkvVU&t=339s"
    },
    {
        id: 7,
        image: [smi7],
        h1: "18 июня состоялась церемония подведения итогов научной работы студентов экономического факультета",
        p: "Итоги научной работы экономического факультета подводят в ПГУ в актовом зале. Церемонию проводят каждый год. Давайте узнаем результаты. Квизы, курсы, бизнес-игры, студенческие слёты и не только - все это интересные события из жизни факультета.",
        type: "ПРОФРАЗВИТИЕ",
        data: "19.06.2024",
        link: "https://www.youtube.com/watch?v=G8pmqDHl-EA"
    },
    {
        id: 6,
        image: [smi6],
        h1: "«С первой минуты казался старым знакомым»: Виталий Глебов и его Приднестровье",
        p: "Политический и общественный деятель. И один из основателей Приднестровья. Доктор наук. Всё это - о Виталии Глебове. О нем написали книгу. Сегодня ее презентация. В конференц-зале ПГУ – друзья и коллеги Виталия Ивановича",
        type: "",
        data: "19.05.2024",
        link: "https://www.youtube.com/watch?v=C8EmCS8Hb8U"
    },
    {
        id: 5,
        image: [smi5],
        h1: "Слёт молодёжи в Кицканском лесу",
        p: "4-ый патриотический слет молодежи в Кицканском лесу на базе спортивно-оздоровительного лагеря «Содружество». Участвуют студенты и педагоги экономического факультета ПГУ, а также представители приднестровского Молодежного парламента.",
        type: "СПОРТ",
        data: "24.04.2024", 
        link: "https://www.youtube.com/watch?v=dZQu13q5y5I"
    },
    {
        id: 4,
        image: [smi4],
        h1: "Анонс игры «Будь в теме»",
        p: "",
        type: "БУДЬ В ТЕМЕ!",
        data: "25.02.2024",
        link: "https://www.youtube.com/watch?v=ChASHLYXTp4"
    },
    {
        id: 3,
        image: [smi3],
        h1: "STEM-образование запустили в Приднестровье",
        p: "На экономическом факультете ПГУ появилась STEM-лаборатория. Это кабинет с компьютерами и проектором. Техникой обзавелись в рамках европейского гранта, который продвигает идеи STEM-образования.",
        type: "",
        data: "25.02.2024",
        link: "https://www.youtube.com/watch?v=923OoGSYwGU"
    },
    {
        id: 2,
        image: [smi2],
        h1: "Студенты распродали виртуальные портфели",
        p: "В Тирасполе провели бизнес-игру «Практический маркетинг» для студентов экономического факультета ПГУ. В игре-симуляторе молодые бизнесмены создавали свои товары и пытались их продать.",
        type: "ПРОФРАЗВИТИЕ",
        data: "25.02.2024",
        link: "https://www.youtube.com/watch?v=WkUTx0rOYBs"
    },
    {
        id: 1,
        image: [smi1],
        h1: "Студенты придумали, как разгрузить парковки",
        p: "На экономическом факультете ПГУ прошёл хакатон. Это мозговой штурм, в ходе которого студенты придумывали, как можно разгрузить парковки в Тирасполе. Четырём командам помогали предприниматели.",
        type: "ПРОФРАЗВИТИЕ",
        data: "25.02.2024",
        link: "https://www.youtube.com/watch?v=VVhula0cImk"
    }
];

const NewsSmi = () => {
    return newsSmiData;
}

export default NewsSmi;