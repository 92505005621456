import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./style.css";
import laxov from "./../../img/laxov.png";

const Department6 = () => {
    return ( <>
    <Header />
    <div className="container-departments">
    <h1>Учебно - вычислительный центр экономического факультета (УВЦ)</h1>
            <div className="department1">
                <p>
                <ul>Контактная информация:
                    <li>Адрес: г. <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Тирасполь, бул. Гагарина 2, корпус 11</span></li>
                    <li>Компьютерные классы: <span style={{color: '#43a5ee', fontWeight: 'bold'}}>ауд. №112, 216, 300, 314</span></li>
                    <li>Лекционные аудитории с мультимедийным оборудованием: <span style={{color: '#43a5ee', fontWeight: 'bold'}}>ауд. №200, 309, 313</span></li>
                    <li>Телефон: <span style={{color: '#43a5ee', fontWeight: 'bold'}}>+373-533-7-94-76</span></li>
                    <li>Адрес электронной почты: <span style={{color: '#43a5ee', fontWeight: 'bold'}}>oglyahoveckaya@spsu.ru</span></li>
                </ul>
                Дата основания: <span style={{color: '#43a5ee', fontWeight: 'bold'}}>1 марта 2003 года.</span><br/>
                Основной задачей УВЦ является обеспечение и сопровождение учебного процесса в соответствии с требованиями Государственных образовательных стандартов.<br/>
                </p>               
                <figure>
                <img src={laxov} className="righting" width="400px"/>
                    <figcaption style={{lineHeight: '2'}}><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Ляховецкая Ольга Геннадьевна,</span> Начальник Учебно-вычислительного центра экономического факультета</figcaption>
                </figure>
            </div>
            <br/>
            <p>
            <ul>Основными функциями УВЦ являются:
                    <li>установка программного обеспечения в компьютерных классах для проведения практических занятий;</li>
                    <li>настройка и обслуживание локальной сети экономического факультета (сервера для сотрудников факультета и отдельно сервера для обучающихся);</li>
                    <li>техническое обслуживание компьютерной техники в случае возникновения неполадок в структурных подразделениях экономического факультета;</li>
                    <li>организация и обеспечение самостоятельной работы обучающихся при дистанционном обучении в компьютерном классе;</li>
                    <li>помощь в организации и проведении научно-практических конференций, семинаров, круглых столов, обеспечение проведений практических занятий и самостоятельных работ, а также проведений испытаний знаний (тестирование, экзамены, защита курсовых и дипломных работ).</li>
                </ul>
            </p>
            <br/>
            <p>
            Компьютерные классы, расположенные в <span style={{color: '#43a5ee', fontWeight: 'bold'}}>ауд. № 112, 216, 300, 314</span> укомплектованы компьютерным оборудованием и программным обеспечением. Созданная компьютерная инфраструктура обеспечивает необходимые потребности учебного процесса в соответствии с рабочими программами дисциплин согласно учебного плана.<br/>
            Лекционные аудитории, расположенные в <span style={{color: '#43a5ee', fontWeight: 'bold'}}>ауд. №200, 309, 313</span> оборудованы современным мультимедийным оборудованием, в т. ч.: проектором, проекционным экраном.
            Все компьютерные классы, лекционные аудитории, а также структурные подразделения факультета подключены к локальной сети и сети Интернет.
            </p>
        </div>

    <div className="container-departments">
        <table>
            <tr className="row1">
                <td className="td">Ф.И.О. сотрудника</td>
                <td className="td">Должность</td>
            </tr>
            <tr className="row2">
                <td>Ляховецкая Ольга Геннадьевна</td>
                <td>Начальник УВЦ</td>
            </tr>
            <tr className="row3">
                <td>Терентьев Виктор Васильевич</td>
                <td>Инженер-электронщик</td>
            </tr>
            <tr className="row4">
                <td>Урсуленко Юлиан Сергеевич</td>
                <td>Специалист-инженер</td>
            </tr>
            <tr className="row5">
                <td>Евстратий Вадим Васильевич</td>
                <td>Специалист-инженер</td>
            </tr>
            <tr className="row6">
                <td>Надькина Дарья Леонидовна</td>
                <td>Специалист-инженер</td>
            </tr>
        </table>
    </div>
        
    <Footer />
    </> );
}
 
export default Department6;