import React from "react";
import styles from "./Rules.module.css"
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Areas4 = () => {
    return ( <><Header/>
        <main>
            <h3 className={styles["h3-main"]}>Кафедра экономической теории и мировой экономики</h3>
            <h3 className={styles["h3-main"]}>БАКАЛАВРИАТ</h3><br/>
            <p>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Направление подготовки:</span> 38.03.01 «Экономика»<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Профиль подготовки:</span> «Внешнеэкономические связи и таможенный консалтинг»<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Форма и сроки обучения:</span> очная, 4 года<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Профильные предметы (вступительные испытания):</span>
            <ul>
                <li>математика (профильный предмет);</li>
                <li>иностранный язык;</li>
                <li>родной язык.</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Описание специальности: </span>Профиль «Внешнеэкономические связи и таможенный консалтинг» открывает перед вами мир международного бизнеса и торговли. Вы получите уникальные знания в области:
            <ul>
                <li>Заключения международных сделок, проведения переговоров и деловых встреч;</li>
                <li>Функционирования мировой экономики, анализа тенденций развития мировых рынков и международных экономических отношений;</li>
                <li>Организации внешнеэкономической деятельности компаний и государств, включая внешнеторговую политику;</li>
                <li>Таможенного оформления грузов, декларирования товаров, организации таможенных процедур и контроля, а также консультирования компаний, ведущих внешнеэкономическую деятельность.</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Чему вы научитесь:</span>
            <ul>
                <li>Освоите ключевые гуманитарные и экономические дисциплины: микро- и макроэкономику, иностранные языки, менеджмент, маркетинг, экономику фирмы, международное право, мировую экономику и международные отношения;</li>
                <li>Углубите свои знания в математических и естественно-научных дисциплинах: математический анализ, линейная алгебра, эконометрика, теория вероятностей и математическая статистика, экономическая информатика, искусственный интеллект в профессиональной деятельности;</li>
                <li>Получите профессиональные навыки в области организации внешнеэкономической деятельности, таможенных процедур, внешнеторгового регулирования, таможенного аудита и консалтинга.</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Эту профессию можно освоить: </span>на экономическом факультете при кафедре <span style={{color: '#43a5ee', fontWeight: 'bold'}}>экономической теории и мировой экономики.</span>
            <ul><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Кем вы сможете работать:</span>
                <li>специалист по внешнеэкономической деятельности;</li>
                <li>специалист таможенного дела;</li>
                <li>экономист-международник;</li>
                <li>менеджер по международной логистике;</li>
                <li>региональный представитель международной компании;</li>
                <li>таможенный брокер;</li>
                <li>таможенный декларант.</li>
            </ul>
            <ul><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Где вы сможете работать:</span>
                <li>Внешнеэкономические отделы крупных компаний и министерств;</li>
                <li>Валютные отделы банков и финансовых учреждений;</li>
                <li>Таможенные органы;</li>
                <li>Представительства международных компаний;</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Количество бюджетных мест – 3.</span><br/>
            Если вы мечтаете о карьере в международном бизнесе, хотите работать с ведущими компаниями мира и быть в центре глобальных экономических процессов, то профиль «Внешнеэкономические связи и таможенный консалтинг» — это ваш шанс реализовать свои амбиции! <br/>
            Если вам нужна дополнительная информация о программе, условиях поступления или перспективах трудоустройства, дайте знать — мы с радостью ответим на все ваши вопросы! <br/>
            Тел. 533-79483
            </p>
        </main>
        <Footer/></> );
}
 
export default Areas4;