import React from "react";
import styles from "./Rules.module.css"
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Areas13 = () => {
    return ( <><Header/>
        <main>
            <h3 className={styles["h3-main"]}>Кафедра финансов и кредита</h3>
            <h3 className={styles["h3-main"]}>МАГИСТРАТУРА</h3><br/>
            <p>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Направление подготовки:</span> 38.04.08 «Финансы и кредит»<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Профиль подготовки:</span> «Финансовая экономика и монетарное регулирование»<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Форма и сроки обучения:</span> очная – 2 года, заочная – 2 года 6 месяцев<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Профильные предметы (вступительные испытания): </span>собеседование по профильному предмету.
            
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Описание специальности: </span>Профиль «Финансовая экономика и монетарное регулирование» дает комплекс углубленных знаний в области:
            <ul>
                <li>денежно-кредитных и финансовых отношений;</li>
                <li>функциональных и институциональных основ денежно-кредитного и финансового регулирования;</li>
                <li>механизмов монетарного и финансового регулирования;</li>
                <li>инструментария достижения финансовой стабильности экономики;</li>
                <li>деятельности монетарных и финансовых институтов;</li>
                <li>состояния и перспектив развития финансовых рынков;</li>
                <li>создания новых финансовых продуктов и инновационных финансовых технологий.</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Чему будут учить:</span>
            <ul>
                <li>современные концепции финансов и кредита; финансовый анализ; современные финансовые отношения; поведенческие финансы; корпоративные финансы; инвестиционная стратегия; производные финансовые инструменты; финансовые технологии; валютно-финансовые системы; инвестиционное кредитование;</li>
                <li>банковский бизнес; финансовые и денежно-кредитные методы регулирования экономики; управление рисками валютных, кредитных и финансовых операций; развитие надзора за деятельностью финансово-кредитных институтов; современная инфраструктура кредитной системы; новации в развитии национальной платежной системы; налогообложение финансово-кредитных институтов; финансовые рынки и финансово-кредитные институты.</li>
            </ul><br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Эту профессию можно освоить: </span>на экономическом факультете при кафедре <span style={{color: '#43a5ee', fontWeight: 'bold'}}> финансов и кредита.</span><br/>
            <ul><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Кем можно будет работать (профессии):</span>
                <li>финансист;</li>
                <li>финансовый аналитик;</li>
                <li>финансовый менеджер;</li>
                <li>финансовый консультант;</li>
                <li>кредитный аналитик, менеджер по кредитованию;</li>
                <li>инвестиционный консультант;</li>
                <li>специалист банковского дела;</li>
                <li>брокер.</li>
            </ul>
            <ul><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Возможные места работы (организации, предприятия):</span>
                <li>органы государственной и муниципальной власти (Министерство экономического развития ПМР, Министерство финансов ПМР и другие министерства и ведомства);</li>
                <li>Приднестровский республиканский банк;</li>
                <li>банковские и небанковские кредитные организации;</li>
                <li>финансовые и кредитные институты;</li>
                <li>микрофинансовые и микрокредитные организации;</li>
                <li>финансовые, инвестиционные, страховые компании;</li>
                <li>финансовые отделы организаций различных отраслей экономики;</li>
                <li>консалтинговые, юридические, аудиторские компании.</li>
            </ul>
            </p>
        </main>
        <Footer/></> );
}
 
export default Areas13;