import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import styles from "./../pages/applicants/Rules.module.css";

const Schedule3 = () => {
    return ( <>
    <Header />
    <main>
        <h2 className={styles["h2-main"]}>График проведения курсовых, контрольных и модульных работ на 2024-2025 учебный год</h2>
        <table className="contacts">
            <thead>
                <tr className="row3 ">
                    <td className="cell-1 td">График проведения</td>
                    <td className="cell-2 td">Полугодие</td>
                    <td className="td">Структурное подразделение</td>
                </tr>
            </thead>
            <tr>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </table>
    </main>
    <Footer />
    </> );
}
 
export default Schedule3;