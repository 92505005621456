import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./style.css";

const Contacts = () => {
    return (<>
    <Header/>
    <div className="container-departments">
        <h1>Контакты</h1>
        <table className="contacts">
            <thead>
                <tr className="row3 ">
                    <td className="cell-1 td">Наименование структурного подразделения</td>
                    <td className="cell-2 td">Должность</td>
                    <td className="td">ФИО</td>
                    <td className="td">Номер телефона</td>
                </tr>
            </thead>
            
            <tr>
                <td rowSpan={8} style={{verticalAlign: 'top'}}>Деканат</td>
                <td>Декан, кандидат экономических наук, доцент</td>
                <td>Узун Иван Николаевич</td>
                <td>533-79479</td>
            </tr>
            <tr>
                
                <td className="cell-1">Заместитель декана по учебно-методической работе</td>
                <td>Павлин Евгения Васильевна</td>
                <td>533-79481</td>
            </tr>
            <tr>
                
                <td>Заместитель декана по научной работе, доктор экономических наук, профессор</td>
                <td>Босюк Виктор Николаевич</td>
                <td>533-79730</td>
            </tr>
            <tr>
                
                <td>Заместитель декана по воспитательной работе</td>
                <td>Формусатий Диана Викторовна</td>
                <td>533-79781</td>
            </tr>
            <tr>
                
                <td>Методист дневного отделения</td>
                <td>Горбачева Татьяна Евгеньевна</td>
                <td rowSpan={2} style={{verticalAlign: 'top'}}>533-79474</td>
            </tr>
            <tr>
                
                <td>Специалист дневного отделения</td>
                <td>Сойферман Ирина Николаевна</td>
                
            </tr>
            <tr>
                
                <td>Методист заочного отделения</td>
                <td>Бондаренко Надежда Петровна</td>
                <td rowSpan={2} style={{verticalAlign: 'top'}}>533-79481</td>
            </tr>
            <tr>
                
                <td>Специалист заочного отделения</td>
                <td>Попова Ольга Николаевна</td>
                
            </tr>
            <tr>
                <td rowSpan={3} style={{verticalAlign: 'top'}}>Кафедра бизнес-информатики и математических методов в экономике</td>
                <td>И.о. заведующего кафедрой, доцент</td>
                <td>Надькин Леонид Юрьевич</td>
                <td rowSpan={3} style={{verticalAlign: 'top'}}>533-79474</td>
            </tr>
            <tr>
                
                <td>Специалист кафедры</td>
                <td>Охомуш Светлана Алексеевна</td>
                
            </tr>
            <tr>
                
                <td>Специалист-инженер-программист</td>
                <td>Юрковская Людмила Алексеевна</td>
                
            </tr>
            <tr>
                <td rowSpan={2} style={{verticalAlign: 'top'}}>Кафедра бухгалтерского учета и аудита</td>
                <td>Заведующий кафедрой, доцент</td>
                <td>Стасюк Татьяна Петровна</td>
                <td rowSpan={2} style={{verticalAlign: 'top'}}>533-79470</td>
            </tr>
            <tr>
                
                <td>Специалист кафедры</td>
                <td>Лясковская Анжелина Нольевна</td>
                
            </tr>
            {/**
             * <tr>
    
                <td>Специалист кафедры</td>
                <td>Сапова Надежда Александровна</td>
                
            </tr>
             */}
            
            <tr>
                <td rowSpan={3} style={{verticalAlign: 'top'}}>Кафедра финансов и кредита</td>
                <td>Заведующий кафедрой, доцент</td>
                <td>Сафронов Юрий Михайлович</td>
                <td rowSpan={3} style={{verticalAlign: 'top'}}>533-79480</td>
            </tr>
            <tr>
                
                <td>Специалист кафедры</td>
                <td>Стратулат Симона Эдуардовна</td>
                
            </tr>
            <tr>
                
                <td>Специалист кафедры</td>
                <td>Колесник Яна Андреевна</td>
                
            </tr>
            <tr>
                <td rowSpan={3} style={{verticalAlign: 'top'}}>Кафедра экономики и менеджмента</td>
                <td>Заведующий кафедрой, доцент</td>
                <td>Смоленский Николай Николаевич</td>
                <td rowSpan={3} style={{verticalAlign: 'top'}}>533-79477</td>
            </tr>
            <tr>
                
                <td>Специалист кафедры</td>
                <td>Факира Ирина Васильевна</td>
                
            </tr>
            <tr>
                
                <td>Специалист кафедры</td>
                <td>Джалая Лариса Васильевна</td>
                
            </tr>
            <tr>
                <td rowSpan={2} style={{verticalAlign: 'top'}}>Кафедра экономической теории и мировой экономики</td>
                <td>И.о. заведующего кафедрой, кандидат экономических наук, доцент</td>
                <td>Узун Иван Николаевич</td>
                <td rowSpan={2} style={{verticalAlign: 'top'}}>533-79483</td>
            </tr>
            <tr>
                
                <td>Специалист кафедры</td>
                <td>Макарова Дарья Вадимовна</td>
                
            </tr>
            {/**
             * <tr>
                
                <td>Старший лаборант кафедры</td>
                <td>Поликанова Тамара Федоровна</td>
                
            </tr>
             */}
            
            <tr>
                <td>Учебно-вычислительный центр</td>
                <td>Начальник</td>
                <td>Ляховецкая Ольга Геннадьевна</td>
                <td>533-79476</td>
            </tr>
            
        </table>
    </div>
    <Footer/>
    </>);
}
 
export default Contacts;