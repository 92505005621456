import React from "react";
import faculty from "./../../img/faculty.png";
import "./style.css";
import "./../../css/main.css";
import decan1 from "./../../img/decan1.png";
import decan2 from "./../../img/decan2.png";
import decan3 from "./../../img/decan3.jpeg";
import decan4 from "./../../img/decan4.png";
import decan5 from "./../../img/decan5.png";
import decan6 from "./../../img/decan6.png";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { TbH2 } from "react-icons/tb";

const History = () => {
    return ( 
        <>
        <Header />

        <div className="container">
            <h1>История создания факультета</h1>
            <div className="history">
                <p>
                    Экономический факультет был образован <span className="blue">25 декабре 1990 года</span> в составе Тираспольского государственного корпоративного университета. Изначально в состав факультета входило только одно структурное подразделение – кафедра бухгалтерского учета.
                    К работе привлекались преподаватели, приехавшие из г. Кишинев: Грицюк Г.Н, Лапушанский С.И., Сенокосова Л.Г., Якушенко В.Г. и др.<br/><br/>
                    <span className="blue">В марте 1997 года</span> открывается кафедра экономики и управления предприятиями народного хозяйства, которая в последующем была переименована в кафедру экономики и менеджмента, а также кафедра финансов и кредита.
                </p>
                <img src={faculty} className="righting" width="920px"/>
            </div>
            <br/>
            <p>
            <span className="blue">В августе 1997 года</span> открывается кафедра Прикладной информатики, которая в последующем была переименована в кафедру «Бизнес-информатика и информационные технологии», а <span className="blue">в 2024 году</span> «Бизнес-информатика и математические методы в экономике». <span className="blue">В 2001 году</span> в состав экономического факультета входит общеуниверситетская кафедра Экономическая теория (основана в 1963 г.), которая в последующем была переименована в кафедру «Экономическая теория и мировая экономика». <span className="blue">В 2005 году</span> факультет переехал в собственный корпус на бул. Гагарина.
            За все время работы факультета успешно завершили свое обучение около 5 тыс. человек.</p>
            <br/>
            <p className="blue decan">За период деятельности экономического факультета им руководили шесть деканов:</p>
            <div className="decan-card">
                <div className="decan-card__item">
                    <img src={decan1} style={{height: '307px'}}/>
                    <h3>Владимир Николаевич Немиров</h3>
                    <p>С 1990 по 1991 г.г.</p>
                </div>
                <div className="decan-card__item">
                    <img src={decan2} style={{height: '307px'}}/>
                    <h3>Владимир Григорьевич
                    Якушенко</h3>
                    <p>С 1991 по 2008 г.г.</p>
                </div>
                <div className="decan-card__item">
                    <img src={decan3} style={{height: '307px'}}/>
                    <h3>Дмитрий Александрович Поросеч</h3>
                    <p>С 2008 по 2009 г.г.</p>
                </div>
                <div className="decan-card__item">
                    <img src={decan4}/>
                    <h3>Николай Николаевич Смоленский</h3>
                    <p>С 2009 по 2017 г.г.</p>
                </div>
                <div className="decan-card__item">
                    <img src={decan5}/>
                    <h3>Ирина Васильевна
                    Толмачева</h3>
                    <p>С 2017 по 2018 г.г.</p>
                </div>
                <div className="decan-card__item">
                    <img src={decan6}/>
                    <h3>Иван Николаевич<br/> Узун</h3>
                    <p>С 2018 г.</p>
                </div>
            </div>
        </div>
 
        <Footer />
        </>
        
     );
}
 
export default History;