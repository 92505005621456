import React from "react";
import styles from "./Rules.module.css"
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Areas4 = () => {
    return ( <><Header/>
        <main>
            <h3 className={styles["h3-main"]}>Кафедра экономической теории и мировой экономики</h3>
            <h3 className={styles["h3-main"]}>БАКАЛАВРИАТ</h3><br/>
            <p>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Направление подготовки:</span> 38.03.01 «Экономика»<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Профиль подготовки:</span> «Внешнеэкономические связи и таможенный консалтинг»<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Форма и сроки обучения:</span> очная, 4 года<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Профильные предметы (вступительные испытания):</span>
            <ul>
                <li>математика (профильный предмет);</li>
                <li>иностранный язык;</li>
                <li>родной язык.</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Описание специальности: </span>Профиль «Внешнеэкономические связи и таможенный консалтинг» дает комплекс знаний в области:
            <ul>
                <li>заключения международных сделок, проведения переговоров, деловых встреч и т. д;</li>
                <li>функционирования механизма мирового хозяйства, состояния и тенденций развития мировых рынков, содержания и развития международных экономических отношений;</li>
                <li>организации внешнеэкономических связей и внешнеэкономической деятельности организаций, внешнеторговой политики государств;</li>
                <li>таможенного оформления грузов, декларирования товаров, организации таможенных процедур и таможенного контроля, осуществления таможенного консультирования экономических агентов, ведущих внешнеэкономическую деятельность.</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Чему будут учить:</span>
            <ul>
                <li>общие гуманитарные и экономические дисциплины: микро- и макроэкономика, иностранные языки, менеджмент, маркетинг, экономика фирмы, международное право, мировая экономика и международные отношения;</li>
                <li>математические и естественно-научные дисциплины: математический анализ, линейная алгебра, эконометрика, теория вероятностей и математическая статистика, информатика, экономическая информатика;</li>
                <li>профессиональные дисциплины: организация внешнеэкономической деятельности предприятия, внешнеэкономические связи, внешнеэкономическая политика и внешнеэкономическая деятельность государства, организация и регулирование внешнеторговой деятельности, таможенные процедуры и платежи, таможенное оформление и декларирование, таможенное регулирование международной торговли, таможенный аудит и консалтинг.</li>
            </ul><br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Эту профессию можно освоить: </span>на экономическом факультете при кафедре <span style={{color: '#43a5ee', fontWeight: 'bold'}}>экономической теории и мировой экономики.</span>
            <ul><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Кем можно будет работать (профессии):</span>
                <li>специалист по внешнеэкономической деятельности;</li>
                <li>специалист таможенного дела;</li>
                <li>экономист-международник;</li>
                <li>менеджер по международной логистике;</li>
                <li>региональный представитель международной компании;</li>
                <li>таможенный брокер;</li>
                <li>таможенный декларант.</li>
            </ul>
            <ul><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Возможные места работы (организации, предприятия):</span>
                <li>внешнеэкономические отделы ведущих организаций и министерств;</li>
                <li>валютные отделы финансово-кредитных учреждений;</li>
                <li>административные органы, таможенные органы;</li>
                <li>представительства международных компаний;</li>
                <li>международные аудиторские компании.</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Количество бюджетных мест – 5.</span>
            </p>
        </main>
        <Footer/></> );
}
 
export default Areas4;