import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./style.css";
import lyu from "./../../img/lyu.png";
import bosuk from "./../../img/decan1.png";
import solomatina from "./../../img/solomatina.png";
import dorosh from "./../../img/dorosh.png";
import malax from "./../../img/malax.png";

const Department1 = () => {
    return ( <>
    <Header />
    <div className="container-departments">
    <h1>Кафедра бизнес-информатики и математических методов в экономике</h1>
            <div className="department1">
                <p>
                <ul>
                    <li>3300, г. Тирасполь, бульвар Гагарина 2, каб. № 302</li>
                    <li>533-79478</li>
                    <li>pi_ef_pgu@rambler.ru</li>
                </ul>
                Кафедра Прикладной информатики была образована <span style={{color: '#43a5ee', fontWeight: 'bold'}}>15 августа 1997 г.</span> по решению Ученого Совета ПГУ им. Т. Г. Шевченко. Первым заведующим кафедрой стал Анисимов Павел Андреевич, к. т. н., доцент. Кафедра обеспечивала преподавание дисциплин математического, естественнонаучного цикла на аграрно-технологическом, медицинском, юридическом и экономических факультетах.<br/>
                С 20 апреля 2006 по 1 сентября 2022 года обязанности заведующего кафедрой исполняла Саломатина Елена Васильевна, к.т.н. С 2012 года кафедра стала выпускающей и начала обучение студентов по направлению подготовки «Бизнес-информатика» профиль Электронный бизнес.
                </p>               
                <figure>
                <img src={lyu} className="righting" width="400px"/>
                    <figcaption style={{lineHeight: '2'}}><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Надькин Леонид Юрьевич,</span> доцент, заведующий кафедрой</figcaption>
                </figure>
                
            </div>
            <br/>
            <p>
            С 1 сентября 2015 году кафедра была переименована в кафедру <span style={{color: '#43a5ee', fontWeight: 'bold'}}>бизнес-информатики и информационных технологий.</span> В составе кафедры работали такие профессионалы, как академик НАН РА, профессор, д.т.н. Сарьян Вильям Карпович, Первый заместитель Председателя Правления Лупашко Сергей Валентинович. Кафедра поддерживала сотрудничество с научными коллективами учебных заведений и организаций: Хмельницкий государственный университет, Академия информатизации образования (Москва), Калужский государственный университет, Московский технический университет связи и информатики, Национальный исследовательский университет "Высшая школа экономики".
            С 1 сентября 2022 году кафедру возглавляет Надькин Леонид Юрьевич, к.ф.-м.н., доцент. С 2023 года в составе кафедры активно работает профессор, д.т.н. Босюк Виктор Николаевич.
            </p>
            <br/>
            <p>
            С 2024 года была внедрена двухуровневая система подготовки кадров – открыта магистратура по направлению Менеджмент профиль <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Управление и разработка информационных систем.</span> Планируется открытие аспирантуры по специальности <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Математические, статистические и инструментальные методы в экономике.</span>
            Преподаватели кафедры ежегодно повышают свой профессиональный уровень, в том числе и с использованием дистанционных технологий.
            </p>
            <br/>
            <p>
            Кафедра участвует в научных конференциях, семинарах. Активно ведется работа со студентами, что выражается в их участии в ежегодной научно-практической студенческой конференции. Учебным проектом студентов направления Бизнес-информатика является разработанный сайт экономического факультета, уже второй по счету.
            </p>
            <br/>
            <p>
            Основная цель деятельности кафедры Бизнес-информатики и информационных технологий состоит в подготовке конкурентоспособных выпускников, отвечающих требованиям рынка труда и обладающих востребованными компетенциями в сфере информационных технологий в области экономики и управления.
            </p>
            <br/>
            <p><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Цель деятельности кафедры Бизнес-информатики и информационных технологий состоит в решении следующих задач:</span>
            <ul>
                <li>планомерное и системное накопление методического опыта подготовки бакалавров и магистров направления Бизнес-информатики;</li>
                <li>определение целей и задач внутренней политики кафедры в области подготовки высококвалифицированных специалистов инновационной экономики;</li>
                <li>подготовка научных изданий, учебно-методических пособий для учебного процесса и практической работы специалистов по информационным технологиям в области экономики и управления;</li>
                <li>проведение воспитательной, социально-культурной работы со студентами совместно с общественными организациями и органами студенческого самоуправления;</li>
                <li>оказание помощи в организации инновационного учебного процесса и повышении квалификации.</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Специальности подготовки дипломированных специалистов:<br/>
            направления подготовки бакалавров:</span>
            <ul>
                <li>Направление: <em>Бизнес-информатика (380305)</em></li>
                <li>Профиль: <em>Применение и разработка информационных систем в экономике</em></li>
            </ul><br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Основные направления научной деятельности:</span><br/>
            <ul style={{listStyleType: 'none'}}>
                <li><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Тема: </span>
                    Математические методы и цифровые технологии в экономике и образовании.
                </li>
                <li><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Подтема 1: </span>
                    Применение искусственного интеллекта в управлении бизнесом иэкономике.
                </li>
                <li><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Подтема 2: </span>
                    Инновационные методы внедрения искусственного интеллекта в учебный процесс: преимущества и перспективы.
                </li>
                <li><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Тема: </span>
                    Проектирование информационных систем.
                </li>
                <li><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Подтема 1: </span>
                    Применение искусственного интеллекта в управлении бизнесом в экономике.
                </li>
                <li><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Подтема 2: </span>
                    Проектирование и разработка информационной системы для обеспечения деятельности материального отдела ПГУ им Т.Г. Шевченко.
                </li>
                <li><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Подтема 3: </span>
                    Проектирование и разработка Web-ресурса «Личный кабинет преподавателя» ПГУ им Т.Г. Шевченко.
                </li>
            </ul>
            </p>
        </div>

    <div className="container-departments">
        <div className="department1-items">
            <div className="department1-item">
                <div className="container-department1-image">
                    <img src={lyu} alt="Надькин Леонид Юрьевич" />
                </div>
                <h3>Надькин Леонид<br/> Юрьевич</h3>
                <p>Зав. кафедрой, доцент</p>
            </div>
            <div className="department1-item">
                <div className="container-department1-image">
                    <img src={bosuk} alt="Босюк Виктор Николаевич" />
                </div>
                <h3>Босюк Виктор<br/> Николаевич</h3>
                <p>Профессор</p>
            </div>
            <div className="department1-item">
                <div className="container-department1-image">
                    <img src={solomatina} alt="Саломатина Елена Васильевна" />
                </div>
                <h3>Саломатина Елена Васильевна</h3>
                <p>Доцент</p>
            </div>
            <div className="department1-item">
                <div className="container-department1-image">
                    <img src={dorosh} alt="Дорошенко Анна Владимировна" />
                </div>
                <h3>Дорошенко Анна Владимировна</h3>
                <p>Ст .преподаватель</p>
            </div>
            <div className="department1-item">
                <div className="container-department1-image">
                    <img src={malax} alt="Малахова Мария Владимировна" />
                </div>
                <h3>Малахова Мария Владимировна</h3>
                <p>Ст .преподаватель</p>
            </div>
            <div className="department1-item">
                <div className="container-department1-image">
                    <img src={bosuk} alt="Джалая Лариса Васильевна" />
                </div>
                <h3>Джалая Лариса Васильевна</h3>
                <p>Преподаватель</p>
            </div>
            <div className="department1-item">
                <div className="container-department1-image">
                    <img src={bosuk} alt="Охомуш Светлана Алексеевна" />
                </div>
                <h3>Охомуш Светлана Алексеевна</h3>
                <p>Специалист</p>
            </div>
            <div className="department1-item">
                <div className="container-department1-image">
                    <img src={bosuk} alt="Юрковская Людмила Алексеевна" />
                </div>
                <h3>Юрковская Людмила Алексеевна</h3>
                <p>Специалист</p>
            </div>
        </div>
    </div>
        
    <Footer />
    </> );
}
 
export default Department1;