import React from "react";
import styles from "./Rules.module.css"
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Areas6 = () => {
    return ( <><Header/>
        <main>
            <h3 className={styles["h3-main"]}>Кафедра финансов и кредита</h3>
            <h3 className={styles["h3-main"]}>БАКАЛАВРИАТ</h3><br/>
            <p>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Направление подготовки:</span> 38.03.01 «Экономика»<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Профиль подготовки:</span> «Финансы и кредит»<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Форма и сроки обучения:</span> очная, 4 года<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Профильные предметы (вступительные испытания):</span>
            <ul>
                <li>математика (профильный предмет);</li>
                <li>иностранный язык;</li>
                <li>родной язык.</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Описание специальности: </span>Профиль «Финансы и кредит» дает комплекс знаний в области:
            <ul>
                <li>организации и управления финансами предприятия, государственных и муниципальных финансов, внебюджетных фондов;</li>
                <li>формирования и исполнения бюджетов различных уровней, управления государственным долгом;</li>
                <li>формирования эффективной инвестиционной политики, оценки эффективности инвестиционных проектов; формирования ценовых стратегий компании на рынке;</li>
                <li>планирования, контроля и оценки устойчивости и результативности финансов предприятий и организаций, проведения финансового анализа, интерпретации их финансовой, бухгалтерской отчетности;</li>
                <li>организации и функционирования денежно-кредитных отношений, банковского и страхового дела, платежных систем, рынка ценных бумаг, налогообложения;</li>
                <li>прогнозирования развития финансового рынка и отдельных его сегментов.</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Чему будут учить:</span>
            <ul>
                <li>финансы организаций, финансовый менеджмент, финансовая среда предпринимательства и предпринимательские риски, международный финансовый менеджмент, моделирование финансовой деятельности, финансовое планирование и прогнозирование, финансы бюджетных организаций, краткосрочная и долгосрочная финансовая политика, оценка стоимости бизнеса;</li>
                <li>банковское дело, банковское законодательство, банковский менеджмент, маркетинг в коммерческом банке, организация деятельности центрального банка, учет и операционная деятельность в банках, организация и оценка деятельности коммерческого банка, организация денежно-кредитного регулирования, международные валютно-кредитные отношения, рынок ценных бумаг;</li>
                <li>бюджетная система, налоги и налогообложение, налоговое право, инвестиционная стратегия, иностранные инвестиции.</li>
            </ul><br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Эту профессию можно освоить: </span>на экономическом факультете при кафедре <span style={{color: '#43a5ee', fontWeight: 'bold'}}>финансов и кредита.</span>
            <ul><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Кем можно будет работать (профессии):</span>
                <li>экономист;</li>
                <li>финансист;</li>
                <li>финансовый аналитик;</li>
                <li>финансовый менеджер;</li>
                <li>финансовый консультант;</li>
                <li>специалист банковского дела;</li>
                <li>специалист страхового дела;</li>
                <li>специалист налоговой службы;</li>
                <li>инвестиционный консультант;</li>
                <li>оценщик бизнеса;</li>
                <li>брокер;</li>
                <li>аудитор;</li>
                <li>государственный служащий.</li>
            </ul>
            <ul><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Возможные места работы (организации, предприятия):</span>
                <li>органы государственной и муниципальной власти (Министерство экономического развития ПМР, Министерство финансов ПМР, Счетная палата ПМР и другие министерства и ведомства);</li>
                <li>законодательные органы власти;</li>
                <li>Приднестровский республиканский банк;</li>
                <li>отделы экономического планирования и анализа, финансового учета, внутреннего контроля банков и компаний и организаций различных форм собственности;</li>
                <li>банковские и небанковские кредитные организации;</li>
                <li>валютно-финансовые, страховые, инвестиционные компании;</li>
                <li>аудиторские и консалтинговые компании;</li>
                <li>научные и образовательные организации.</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Количество бюджетных мест – 7.</span>
            </p>
        </main>
        <Footer/></> );
}
 
export default Areas6;