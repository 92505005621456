import React from "react";
import styles from "./Rules.module.css"
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Areas15 = () => {
    return ( <><Header/>
        <main>
            <h3 className={styles["h3-main"]}>Кафедра финансов и кредита</h3>
            <h3 className={styles["h3-main"]}>МАГИСТРАТУРА</h3><br/>
            <p>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Направление подготовки:</span> 5.38.04.01 «Экономика»<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Профиль подготовки:</span> «Бухгалтерский учет, анализ и аудит в отраслях экономики»<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Форма и сроки обучения:</span> заочная, 2 года и 6 месяцев<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Профильные предметы (вступительные испытания): </span>комплексный экзамен
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Описание направления подготовки: </span>Профиль «Бухгалтерский учет, анализ и аудит в отраслях экономики» дает комплекс углубленных знаний в области:
            <ul>
                <li>исследований, анализа и прогнозирования социально-экономических процессов и явлений на микроуровне и макроуровне в экспертно-аналитических службах (центрах экономического анализа, правительственном секторе, общественных организациях);</li>
                <li>производства продукции и услуг, включая анализ спроса на продукцию и услуги, и оценку их текущего и перспективного предложения, планирования и обслуживания финансовых потоков, связанных с производственной деятельностью;</li>
                <li>внутреннего и внешнего финансового контроля и аудита, финансового консультирования (консалтинга).</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Чему будут учить:</span>
            <ul>
                <li>общие гуманитарные и экономические дисциплины: микро- и макроэкономика (продвинутый курс), профессиональный иностранный язык (английский), методика и методология научного исследования;
                -математические и естественно-научные дисциплины: эконометрика (продвинутый курс), финансовый учет и отчетность (продвинутый курс), управленческий учет (продвинутый курс);</li>
                <li>профессиональные дисциплины: финансовый учет и отчетность (продвинутый уровень), управленческий учет (продвинутый уровень), МСФО (продвинутый уровень), управленческий анализ в отраслях (продвинутый уровень), бухгалтерский учет в банках, учет, анализ и аудит в некоммерческих организациях, бухгалтерский учет на предприятиях общественного питания, бухгалтерский учет в торговле (продвинутый уровень); особенности учета в отраслях экономики; особенности аудита в отраслях экономики; финансовый анализ (продвинутый уровень), инвестиционный и инновационный анализ; учет, анализ и аудит в период антикризисного управления; бухгалтерский учет в страховании; контроллинг и управление рисками; профессиональное суждение бухгалтера и аудитора; история бухгалтерского учета; компьютерные технологии в учете, анализе и аудите.</li>
            </ul><br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Эту профессию можно освоить: </span>на экономическом факультете при кафедре <span style={{color: '#43a5ee', fontWeight: 'bold'}}>бухгалтерского учета и аудита.</span><br/>
            <ul><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Кем можно будет работать (профессии):</span>
                <li>руководители и специалисты в области бухгалтерского учета, анализа хозяйственной деятельности и аудита для работы в организациях различных правовых форм собственности, включая финансовые и инвестиционные структуры;</li>
                <li>главный бухгалтер, бухгалтер, бухгалтер-ревизор на предприятиях различных отраслей и видов деятельности;</li>
                <li>работник учетных и финансовых служб;</li>
                <li>бухгалтер-аналитик на предприятиях различных отраслей и видов деятельности;</li>
                <li>судебный эксперт;</li>
                <li>преподаватели специальных дисциплин, выполнение научно-исследовательских и проектных работ в области бухгалтерского учета, анализа и аудита в отраслях экономики в академических и ведомственных научно-исследовательских организациях, образовательных учреждениях начального профессионального, среднего профессионального, высшего профессионального и дополнительного профессионального образования;</li>
                <li>руководитель бухгалтерской, аналитической и аудиторской службы и решать различные задачи, связанные с постановкой учета, проведением аудита и анализа;</li>
                <li>аудитор, помощник аудитора.</li>
            </ul>
            <ul><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Возможные места работы (организации, предприятия):</span>
                <li>торговые фирмы, производственные, проектные и научные организации различных отраслей;</li>
                <li>крупные и средние коммерческие организации различных отраслей и видов деятельности (пищевой промышленности, машиностроении, металлургии, текстильной, швейной отрасли, сельском хозяйстве, транспорте, строительстве, общепите, сфере услуг и т.д.);</li>
                <li>бюджетные учреждения;</li>
                <li>банки;</li>
                <li>страховые организации;</li>
                <li>Министерство финансов ПМР, Министерство здравоохранения ПМР, Министерство экономического развития ПМР, Счетная палата ПМР и другие министерства и ведомства; Прокуратура, Следственный комитет ПМР, ОБЭП и другие органы государственной власти;</li>
                <li>аудиторские и консалтинговые фирмы;</li>
                <li>государственные финансовые, налоговые и контрольно-ревизионные органы;</li>
                <li>учебные заведения.</li>
            </ul>
            </p>
        </main>
        <Footer/></> );
}
 
export default Areas15;