import React from "react";
import styles from "./Rules.module.css"
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Areas14 = () => {
    return ( <><Header/>
        <main>
            <h3 className={styles["h3-main"]}>Кафедра финансов и кредита</h3>
            <h3 className={styles["h3-main"]}>МАГИСТРАТУРА</h3><br/>
            <p>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Направление подготовки:</span> 5.38.04.01 «Экономика»<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Профиль подготовки:</span> «Аудит и финансовый консалтинг»<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Форма и сроки обучения:</span> заочная, 2 года и 6 месяцев<br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Профильные предметы (вступительные испытания): </span>комплексный экзамен
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Описание направления подготовки: </span>Профиль «Аудит и финансовый консалтинг» дает комплекс углубленных знаний в области:
            <ul>
                <li>исследований, анализа и прогнозирования социально-экономических процессов и явлений на микроуровне и макроуровне в экспертно-аналитических службах (центрах экономического анализа, правительственном секторе, общественных организациях);</li>
                <li>производства продукции и услуг, включая анализ спроса на продукцию и услуги, и оценку их текущего и перспективного предложения, планирования и обслуживания финансовых потоков, связанных с производственной деятельностью;</li>
                <li>внутреннего и внешнего финансового контроля и аудита, финансового консультирования (консалтинга).</li>
            </ul>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Чему будут учить:</span>
            <ul>
                <li>общие гуманитарные и экономические дисциплины: микро- и макроэкономика (продвинутый курс), профессиональный иностранный язык (английский), методика и методология научного исследования;
                -математические и естественно-научные дисциплины: эконометрика (продвинутый курс), финансовый анализ (продвинутый курс);</li>
                <li>профессиональные дисциплины: аудит (продвинутый курс), история аудиторской деятельности; особенности аудита в отраслях экономики, финансовый, антикризисный и другие виды консалтинга, антикоррупционный, международный, банковский и другие виды аудита, аудит в государственных учреждениях, внутренний аудит, аудит мошенничеств, контроллинг и управление рисками, профессиональное суждение бухгалтера и аудитора, компьютерные технологии в анализе и аудите.</li>
            </ul><br/>
            <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Эту профессию можно освоить: </span>на экономическом факультете при кафедре <span style={{color: '#43a5ee', fontWeight: 'bold'}}>бухгалтерского учета и аудита.</span><br/>
            <ul><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Кем можно будет работать (профессии):</span>
                <li>должности руководителей и специалистов отдела (департамента) внутреннего аудита в крупных и средних организациях различных отраслей и видов деятельности;
                -должности директора, ведущего специалиста, руководителя подразделения в аудиторских и консалтинговых фирмах;</li>
                <li>помощник аудитора и аудитор в национальных и международных компаниях, как в ПМР, так и за рубежом;</li>
                <li>финансовый консультант в аудиторских и консалтинговых компаниях;</li>
                <li>преподаватели специальных дисциплин, выполнение
                научно-исследовательских и проектных работ в области бухгалтерского учета, анализа и аудита в академических и ведомственных научно-исследовательских организациях, образовательных учреждениях начального профессионального, среднего профессионального, высшего профессионального и дополнительного профессионального образования;</li>
                <li>административные должности, а также специалисты методологических отделов в саморегулируемых аудиторских организациях и иных профессиональных объединениях аудиторов и консультантов;</li>
                <li>главный бухгалтер на предприятиях различных отраслей и видов деятельности;</li>
                <li>работник учетных и финансовых служб;</li>
                <li>бухгалтер-аналитик, бизнес-аналитик.</li>
            </ul>
            <ul><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Возможные места работы (организации, предприятия):</span>
                <li>аудиторские и консалтинговые фирмы;</li>
                <li>крупные и средние коммерческие организации различных отраслей и видов деятельности;</li>
                <li>саморегулируемые аудиторские организации и иные профессиональные объединения аудиторов и консультантов;</li>
                <li>Министерство финансов ПМР, Министерство здравоохранения ПМР, Министерство экономического развития ПМР, Счетная палата ПМР и другие министерства и ведомства; Прокуратура, Следственный комитет ПМР, ОБЭП и другие органы государственной власти;</li>
                <li>государственные финансовые, налоговые и контрольно-ревизионные органы;</li>
                <li>страховые организации;</li>
                <li>учебные заведения.</li>
            </ul>
            </p>
        </main>
        <Footer/></> );
}
 
export default Areas14;