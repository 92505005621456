import slaid1 from "./../img/slaid1.jpg";
import slaid2 from "./../img/slaid2.png";
import slaid3 from "./../img/slaid3.png";
import slaid4 from "./../img/slaid4.png";

const slides = [
    {
        image: slaid1,
    },
    {
        image: slaid2,
    },
    {
        image: slaid3,
    },
    {
        image: slaid4,
    }
];
 export default slides;