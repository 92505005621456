import React from "react"; 

const ScrollToTopButton = () => {
    const scrollToTop = () => {
        /*window.scrollTo({top: 0, behavior: 'smooth'});*/
        document.documentElement.scrollTop = 0;
    };
    return(
        <button className="button-footer" onClick={scrollToTop} style={{cursor:'pointer'}}>
            Наверх &uarr;
        </button>
    );
};

export default ScrollToTopButton;