import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import styles from "./../pages/applicants/Rules.module.css";
import ScheduleComponent from "./Shedule2arr";

const Schedule2 = () => {
    return ( <>
    <Header />
    <main>
        <h2 className={styles["h2-main"]}>Расписание зачетов и экзаменов на 2024-2025 учебный год</h2>

        <ScheduleComponent />

    </main>
    <Footer />
    </> );
}
 
export default Schedule2;