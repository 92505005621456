import React from "react";
import Header from "./../../components/Header";
import Footer from "./../../components/Footer";
import "./../../css/main.css";
import "./style.css";
import Newscouncil from "./Newscouncil";

const Council = () => {
    return ( 
        <>
        <Header />
        <h1 className="h1-council">Состав ученого совета</h1>
        <div className="container-council">
            <div className="item">
                <span className="position">Председатель Ученого Совета:</span>
                <h3 className="name-council">Узун Иван Николаевич</h3>
                <span className="title">Кандидат экономических наук, доцент, декан.</span>
            </div>
            <div className="item">
                <span className="position">Заместитель председателя Ученого Совета:</span>
                <h3 className="name-council">Павлин Евгения Васильевна</h3>
                <span className="title">Заместитель декана по учебно-методической работе.</span>
            </div>
            <div className="item">
                <span className="position">Секретарь Ученого совета:</span>
                <h3 className="name-council">Муравьева Наталья Юрьевна</h3>
                <span className="title">Старший преподаватель кафедры бухгалтерского учета и аудита.</span>
            </div>
            <div className="item">
                <span className="position">Члены Ученого совета:</span>
                <h3 className="name-council">Изместьева Светлана Юрьевна</h3>
                <span className="title">Председатель Счетной палаты Приднестровской Молдавской Республики.</span>
                <h3 className="name-council">Еремеева Оксана Владимировна</h3>
                <span className="title">Проректор по образовательной политике и менеджменту качества обучения</span>
                <h3 className="name-council">Босюк Виктор Николаевич</h3>
                <span className="title">Доктор экономических наук, профессор, заместитель декана по научной работе.</span>
                <h3 className="name-council">Формусатий Диана Викторовна</h3>
                <span className="title">Заместитель декана по воспитательной работе.</span>
                <h3 className="name-council">Надькин Леонид Юрьевич</h3>
                <span className="title">Кандидат физико-математических наук, доцент, исполняющий обязанности заведующего кафедрой бизнес-информатики и математических методов в экономике.</span>
                <h3 className="name-council">Сафронов Юрий Михайлович</h3>
                <span className="title">Кандидат экономических наук, доцент, заведующий кафедрой финансов и кредита.</span>
                <h3 className="name-council">Смоленский Николай Николаевич</h3>
                <span className="title">Кандидат экономических наук, заведующий кафедрой экономики и менеджмента.</span>
                <h3 className="name-council">Стасюк Татьяна Петровна</h3>
                <span className="title">Кандидат экономических наук, доцент, заведующая кафедрой бухгалтерского учета и аудита.</span>
                <h3 className="name-council">Человская Екатерина Ивановна</h3>
                <span className="title">Доцент кафедры финансово и кредита, секретарь аттестационной комиссии экономического факультета.</span>
                <h3 className="name-council">Ляховецкая Ольга Геннадьевна</h3>
                <span className="title">Начальник учебно-вычислительного центра.</span>
                <h3 className="name-council">Малахова Мария Владимировна</h3>
                <span className="title">Старший преподаватель кафедры бизнес-информатики и информационных технологий, председатель профбюро экономического факультета.</span>
            </div>
            <div className="item">
                <span className="position">Члены совета не на постоянной основе:</span>
                <h3 className="name-council">Репида Артем Анатольевич</h3>
                <span className="title">Председатель студенческого совета эконмического факультета.</span>
            </div>
        </div>
        <Newscouncil />        
        <Footer />
        </>
     );
}
 
export default Council;