import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Entrance = () => {
    return ( <>
    <Header/>
    <main>
        <div style={{textAlign:'center'}}>
            <h1 style={{marginBottom:'10px'}}>Прием в Университет на обучение по образовательным программам осуществляется</h1>
            <p>на первый курс на конкурсной основе:<br/>
            <ul style={{textAlign:'left'}}>
                <li>по программам бакалавриата, программам специалитета - по результатам единого государственного экзамена и (или) вступительных испытаний, проводимых Университетом (далее – вступительные испытания): математика (профильная дисциплина), родной язык, иностранный язык;</li>
                <li>по программам магистратуры - по результатам вступительных испытаний;</li>
                <li>по программам профессиональной переподготовки – по результатам вступительных
                испытаний.</li>
            </ul>
            </p>
            {/** 
            <h1 style={{marginBottom:'10px'}}>ПОРЯДОК СДАЧИ ВСТУПИТЕЛЬНЫХ ИСПЫТАНИЙ НА БЮДЖЕТНОЙ ФОРМЕ ОБУЧЕНИЯ</h1>
            <p>Абитуриенты сдают профилирующее (приоритетное) вступительное испытание или предоставляют сертификат о предварительном тестировании или сертификат ЕГЭ текущего года, но освобождаются от сдачи непрофилирующих (неприоритетных) вступительных испытаний с внесением в рейтинговую таблицу оценок из аттестата об образовании или сертификатов о предварительном тестировании, сертификатов ЕГЭ текущего года.<br/>
            На направлениях подготовки и специальностях, на которых отсутствует конкурс, абитуриенты могут быть освобождены от сдачи всех вступительных испытаний с внесением в рейтинговую таблицу оценок из аттестата об образовании или сертификатов о предварительном тестировании, сертификатов ЕГЭ текущего года</p>
        */}
        </div>
    </main>
    <Footer/>
    </> );
}
 
export default Entrance;