// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cell-1 {
    width: 35%;
}

.cell-2 {
    width: 20%;
}

.cell-3 {
    width: 20%;
}

td a {
    text-decoration: none;
}

td a:hover {
    text-decoration: underline;
}

.alicenter {
    text-align: center;
}

.undertd {
    text-transform: uppercase;
}

.contacts {
    margin-top: 20px;
    margin-bottom: 20px;
}

.bachelor {
    font-size: 16px;
}

.table-recruitplan td {
    border-color: black;
    padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/contacts/style.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":[".cell-1 {\r\n    width: 35%;\r\n}\r\n\r\n.cell-2 {\r\n    width: 20%;\r\n}\r\n\r\n.cell-3 {\r\n    width: 20%;\r\n}\r\n\r\ntd a {\r\n    text-decoration: none;\r\n}\r\n\r\ntd a:hover {\r\n    text-decoration: underline;\r\n}\r\n\r\n.alicenter {\r\n    text-align: center;\r\n}\r\n\r\n.undertd {\r\n    text-transform: uppercase;\r\n}\r\n\r\n.contacts {\r\n    margin-top: 20px;\r\n    margin-bottom: 20px;\r\n}\r\n\r\n.bachelor {\r\n    font-size: 16px;\r\n}\r\n\r\n.table-recruitplan td {\r\n    border-color: black;\r\n    padding: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
