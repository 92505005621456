import React from "react";
import "./style.css";
import decan from "./../../img/decan6.png";
import pavlin from "./../../img/pavlin.png";
import bosiuk from "./../../img/decan1.png";
import formus from "./../../img/formus.png";
import Header from "./../../components/Header";
import Footer from "./../../components/Footer";

const Management = () => {
    return ( <>
    <Header />
    <div className="container-management">
            <h1 className="h1-management">Руководство факультета</h1>
            <div className="container-management__items">
                <div className="management__item">
                    <img src={decan} style={{width: '280px', height: '250px'}}/>
                    <div className="management__item-text">
                        <h3 className="management-name blue">Узун Иван Николаевич</h3>
                        <h4 className="grou">Декан</h4>
                        <h5>Кандидат экономических наук, доцент
                            <ul>
                                <li>Тел.: 533-79479</li>
                                <li>Прием по личным вопросам – вторник 9.00-10.00</li>
                            </ul>
                        </h5>
                    </div>
                </div>

                <div className="management__item">
                    <img src={pavlin} style={{width: '280px', height: '250px'}} />
                    <div className="management__item-text">
                        <h3 className="management-name blue">Павлин Евгения Васильевна</h3>
                        <h4 className="grou">Заместитель декана по учебно-методической работе</h4>
                        <h5>
                            <ul>
                                <li>Тел.: 533-79481</li>
                                <li>Прием по личным вопросам – вторник 11.00-12.00</li>
                            </ul>
                        </h5>
                    </div>
                </div>

                <div className="management__item">
                    <img src={bosiuk} style={{width: '280px', height: '250px'}} />
                    <div className="management__item-text">
                        <h3 className="management-name blue">Босюк Виктор Николаевич</h3>
                        <h4 className="grou">Заместитель декана по научной работе</h4>
                        <h5>Доктор экономических наук, профессор
                            <ul>
                                <li>Тел.: 533-79730</li>
                                <li>Прием по личным вопросам – вторник 11.00-12.00</li>
                            </ul>
                        </h5>
                    </div>
                </div>

                <div className="management__item">
                    <img src={formus} style={{width: '280px', height: '250px'}} />
                    <div className="management__item-text">
                        <h3 className="management-name blue">Формусатий Диана Викторовна</h3>
                        <h4 className="grou">Заместитель декана по воспитательной работе</h4>
                        <h5>
                            <ul>
                                <li>Тел. 533-79781</li>
                                <li>Прием по личным вопросам –четверг 15.00-16.00</li>
                            </ul>
                        </h5>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>
     );
}
 
export default Management;