import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Entrance = () => {
    return ( <>
    <Header/>
    <main>
        <div style={{textAlign:'center'}}>
            <h1 style={{marginBottom:'10px'}}>ПОРЯДОК СДАЧИ ВСТУПИТЕЛЬНЫХ ИСПЫТАНИЙ НА ДОГОВОРНОЙ ФОРМЕ ОБУЧЕНИЯ</h1>
            <p>Абитуриенты освобождаются от сдачи вступительных испытаний с внесением в рейтинговую таблицу оценок из аттестата об образовании или сертификатов о предварительном тестировании, сертификатов ЕГЭ текущего года</p>
            <br/>
            <h1 style={{marginBottom:'10px'}}>ПОРЯДОК СДАЧИ ВСТУПИТЕЛЬНЫХ ИСПЫТАНИЙ НА БЮДЖЕТНОЙ ФОРМЕ ОБУЧЕНИЯ</h1>
            <p>Абитуриенты сдают профилирующее (приоритетное) вступительное испытание или предоставляют сертификат о предварительном тестировании или сертификат ЕГЭ текущего года, но освобождаются от сдачи непрофилирующих (неприоритетных) вступительных испытаний с внесением в рейтинговую таблицу оценок из аттестата об образовании или сертификатов о предварительном тестировании, сертификатов ЕГЭ текущего года.<br/>
            На направлениях подготовки и специальностях, на которых отсутствует конкурс, абитуриенты могут быть освобождены от сдачи всех вступительных испытаний с внесением в рейтинговую таблицу оценок из аттестата об образовании или сертификатов о предварительном тестировании, сертификатов ЕГЭ текущего года</p>
        </div>
    </main>
    <Footer/>
    </> );
}
 
export default Entrance;