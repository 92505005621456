import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";


const Documents = () => {
    return ( <>
    <Header/>
        <main>
            <h2 style={{textAlign:'center', lineHeight:'1.5', color:'#43a5ee', marginTop:'20px'}}>Документы для поступления в ПГУ им.Т.Г.Шевченко</h2><br/>
            <p>
            <ul>К заявлению прилагаются:
                <li>документ об образовании в подлиннике + копия;</li>
                <li>сертификат ЕГЭ;</li>
                <li>сертификат предварительного тестирования;</li>
                <li>медицинская справка (форма 086-У);</li>
                <li>шесть фотографий размером 3×4 без головного убора;</li>
                <li>копия трудовой книжки, заверенная по последнему месту работы, <span style={{color:'#43a5ee', fontWeight:'bold'}}>ЕСЛИ ЕСТЬ НАПРАВЛЕНИЕ ОТ ПРЕДПРИЯТИЯ</span>;</li>
                <li>справка Военного комиссариата по месту жительства о предоставлении возможности поступления в организацию высшего образования (для граждан Приднестровской Молдавской Республики – призывников текущего года, поступающих на очную форму обучения);</li>
                <li>удостоверение призера (1-е, 2-е, 3-е место) (при наличии);</li>
                <li>удостоверение об окончании заочной школы при ЭФ ПГУ им. Т.Г. Шевченко (при наличии);</li>
                <li>документ, удостоверяющий личность + копия;</li>
                <li>военный билет (приписное удостоверение)+ копия с отметкой о постановке на воинский учет – для граждан Приднестровской Молдавской Республики – военнообязанных и призывников, поступающих на очную форму обучения;</li>
                <li>иностранные граждане дополнительно предоставляют: заграничный паспорт, удостоверение гражданина Молдовы (для граждан Молдовы), миграционную карту с отметкой о пересечении границы, две цветные фотографии размером 3х4 без головного убора, заявление на имя ректора о месте проживания на период поступления;</li>
                <li>картонная папка – скоросшиватель без веревок;</li>
                <li>тетрадь (12 листов), ручка с черной пастой (для поступающих на бюджет);</li>
                <li>конверт А3 бумажный.</li>
            </ul>
            </p>
        </main>
    <Footer/>
    </> );
}
 
export default Documents;



