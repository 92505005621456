import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import News from './News'; 

const MultiSlideCarousel = () => {
  const [current, setCurrent] = useState(0);
  const newsHeaderData = News(); // получаем массив newsData через функцию News
  const navigate = useNavigate(); // используем useNavigate для навигации

  // Фильтруем новости, оставляя только те, которые помечены как "Актуальное" 
  const filteredNewsHeaderData = newsHeaderData.filter(news => news.type.includes("АКТУАЛЬНОЕ")); 
  const sortedNewsHeaderData = filteredNewsHeaderData.sort((a, b) => b.id - a.id); // сортируем по убыванию ID, чтобы последние новости были первыми 
  const limitedNewsHeaderData = sortedNewsHeaderData.slice(0, 12); // Ограничиваем количество слайдов до 12 последних новостей 
  const length = limitedNewsHeaderData.length;

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000); // смена слайда каждые 3 секунды
    return () => clearInterval(interval);
  }, [current]);

  const nextSlide = () => {
    setCurrent((current + 1) % length);
  };

  if (!Array.isArray(limitedNewsHeaderData) || limitedNewsHeaderData.length <= 0) {
    return null;
  }

  const slides = [...limitedNewsHeaderData, ...limitedNewsHeaderData.slice(0, 2)];

  const handleSlideClick = (id) => {
    navigate(`/news/${id}`);
  };

  return (
    <div className="multi-slide-carousel">
      <div className="multi-carousel-container">
        <div
          className="carousel-slides"
          style={{
            transform: `translateX(-${current * 33.33}%)`,
            transition: current % length === 0 ? 'none' : 'transform 0.5s ease-in-out',
          }}
          onTransitionEnd={() => {
            if (current % length === 0) {
              setCurrent(0);
            }
          }}
        >
          {slides.map((newsItem, index) => (
            <div key={index} className="multi-slide" onClick={() => handleSlideClick(newsItem.id)} style={{ cursor: 'pointer' }}>
              <img src={newsItem.images[0]} alt={`slide-${index}`} className="carousel-image" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MultiSlideCarousel;
